import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Global } from './global';
import { jsPDF } from "jspdf";
declare let html2canvas: any;
import { Cargo } from '../interfaces/cargo';
import { CatalogItem } from '../interfaces/catalogItem';
import ContentTypes from './content-type-ext.json';
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { Patterns } from './patterns';
import { Fmt } from '../messages/fmt';
import { FormMessages } from '../messages/form-messages.enum';
import { AbstractControl } from '@angular/forms';
import { Exclusion } from '../interfaces/exclusion';

export class Utils {
  constructor(
    private spinner: NgxSpinnerService,
    private patterns: Patterns,
    private global: Global,
    private snackBarService: SnackBarService,
  ) {

  }

  getPorcentageAdvance(porcentageTypeCargo: number) {
    let porcentageList: Array<object> = [];
    let max = porcentageTypeCargo

    for (let porcentages = 10; porcentages < (max); porcentages += 10) {
      porcentageList.push({
        title: porcentages + "%",
        value: porcentages
      })
    }
    return porcentageList;
  }
  getListHour() {
    let hourList: Array<object> = [];
    for (let hours = 1; hours < 24; hours++) {
      hourList.push({
        title: hours + " h",
        value: hours
      })
    }
    return hourList;
  }

  getListMinute() {
    let minuteList: Array<object> = [];
    for (let minutes = 0; minutes < 60; minutes += 5) {
      minuteList.push({
        title: minutes + " m",
        value: minutes
      })
    }
    return minuteList;
  }

  getListTime(): Array<{ title: string, value: string }> {
    let timeList: Array<{ title: string, value: string }> = [];
    for (let hours = 1; hours < 13; hours++) {
      for (let mins = 0; mins < 60; mins += 30) {
        timeList.push({
          title: hours + ':' + (mins === 0 ? '00' : mins),
          value: hours + ':' + (mins === 0 ? '00' : mins)
        });
      }
    }
    return timeList;
  }

  isDefined(value, anotherValue?) {
    let status = false;
    if (!_.isUndefined(value) && !_.isNull(value)) {
      status = true;
    }
    if (anotherValue) {
      if (status) {
        return value;
      } else {
        return anotherValue;
      }
    } else {
      return status;
    }
  }

  isEmailValid(email: string) {
    return /^[a-z0-9]([-+\.]?[\w]+)*[-+\.]?[a-z0-9]@([a-z0-9][\w-]*\.)+[a-z0-9][\w-]{1,}$/gim.test(email) && email.length <= 100;
  }

  snapshotToArray(snapshot: any) {
    const returnArr = [];
    if (snapshot) {
      snapshot.forEach((childSnapshot: any) => {
        const item = childSnapshot.val();
        if (item && item.latLng && item.latLng.latitude && item.latLng.longitude) {
          // return item.latLng;
          const newItem = this.clone(item);
          delete newItem.latLng;
          item.latLng.key = childSnapshot.key;
          returnArr.push({
            ...item.latLng,
            ...newItem
          });
        }
      });
    }
    return returnArr;
  };

  snapshotToArrayAnomalies(snapshot: any) {
    const returnArr = [];
    if (snapshot) {
      snapshot.forEach((childSnapshot: any) => {
        const item = childSnapshot.val();
        if (item && item.latLng && item.latLng.lat && item.latLng.lng) {
          const newItem = this.clone(item);
          delete newItem.latLng;
          item.latLng.key = childSnapshot.key;
          returnArr.push({
            ...item.latLng,
            ...newItem
          });
        }
      });
    }
    return returnArr;
  };

  getListLocationsCargo(cargo: Cargo) {
    const locationsList = [];
    if (cargo.cargoFeature.uploadDownload.origin.addresses.length > 0) {
      for (let i = 0; i < cargo.cargoFeature.uploadDownload.origin.addresses.length; i++) {
        locationsList.push({
          lat: cargo.cargoFeature.uploadDownload.origin.addresses[i].location.lat,
          lng: cargo.cargoFeature.uploadDownload.origin.addresses[i].location.lng
        });
      }
    }

    let count = 0;
    if (cargo.cargoFeature.uploadDownload.destination.length) {

      for (let i = 0; i < cargo.cargoFeature.uploadDownload.destination.length; i++) {
        count++;
        if (cargo.cargoFeature.uploadDownload.destination[i].addresses.length) {
          let maxSize = cargo.cargoFeature.uploadDownload.destination[i].addresses.length;
          for (let iD = 0; iD < maxSize; iD++) {
            locationsList.push({
              lat: cargo.cargoFeature.uploadDownload.destination[i].addresses[iD].location.lat,
              lng: cargo.cargoFeature.uploadDownload.destination[i].addresses[iD].location.lng
            });
          }
        }
      }
    }
    _.map(locationsList, (location, i) => {
      const position = i + 1;
      location.position = position;
    });

    return locationsList;
  }
  setStateOnHoldAddressCargo(cargo: Cargo): void {
    if (cargo.cargoFeature.uploadDownload.origin.addresses.length > 0) {
      for (let i = 0; i < cargo.cargoFeature.uploadDownload.origin.addresses.length; i++) {
        if (!cargo.cargoFeature.uploadDownload.origin.addresses[i].state) {
          cargo.cargoFeature.uploadDownload.origin.addresses[i].state = 'On hold';
        }
      }
    }

    if (cargo.cargoFeature.uploadDownload.destination.length) {

      for (let i = 0; i < cargo.cargoFeature.uploadDownload.destination.length; i++) {
        if (cargo.cargoFeature.uploadDownload.destination[i].addresses.length) {
          for (let iD = 0; iD < cargo.cargoFeature.uploadDownload.destination[i].addresses.length; iD++) {
            if (!cargo.cargoFeature.uploadDownload.destination[i].addresses[iD].state) {
              cargo.cargoFeature.uploadDownload.destination[i].addresses[iD].state = 'On hold';
            }
          }
        }
      }
    }
  }

  getListGroupLocationsCargo(cargo) {
    let locationsList = this.getListLocationsCargo(cargo);
    let points = [];
    let parts = []
    let max = 24;
    for (let i = 0; i < locationsList.length; i = i + max) {
      parts.push(locationsList.slice(i, i + max + 1));
    }

    for (let i = 0; i < parts.length; i++) {
      let waypoints = [];
      for (let j = 1; j < parts[i].length - 1; j++)
        waypoints.push({ location: parts[i][j], stopover: false });

      points.push({
        origin: parts[i][0],
        destination: parts[i][parts[i].length - 1],
        waypoints: waypoints
      });
    }

    return points;
  }

  getWaypointsObject(points: Array<any>) {
    const waypoints = [];
    for (let j = 1; j < points.length - 1; j++)
      waypoints.push({
        location: points[j],
        stopover: false
      });

    return {
      origin: points[0],
      destination: points[points.length - 1],
      waypoints
    };
  }

  copyElementJSON(element: any) {
    let elementStringify = JSON.stringify(element);
    let elementJSON = JSON.parse(elementStringify);
    return elementJSON;
  }

  convertArrayToObject(array, key) {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  }

  downloadFromUrl(url) {
    let thisClass = this;
    this.spinner.show();
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(() => {
      link.remove();
      thisClass.spinner.hide();
    }, 100);
  }

  downloadFile(response, fileName, type?: string) {
    let thisClass = this;
    this.spinner.show();
    const typeBlob = type ? type : response.type;
    const newBlob = new Blob([response], { type: typeBlob });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator['msSaveOrOpenBlob']) {
      window.navigator['msSaveOrOpenBlob'](newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    // link.download = fileName + "." + newBlob.type;
    link.download = `${fileName}.${this.getExtension(newBlob.type)}`;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
      thisClass.spinner.hide();
    }, 100);
  }

  getExtension(contentType: string) {
    for (let extension in ContentTypes) {
      if (contentType === ContentTypes[extension]) {
        return extension;
      }
    }
    return 'txt';
  }

  companyEnabledExport(nit, idExport) {
    let valueFind = _.find(this.global.companiesEnabledsToExport, function (key) {
      return key === nit;
    });
    return this.isDefined(valueFind);
  }

  getRandomColorSize(size, opacity?: boolean) {

    let totalDIVs = size;
    let totalColors = totalDIVs;
    let colors = [];
    let count = 1;

    for (let i = 0; i < totalDIVs; i++) {
      let color = "hsl( " + this.makeColorHSL(i, totalColors) + ", 100%, 50% )";
      if (opacity) {
        color = "hsl( " + this.makeColorHSL(i, totalColors) + ", 100%, 50%, 0.4 )";
      }
      colors.push(color);
      if (count === totalDIVs) {
        return colors;
      }
      count++;
    }
  }

  makeColorHSL(colorNum, colors) {
    if (colors < 1) colors = 1;
    return colorNum * (360 / colors) % 360;
  }

  generateCanvaPDF(element: any, orientation: "p" | "portrait" | "l" | "landscape", name: string) {

    let doc = new jsPDF(orientation, "px", "letter");

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();

    let imgData = element.toDataURL('image/png');

    doc.addImage(imgData, 'PNG', 30, 30, width - 50, height - 200);
    // doc.addImage(imgData, 'PNG', 55, 0, null, null);

    doc.save(name + '.pdf');

  }

  generateHTMLPDF(name: string) {
    this.spinner.show();
    const DATA = document.getElementById('htmlDataReport');
    const doc = new jsPDF('l', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {
      const img = canvas.toDataURL('image/PNG');
      const bufferX = 15;
      const bufferY = 0;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined);
      return doc;
    }).then(
      (docResult) => {
        this.spinner.hide();
        docResult.save(`${name + " " + new Date().toISOString()}.pdf`);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  bytesToSize(bytes) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    let i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  clearFieldsObject(object: any) {
    try {
      return _.pickBy(object, _.identity);
    } catch (e) {
      return object;
    }
  }

  getObjWithValues(object: any) {
    let newObject = {};
    for (const key in object) {
      if ((typeof object[key] === 'number' && object[key] >= 0) || (typeof object[key] === 'string' && object[key].length)) {
        newObject[key] = object[key];
      }
    }
    return newObject;
  }

  selectAllItems(list: Array<any>, excludeFields?: Array<Exclusion>) {
    list = list.filter((value) => {
      let exclude = false;
      excludeFields.forEach((excludeField: Exclusion) => {
        const compareValue = this.getNestedValue(value, excludeField.field);

        if (typeof (excludeField.value) == 'function') {
          exclude = exclude || excludeField.value(compareValue);
        } else {
          exclude = exclude || compareValue == excludeField.value;
        }
      });
      return !exclude;
    });


    const listData = _.map(list, (i: any) => {
      if (i) {
        i.checked = true;
        return i;
      }
    }).filter((item: any) => !!item);

    return listData;
  }

  deselectAllItems(list) {
    const listData = _.map(list, (i) => {
      if (i) {
        i.checked = false;
      }
      return i;
    });

    return listData;
  }

  getItemsCheked(list, propertyReturn?: string) {
    const listData = this.clone(list);
    return _.filter(listData, (i) => {
      return i.checked >= true;
    }).map((i) => {
      if (propertyReturn && i[propertyReturn]) {
        return i[propertyReturn];
      } else {
        return i;
      }
    });
  }

  clearCargos(cargos: Cargo[]) {
    return cargos.map((i) => {
      delete i.checked;
      return i;
    });
  }

  public enableSelectItemByList(typeList: string): boolean {
    let state = false;
    switch (typeList) {
      case 'advanceState':
      case 'paymentAdvanceCargo':
      case 'paymentExtraAdvanceCargo':
      case 'paymentAdditionalCostsCargo':
      case 'paymentAdvanceCargo':
      case 'paymentBalanceCargo':
      case 'paymentTravelExpenses':
      case 'charges':
      case 'approvePayments':
      case 'withoutPayment':
        state = true;
        break;
    }

    return state;
  }

  isImage(file: File | FileList): boolean {
    try {
      if (file instanceof FileList) {
        if (!file.length) return false;
        return Object.keys(file).every(img => file[img].type.match(/image\/*/));
      }
      return !!file.type.match(/image\/*/)
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  isPDF(file: File): boolean {
    return file && file.type && file.type === 'application/pdf';
  }


  getImageBase64(file: File): Promise<string> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      try {
        if (!this.isImage(file)) reject();
        if (file) {
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            resolve(reader.result as string);
          };
        }
      } catch (e) {
        console.error(e);
        reject();
      }
    });
  }

  getPdfBase64(file: File): Promise<string> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      if (!this.isPDF(file)) reject();
      try {
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          resolve(reader.result as string);
        };
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  clone(value: any) {
    if (value) {
      const newValue = JSON.stringify(value);
      return JSON.parse(newValue);
    }
  }

  getDocumentType(documentId: string): CatalogItem {
    const documentType: CatalogItem[] = this.global.documenTypes.filter((obj) => {
      return obj.id === documentId;
    });
    if (documentType.length) {
      return documentType[0];
    }
    return null;
  }

  toCapitalize(value: string): string {
    try {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    } catch (e) {
      return value;
    }
  }

  listToDefault(list: Array<any>, key: string, label?: string) {
    let newList = this.clone(list);
    return newList = newList.map((item) => {
      const keyStr = item[key] ? item[key] : key;
      return {
        key: label ? `${label}${keyStr}` : keyStr,
        value: item
      };
    });
  }

  objIsEmpty(obj: object) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  isEmpty(str): boolean {
    try {
      return str === null || str === undefined || str.length === 0;
    } catch (e) {
      console.error(e);
      return true;
    }
  }
  getNestedValue(obj: any, path: string) {
    try {
      const pathNoSpaces = path.replace(/(\r\n|\n|\r|\t|\ )/gm, "");
      const keys = pathNoSpaces.split('.');
      let currentValue = this.clone(obj);
      for (const key of keys) {
        if (key.includes('[') && key.includes(']')) {
          const arrayKey = key.substring(0, key.indexOf('['));
          const index = parseInt(key.substring(key.indexOf('[') + 1, key.indexOf(']')));

          if (Array.isArray(currentValue[arrayKey]) && currentValue[arrayKey][index] !== undefined) {
            currentValue = currentValue[arrayKey][index];
          } else {
            return undefined;
          }
        } else {
          if (key in currentValue) {
            currentValue = currentValue[key];
          } else {
            return undefined;
          }
        }
      }
      return currentValue;
    } catch (error) {
      return undefined;
    }
  }


  cleanEmptyValues(obj: any) {
    try {
      if (Array.isArray(obj)) {
        return obj
          .map(v => (v && typeof v === 'object') ? this.cleanEmptyValues(v) : v)
          .filter(v => !(v == null));
      } else {
        return Object.entries(obj)
          .map(([k, v]) => [k, v && typeof v === 'object' ? this.cleanEmptyValues(v) : v])
          .reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {});
      }
    } catch (e) {
      return obj;
    }
  }

  daysInDate(days: any) {

    let year = Math.abs(days) / 365;
    let mont = ((year % 1) * 365) / 12;
    let day = ((mont % 1) * 12);

    if (year > 2) {
      return Math.trunc(year) + ' años , ' + Math.trunc(mont) + ' meses y ' + Math.trunc(day) + ' dias';
    }
    if (year >= 1 && year < 2) {
      return Math.trunc(year) + ' año , ' + Math.trunc(mont) + ' meses y ' + Math.trunc(day) + ' dias';
    }
    if (year < 1) {
      return Math.trunc(mont) + ' meses y ' + Math.trunc(day) + ' dias';
    }
    if (year < 1 && mont < 1) {
      return Math.trunc(day) + ' dias';
    }

  }

  arrayGroupBy(list: any[], key: string) {
    try {
      const group = list.reduce((r: any, a) => {
        (r[a[key]] = r[a[key]] || []).push(a);
        return r;
      }, {});
      return group;
    } catch (e) {
      return list;
    }
  }

  getPercentage(value: number, percentage: number): number {
    const valuePercentage: number = (value * percentage) / 100;
    return valuePercentage;
  }

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  round(value: number) {
    return Math.round(value);
  }

  errorMessagesCustomized(field, name: string, minlength?, maxlength?, min?, max?): boolean {
    if (!field) {
      return true;
    }
    if (!field.errors) {
      return false;
    }
    let title: string;
    if (!name) {
      title = FormMessages.GENERAL_ERROR_DEFAULT;
    } else if (field.errors.required) {
      title = Fmt.string(FormMessages.MISSING_FIELD, name);
    } else if (field.errors.pattern) {
      title = Fmt.string(FormMessages.INVALID_FIELD, name);
    } else if (field.errors.maxlength) {
      title = maxlength ? Fmt.string(FormMessages.MAX_LENGTH_EXCEED, name, maxlength) : Fmt.string(FormMessages.MAX_LENGTH_EXCEED_DEFAULT, name);
    } else if (field.errors.minlength) {
      title = minlength ? Fmt.string(FormMessages.MIN_LENGTH_NOT_REACHED, name, minlength) : Fmt.string(FormMessages.MIN_LENGTH_NOT_REACHED_DEFAULT, name);
    } else if (field.errors.min) {
      title = min || min === 0 ? Fmt.string(FormMessages.MIN_VALUE_NOT_REACHED, name, min) : Fmt.string(FormMessages.MIN_VALUE_NOT_REACHED_DEFAULT, name);
    } else if (field.errors.max) {
      title = max || max === 0 ? Fmt.string(FormMessages.MAX_VALUE_EXCEED, name, max) : Fmt.string(FormMessages.MAX_VALUE_EXCEED_DEFAULT, name);
    } else if (field.errors.email) {
      title = Fmt.string(FormMessages.INVALID_FIELD, name);
    } else {
      title = Fmt.string(FormMessages.GENERAL_ERROR, name);
    }
    title && this.snackBarService.openSnackBar(title, undefined, 'alert');
    return true;
  }

  giveMessageError(formMessagesEnum: string, name?: string, param?): string {
    try {
      if (FormMessages[formMessagesEnum]) return Fmt.string(FormMessages[formMessagesEnum], name, param);
      else if (name) return Fmt.string(FormMessages.GENERAL_ERROR, name);
      else return FormMessages.GENERAL_ERROR_FIELD;
    } catch (error) {
      if (name) return Fmt.string(FormMessages.GENERAL_ERROR, name);
      else return FormMessages.GENERAL_ERROR_FIELD;
    }
  }

  public dataUrlToFile(appType: string, dataType: string, dataurl: string, filename: string): File {
    // 'application/xslx', 'base64'
    const name = `data:${appType};${dataType},${dataurl}`;
    let arr: string[] = name.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  isRequiredField(control: AbstractControl): boolean {
    return !!(control && control.validator && control.validator({} as AbstractControl) && control.validator({} as AbstractControl).required);
  }
  public getDefaultUrlLogo(type: 'mainLogo' | 'white'): string {
    let defaultUrl: string;
    let isTeclogi = location.hostname.search('teclogi') >= 0;

    switch (type) {
      case 'mainLogo':
        defaultUrl = isTeclogi ? '/assets/svg/logotype/color/logotype.svg' : '/assets/svg/logotype/color/logotypeLA.png';
        break;
      case 'white':
        defaultUrl = isTeclogi ? '/assets/svg/logotype/white/logotype-horizontal.png' : '/assets/svg/logotype/white/logotypeWhiteLA.png';
        break;
    }

    return defaultUrl;
  }

  removeEmpty(body) {
    if (typeof body === "object") {
      let newObject = Array.isArray(body) ? [] : {};
      Object.entries(body)
        .filter(
          ([_, value]) => {
            if (typeof value === "string")
              value = value.trim();
            return !(value === null || value === undefined || value === "");
          }
        )
        .map(([key, value]) =>
          (parseInt(key) as any) == (key as any)
            ? (newObject[parseInt(key)] = this.removeEmpty(this.trimIfString(value)))
            : (newObject[key] = this.removeEmpty(this.trimIfString(value)))
        );
      return newObject;
    } else return body;
  }

  trimIfString(value: any) {
    if (typeof value === "string") {
      if (this.patterns.CELLPHONE.test(value) || this.patterns.CELLPHONE_WITH_SPACES.test(value) || this.patterns.ONLY_NUMBERS.test(value) || this.patterns.ONLY_NUMBERS_WITH_SPACES.test(value)) value = value.replace(/\s/g, "").trim();
      else value = value.replace(/\s+/g, ' ').trim();
    }
    return value;
  }

  public numberMaskToNumber(valueString: string): string {
    let value = valueString.toString().replace(/\D/g, '') || '';
    if (value.length === 0) {
      value = '';
    } else {
      value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
    return value;
  }

}
