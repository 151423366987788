import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { ApprovalState, ApprovalStateEnum, ImageFingerprint, TravelExpense } from 'src/app/core/interfaces/travel-expense';
import { LegalizeStatePipe } from 'src/app/core/pipe/legalize-state.pipe';
import { Dialog } from 'src/app/core/resources/dialog';
import { TravelExpensesService } from 'src/app/core/services/travel-expenses.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Cargo } from 'src/app/core/interfaces/cargo';

@Component({
  selector: 'app-general-table-legalization-travel-expenses',
  templateUrl: './general-table-legalization-travel-expenses.component.html',
  styleUrls: ['./general-table-legalization-travel-expenses.component.scss']
})
export class GeneralTableLegalizationTravelExpensesComponent implements OnInit {

  @Input()
  travelExpenses: Array<TravelExpense> = [];
  @Input()
  cargo: Cargo;
  @Output()
  public onCloseDialog: EventEmitter<any> = new EventEmitter();

  states: string[] = ['Pending approval', 'Rejected', 'Approved'];

  constructor(
    private dialog: Dialog,
    private travelExpensesService: TravelExpensesService,
    private legalizeStatePipe: LegalizeStatePipe,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
  ) {

  }

  ngOnInit() {

  }

  public get calculateDisbursed(): number {
    if(!Array.isArray(this.travelExpenses))
      return 0;
    return this.travelExpenses.reduce((accumulator, travelExpense) => accumulator + (travelExpense.totalPaid ? travelExpense.totalPaid : 0), 0);
  }

  public get calculateUsed(): number {
    if(!Array.isArray(this.travelExpenses))
      return 0;
    return this.travelExpenses.reduce((accumulator, travelExpense) => accumulator + (travelExpense.totalSpentValue ? travelExpense.totalSpentValue : 0), 0);
  }

  public get calculateNotLegalized(): number {
    if(!Array.isArray(this.travelExpenses))
      return 0;
    return this.travelExpenses.reduce((accumulator, travelExpense) =>
      accumulator + (travelExpense.totalSpentValue && travelExpense.approval === ApprovalStateEnum.PENDING
        ? travelExpense.totalSpentValue : 0), 0);
  }

  public get calculateRejected(): number {
    if(!Array.isArray(this.travelExpenses))
      return 0;
    return this.travelExpenses.reduce((accumulator, travelExpense) =>
      accumulator + (travelExpense.totalSpentValue && travelExpense.approval === ApprovalStateEnum.REJECTED
        ? travelExpense.totalSpentValue : 0), 0);
  }

  public get calculateLegalized(): number {
    if(!Array.isArray(this.travelExpenses))
      return 0;
    return this.travelExpenses.reduce((accumulator, travelExpense) =>
      accumulator + (travelExpense.totalSpentValue && travelExpense.approval === ApprovalStateEnum.APPROVED
        ? travelExpense.totalSpentValue : 0), 0);
  }

  public get generalState(): string {
    if(!Array.isArray(this.travelExpenses))
      return "Sin legalizar";

    const states = this.travelExpenses.map((travelExpense: TravelExpense) => travelExpense.approval);

    const withRejections = states.includes(ApprovalStateEnum.REJECTED);
    const withPendings = states.includes(ApprovalStateEnum.PENDING);
    const withApprovements = states.includes(ApprovalStateEnum.APPROVED);

    if (withApprovements && !withRejections && !withPendings)
      return "Legalizado";

    if (withApprovements && withRejections && !withPendings)
      return "Legalizado con rechazos";

    if (withApprovements && !withRejections && withPendings)
      return "Parcialmente legalizado";

    if (withRejections && withPendings)
      return "Parcialmente legalizado con rechazos";

    if (!withApprovements && !withRejections && withPendings)
      return "Sin legalizar";

    if (!withApprovements && withRejections && !withPendings)
      return "Rechazado";

    return "Sin legalizar";
  }

}
