import { MatDialog, MatDialogConfig } from "@angular/material";
import { AmountsCargoEnum } from "../enums/amountsCargo.enum";
import { Cargo } from "../interfaces/cargo";
import { Utils } from "../resources/utils";
import { Injectable } from "@angular/core";
import { ModalEnum } from "../enums/modal.enum";
import { TRIP_TYPES } from "../enums/tripTypes.enum";
import { CargoGenerateService } from "../services/cargoGenerate.service";
import { DialogSurveyComponent } from "src/app/shared/dialog-survey/dialog-survey.component";
import { LatLngLiteral } from "@agm/core";
import { AddressCargo } from "../interfaces/addressCargo";
import { LocationAddress } from "../interfaces/locationAddress";
import moment from "moment";
import { Observable } from "rxjs";
import { DateManager } from "./date.manager";
import { GoogleService } from "../services/google.service";
import { CargoApproval } from "../interfaces/cargo-approval";

type Arrive = { location: LocationAddress, arrive: Date, address: string };

@Injectable()
export class CargoManager {

  public amountsCargo = [
    {
      start: AmountsCargoEnum.LOW_START,
      end: AmountsCargoEnum.LOW_END
    },
    {
      start: AmountsCargoEnum.MEDIUM_START,
      end: AmountsCargoEnum.MEDIUM_END
    },
    {
      start: AmountsCargoEnum.HIGH_START,
      end: AmountsCargoEnum.HIGH_END
    }
  ];

  constructor(
    public utils: Utils,
    public matDialog: MatDialog,
    public cargoGenerateService: CargoGenerateService,
    public googleService: GoogleService
  ) {

  }

  public getTypeCargo(cargo: Cargo): TRIP_TYPES {
    let tripType: TRIP_TYPES;
    try {
      tripType = cargo.cargoModel.tripType.name;
    } catch (e) {
      console.error(e);
    }
    if (this.utils.isEmpty(tripType)) {
      tripType = TRIP_TYPES.URBAN;
    }
    return tripType;
  }

  public getProductNameCargo(cargo: Cargo): string {
    let productName: string;
    try {
      productName = cargo.cargoFeature.productType.name;
    } catch (e) {
      console.error(e);
    }
    if (this.utils.isEmpty(productName)) {
      productName = 'PRODUCTOS VARIOS';
    }
    return productName;
  }

  public checkInspection(cargo: Cargo, userId: string, licensePlate: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      //Inspeccionar vehiculo
      const tripTypesToInspection = [TRIP_TYPES.NATIONAL, TRIP_TYPES.IMPORT, TRIP_TYPES.EXPORT, TRIP_TYPES.INTERNATIONAL];
      if (tripTypesToInspection.includes(this.getTypeCargo(cargo))) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          user: userId,
          licensePlate,
          id: cargo.id
        };
        dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
        dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
        dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
        dialogConfig.autoFocus = false;
        const dialoRef = this.matDialog.open(DialogSurveyComponent, dialogConfig);
        dialoRef.afterClosed().subscribe(result => {
          if (result) {
            resolve(true);
          } else {
            reject();
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  public isTripTypeNational(cargo: Cargo): boolean {
    let state: boolean = false;
    try {
      return this.utils.getNestedValue(cargo, 'cargoModel.tripType.name') && cargo.cargoModel.tripType.name !== TRIP_TYPES.URBAN && cargo.cargoModel.tripType.name !== TRIP_TYPES.INTERNATIONAL && cargo.cargoModel.tripType.name !== TRIP_TYPES.LAST_MILE && ((this.utils.isDefined(cargo.cargoModel.tripType.category) && cargo.cargoModel.tripType.category !== TRIP_TYPES.URBAN) || !this.utils.isDefined(cargo.cargoModel.tripType.category));
    } catch (e) {
      console.error(e);
    }
    return state;
  }

  private timeAmPmTo24H(address: AddressCargo): string {
    const time24H =
      address.time.startsWith('12') ?
        // 12:00 AM -> 00:00
        address.timeType == 'AM' ?
          address.time.replace('12', '00') :
          address.time :
        // 11:00 AM -> 11:00; 11:00 PM -> 23:00
        address.timeType == 'AM' ?
          address.time :
          `${parseInt(address.time.slice(0, 2)) + 12}:${address.time.slice(3, 5)}`;
    return time24H;
  }

  private processAddressToCheckTripTimes(address: AddressCargo, arrivalDate: Date | string): Arrive {
    // Completar con 0 a la izquierda 5:00 -> 05:00
    if (!address || !address.address)
      throw new Error(`No se ha diligenciado la dirección de algun origen o destino`);
    if (!address.time)
      throw new Error(`La dirección ${address.address} no tiene hora estimada`);
    address.time = address.time.padStart(5, '0');
    const time24H = this.timeAmPmTo24H(address);

    // Check if it is valid location
    if (!address.location || (!address.location.lat && !address.location.lng))
      throw new Error(`La dirección ${address.address} no tiene una geopocisión válida`);

    return {
      location: address.location as LatLngLiteral,
      arrive: ((moment(arrivalDate)).add(time24H).toDate()),
      address: address.address
    }
  }

  public checkTripTimes(cargo: Cargo): Observable<{ valid: boolean, min: number, address: string }> {
    const arrivals: Array<Arrive> = [];
    const dateLoad = cargo.dateLoad;
    const origin = cargo.cargoFeature.uploadDownload.origin;
    const destinations = cargo.cargoFeature.uploadDownload.destination;

    try {
      origin.addresses.forEach(address => arrivals.push(this.processAddressToCheckTripTimes(address, dateLoad)));
      destinations.forEach(destination => {
        const dateDownload = destination.downloadDate;
        destination.addresses.forEach(address => arrivals.push(this.processAddressToCheckTripTimes(address, dateDownload)));
      });
    } catch (err) {
      return new Observable(observer => observer.error(err));
    }
    if (!arrivals.length)
      return new Observable(observer => observer.error('No se han registrado direcciones de origen o destino'));

    const observables: Array<Observable<{ valid: boolean, min: number, address: string }>> = [];
    // Process all arrivals times from [1] ... [end]
    for (let i = 1; i < arrivals.length; i++) {
      const prev = arrivals[i - 1];
      const next = arrivals[i];

      observables.push(new Observable<{ valid: boolean, min: number, address: string }>(
        (observer) => this.googleService
          .calculateMinRouteTime(
            prev.location,
            next.location,
            (response, status) => {
              if (status !== "OK") { observer.error('No fue posible calcular una ruta entre las direcciones'); }
              else {
                const times: Array<number> = [];
                const distance: Array<number> = [];
                const description: Object = {};

                response.routes.forEach((route) => {
                  route.legs.forEach((leg) => {
                    times.push(leg.duration.value);
                    distance.push(leg.distance.value);

                    description[`${leg.duration.value}`] = {
                      duration: leg.duration.text,
                      distance: leg.distance.text,
                    };
                  });
                });
                const min = (4 / 5) * Math.min(...times); // Minimo tiempo estimado en segundos
                const duration = DateManager.dateDiff(next.arrive, null, prev.arrive, null, 'seconds');
                const isValid: boolean = !(duration - min < 0);
                observer.next({ valid: isValid, min, address: arrivals[i].address });
              }
              observer.complete();
            })));
    }

    return new Observable<{ valid: boolean, min: number, address: string }>(
      observer => {
        let pending = observables.length;
        let result = true;
        let min = 0;
        let address = '';
        observables.forEach(
          subscribable => {
            subscribable.subscribe({
              next: (success: { valid: boolean, min: number, address: string }) => {
                if (success && success.min) min = success.min;
                if (!success || !success.valid) {
                  observer.next({ valid: false, min, address: success.address });
                  observer.complete();
                } else {
                  pending--;
                  result = result && success.valid;
                  address = success.address;
                }
              },
              error: (err) => {
                observer.error(err);
                observer.complete();
              },
              complete: () => {
                if (pending == 0) {
                  observer.next({ valid: result, min, address });
                  observer.complete();
                }
              }
            })
          }
        )
      }
    );
  }

  public createCargoApproval(cargo: Cargo): CargoApproval {
    if (!this.isTripTypeNational(cargo)) return { cargoId: cargo.id };
    const lastAddressOrigin: AddressCargo = cargo.cargoFeature.uploadDownload.origin.addresses[cargo.cargoFeature.uploadDownload.origin.addresses.length - 1];
    const destinations = cargo.cargoFeature.uploadDownload.destination;
    const consignments = [];
    const [timeHours, timeMinutes] = lastAddressOrigin.time.split(':');

    const OriginTime = DateManager.add(
      DateManager.add(cargo.dateLoad, parseInt(timeHours), 'hours'),
      parseInt(timeMinutes), 'minutes'
    );
    for (const i in destinations) {
      for (const j in destinations[i].addresses) {
        const address = destinations[i].addresses[j];
        if (address && address.consignments) {
          const dateDownload = this.getDownloadDateBase(address, destinations[i].downloadDate);
          const consigmentForm = {
            id: address.consignments[0],
            destinationId: i,
            addressId: address.id,
            amountDelivered: address.cargoMeasure.totalWeigth,
            upload: {
              arrival: DateManager.formatDate(DateManager.add(OriginTime, 16, 'minutes')),
              entry: DateManager.formatDate(DateManager.add(OriginTime, 30, 'minutes')),
              departure: DateManager.formatDate(DateManager.add(
                DateManager.add(OriginTime, lastAddressOrigin.timePact > 0 ? lastAddressOrigin.timePact : 1, 'hours'),
                lastAddressOrigin.minutePact,
                'minutes'
              )),
            },
            download: {
              arrival: dateDownload,
              entry: DateManager.dateToString(DateManager.add(dateDownload, 16, 'minutes')),
              departure: DateManager.dateToString(DateManager.add(
                DateManager.add(dateDownload, address.timePact > 0 ? address.timePact : 1, 'hours'),
                address.minutePact,
                'minutes'
              )),
            }
          };
          consignments.push(consigmentForm);
        }
      }
    }
    return { cargoId: cargo.id, consignment: consignments };
  }

  public getDownloadDateBase(address: AddressCargo, downloadDate: string): string {
    let dateDownload: string;
    if (address.dateTimeDownload && address.dateTimeDownload[0])
      dateDownload = address.dateTimeDownload[0];
    else if (address.durationTime && address.durationTime.startDate)
      dateDownload = address.durationTime.startDate;
    else dateDownload = `${downloadDate.slice(0, 10)} ${address.time} -0500`;

    const dateDownloadUpdated = DateManager.add(
      DateManager.add(dateDownload, address.timePact > 0 ? address.timePact : 1, 'hours'),
      address.minutePact,
      'minutes'
    );
    if (!DateManager.isBefore(dateDownloadUpdated, new Date(), 'seconds')) {
      return DateManager.dateToString(DateManager.substract(
        DateManager.substract(new Date(), address.timePact > 0 ? address.timePact : 1, 'hours'),
        address.minutePact,
        'minutes'
      ));
    }
    return dateDownload;
  }
}
