import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Filter } from 'src/app/core/models/filter';
import { PaginationList } from 'src/app/core/models/pagination-list';
import { Dialog } from 'src/app/core/resources/dialog';
import { Utils } from 'src/app/core/resources/utils';
import { AuthService } from 'src/app/core/services/authentication.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoItemService } from '../cargo-item/cargo-item.service';
import { FreightListService } from '../cargo-list/cargo-list.service';
import { ListFilterComponent } from 'src/app/shared/list-filter/list-filter.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { ConventionsComponent } from 'src/app/shared/conventions/conventions.component';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { ApprovalStateEnum, TravelExpense } from 'src/app/core/interfaces/travel-expense';

@Component({
  selector: 'app-cargo-list-payments',
  templateUrl: './cargo-list-payments.component.html',
  styleUrls: ['./cargo-list-payments.component.scss'],
  providers: [CurrencyPipe]
})
export class CargoListPaymentsComponent implements OnInit {

  @ViewChild(ListFilterComponent, { static: false }) listFilterComponent: ListFilterComponent;
  @Input() typeList: string;
  itemsSelecteds: Array<Cargo> = [];
  filters: string = '';
  activeSelectItemToPay = false;
  activeSelectItemExtraAdvance = false;
  activeSelectItemApproval = false;
  enableSelectItemByList = false;
  optionsFilter: Filter;
  paginationList: PaginationList = new PaginationList();
  permission = Permission;

  constructor(
    private spinner: NgxSpinnerService,
    public service: FreightListService,
    public utils: Utils,
    public authService: AuthService,
    private managerDialog: Dialog,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private cargoItemService: CargoItemService,
    private permissionRole: PermissionRole

  ) {
    localStorage.removeItem('hasOpenEval');
  }

  ngOnInit() {
    this.optionsFilter = this.service.getOptionsFilter(this.typeList);
    this.enableSelectItemByList = this.utils.enableSelectItemByList(this.typeList);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  onFilter($event) {
    this.resetPagging();
    this.filters = $event;
    this.getListCargo(this.filters);
  }

  getListCargo($event) {
    let service = null;
    this.itemsSelecteds = [];
    this.spinner.show();
    // Viáticos
    if (this.service.isTravelExpensePayment(this.typeList)) {
      service = this.service.getListCargoTravelExpenses(
        $event,
        this.paginationList.getPageKey(),
        this.paginationList.getPageSize()
      );

      this.activeSelectItemToPay = true;
    } else if (this.service.isPaymentCargo(this.typeList) && $event.search('paid=true') === -1) {
      // Payment
      service = this.service.getListCargoToPay(
        $event,
        this.paginationList.getPageKey(),
        this.paginationList.getPageSize()
      );
      this.activeSelectItemToPay = true;
    } else {
      // General
      this.activeSelectItemToPay = false;
      if ($event.search('paymentType=advanceState') > -1) {
        this.activeSelectItemExtraAdvance = true;
      } else {
        this.activeSelectItemExtraAdvance = false;
      }
      if ($event.search('approval=Pending') > -1 &&
        this.permissionRole.hasPermission(
          this.permission.payments.module,
          this.permission.payments.approvePayments
        )) {
        this.activeSelectItemApproval = true;
      } else {
        this.activeSelectItemApproval = false;
      }

      $event = $event.replace('&paymentType=advanceState', '');
      service = this.service.getListCargo(
        $event,
        this.paginationList.getPageKey(),
        this.paginationList.getPageSize()
      );
    }

    service.subscribe(
      (data: any) => {
        if ((this.service.isPaymentCargo(this.typeList) && (this.service.isTravelExpensePayment(this.typeList) || $event.search('paid=true') === -1))) {
          if (data && data.length) {
            data = data.map((item) => {
              if (item && item.cargo) {
                const cargo: Cargo = item.cargo;
                if (!!item.travelExpenses) {
                  if (Array.isArray(item.travelExpenses))
                    cargo.travelExpenses = item.travelExpenses;
                  else
                    cargo.travelExpense = item.travelExpenses;
                }

                if (!this.utils.isEmpty(item.validatedBankAccount)) {
                  cargo.validatedBankAccount = item.validatedBankAccount;
                }
                return cargo;
              }
            });
          }
        }

        if (!this.utils.isDefined(data) || data.length === 0) {
          this.paginationList.setEnablePagingScroll(false);
          data = [];
        }

        if (this.paginationList.getList().length) {
          this.paginationList.setList(
            this.paginationList.getList().concat(data)
          );
        } else {
          this.paginationList.setList(data);
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        if (!(this.paginationList.getList().length > 0)) {
          this.resetPagging();
          this.paginationList.setList([]);
        }
      }
    );
  }

  onSelectItem($event) {
    this.itemsSelecteds = $event;
  }

  resetPagging() {
    this.paginationList.setPageKey(1);
    this.paginationList.setList([]);
    this.paginationList.setEnablePagingScroll(true);
    this.activeSelectItemExtraAdvance = false;
    this.activeSelectItemApproval = false;
    this.activeSelectItemToPay = true;
  }

  refreshActualList() {
    !this.paginationList
      ? this.paginationList.setList([])
      : this.resetPagging();
    this.getListCargo(this.filters);
  }

  resetActualList() {
    !this.paginationList
      ? this.paginationList.setList([])
      : this.resetPagging();
  }

  refreshList($event?) {
    !this.paginationList
      ? this.paginationList.setList([])
      : this.resetPagging();

    if ($event) {
      this.filters = $event;
    }
    this.getListCargo(this.filters);
  }

  onScrollDown() {
    if (
      this.paginationList.getEnablePagingScroll() &&
      this.typeList !== 'loadsInNegotiation' &&
      this.typeList !== 'cargoRequestNegotiation' &&
      (((this.service.isPaymentCargo(this.typeList) && this.filters.search('paymentType=true') > 0) || (this.service.isPaymentCargo(this.typeList) &&
        this.filters.search('paid=true') === -1)) || !this.service.isPaymentCargo(this.typeList))
    ) {
      this.paginationList.setPageKey(
        this.paginationList.getPageKey() + 1
      );
      this.getListCargo(this.filters);
    }
  }


  get showSelectAll() {
    let state = false;
    if (this.enableSelectItemByList &&
      !this.isCargoAdditionalCostsPaid() &&
      !this.isCargoAdvancePaid() &&
      (this.isCargoToPayExtraAdvance() ||
        this.isCargoToCharge() ||
        this.isCargoToApproval() ||
        this.activeSelectItemToPay)) {
      state = true;
    }
    return state;
  }

  public isActiveSelectedItemCargo(cargo: Cargo): boolean {
    return (
      this.enableSelectItemByList &&
      !this.disableSelectedItem() &&
      (
        this.isCargoToPayExtraAdvance() ||
        this.isCargoToCharge() ||
        this.isCargoToApproval() ||
        this.isCargoWithValidTravelExpense(cargo) ||
        this.isCargoToGeneralPay(cargo)
      ) &&
      !(this.hasPendingTravelExpenseLegalization(cargo) && this.typeList == 'paymentBalanceCargo')
    );
  }

  private disableSelectedItem(): boolean {
    return (
      (
        this.typeList === 'paymentAdditionalCostsCargo' &&
        this.filters.search('additionalCostPaid=true') >= 0
      ) ||
      (
        this.typeList === 'paymentAdvanceCargo' &&
        this.filters.search('advanceState=true') >= 0 &&
        this.filters.search('paymentType=advance') >= 0
      ) ||
      (this.filters.search('paid=true') >= 0 &&
        this.service.isTravelExpensePayment(this.typeList))
    );
  }

  getActiveItemSelected(cargo?: Cargo) {
    let state = false;
    if (this.enableSelectItemByList &&
      !this.isCargoAdditionalCostsPaid() &&
      !this.isCargoAdvancePaid() &&
      (this.isCargoToGeneralPay(cargo) ||
        this.isCargoToPayExtraAdvance() ||
        this.isCargoToCharge() ||
        this.isCargoToApproval())) {
      state = true;
    }
    return state;
  }

  isCargoToCharge() {
    let state = false;
    if (
      this.typeList === 'charges'
    ) {
      state = true;
    }
    return state;
  }

  isCargoAdditionalCostsPaid() {
    let state = false;
    if (
      this.typeList === 'paymentAdditionalCostsCargo' &&
      this.filters.search('additionalCostPaid=true') >= 0
    ) {
      state = true;
    }
    return state;
  }

  isCargoAdvancePaid() {
    let state = false;
    if (
      this.typeList === 'paymentAdvanceCargo' &&
      this.filters.search('advanceState=true') >= 0 &&
      this.filters.search('paymentType=advance') >= 0
    ) {
      state = true;
    }
    return state;
  }

  isCargoPaySoon(cargo?: Cargo) {
    let state = false;
    if (
      this.typeList === 'paymentBalanceCargo' &&
      this.filters.search('fastPayment=true') >= 0 &&
      cargo.approval === 'Approved' && cargo.state === CargoStateEnum.END_SERVICE && !cargo.shippingCost.paid
    ) {
      state = true;
    }
    return state;
  }

  isCargoToPayExtraAdvance() {
    let state = false;
    if (
      this.service.isPaymentCargo(this.typeList) &&
      this.activeSelectItemExtraAdvance
    ) {
      state = true;
    }
    return state;
  }

  isCargoToApproval() {
    let state = false;
    if (
      this.typeList === 'approvePayments' &&
      this.activeSelectItemApproval
    ) {
      state = true;
    }
    return state;
  }

  get excludeItemsList() {
    let toExclude = [];

    if (this.service.isPaymentCargo(this.typeList)) {
      toExclude.push({
        field: 'validatedBankAccount',
        value: false
      });
    }

    if (this.service.isTravelExpensePayment(this.typeList)) {
      toExclude.push({
        field: 'travelExpense.paid',
        value: true
      });
    }

    if (this.typeList == 'paymentBalanceCargo') {
      toExclude.push({
        field: 'travelExpenses',
        value: (travelExpenses: Array<TravelExpense>) => {
          if (Array.isArray(travelExpenses))
            return travelExpenses.some((travelExpense => travelExpense.approval == ApprovalStateEnum.PENDING));
          return false;
        }
      });
    }

    return toExclude;
  }

  isCargoToGeneralPay(cargo: Cargo) {
    let state = false;
    if (
      this.service.isPaymentCargo(this.typeList) &&
      this.utils.isDefined(cargo) &&
      this.utils.isDefined(cargo.validatedBankAccount) &&
      cargo.validatedBankAccount === true &&
      (
        (this.cargoItemService.isApproveBalance(cargo) && this.typeList === 'paymentBalanceCargo' && this.filters.search('fastPayment=true') < 0) ||
        this.typeList !== 'paymentBalanceCargo' ||
        this.isCargoPaySoon(cargo)
      )
    ) {
      state = true;
    }
    return state;
  }

  hasPendingTravelExpenseLegalization(cargo: Cargo): boolean {
    const travelExpenses: Array<TravelExpense> | undefined = this.utils.getNestedValue(cargo, 'travelExpenses');
    if (Array.isArray(travelExpenses))
      return travelExpenses.some((travelExpense => travelExpense.approval == ApprovalStateEnum.PENDING));
    return false;
  }

  isCargoWithValidTravelExpense(cargo: Cargo) {
    if (!cargo.confirmedDriver)
      return false;
    const travelExpense: TravelExpense | undefined = this.utils.getNestedValue(cargo, 'travelExpense');

    if (travelExpense) {
      return this.service.isTravelExpensePayment(this.typeList) &&
        !travelExpense.paid
    }
    return false;
  }


  openConventions() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      transported: true
    };
    dialogConfig.autoFocus = false;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialog.open(ConventionsComponent, dialogConfig);
  }

  get hasOperationsTop(): boolean {
    let additionalServicesWithPermission =
      ['paymentAdditionalCostsCargo'].includes(this.typeList)
      && this.permissionRole.hasPermission(
        this.permission.payments.module,
        this.permission.payments.paymentAdvance
      );
    return additionalServicesWithPermission;
  }

  get hasOperationsInline(): boolean {
    let advanceBalancePermission =
      ["paymentAdvanceCargo", "paymentBalanceCargo"].includes(this.typeList)
      && this.permissionRole.hasPermission(
        this.permission.payments.module,
        this.permission.payments.paymentAdvance
      );
    let extraAdvancePermission =
      this.typeList === "paymentExtraAdvanceCargo"
      && this.permissionRole.hasPermission(
        this.permission.payments.module,
        this.permission.payments.paymentExtraAdvance
      )
    let travelExpensesPermission = true;
    this.typeList === 'paymentTravelExpenses'
      && this.permissionRole.hasPermission(
        this.permission.payments.module,
        this.permission.payments.paymentExtraAdvance
      );

    return advanceBalancePermission || extraAdvancePermission || travelExpensesPermission;
  }

  get hasOperations(): boolean {
    return this.hasOperationsTop || this.hasOperationsInline;
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }
}
