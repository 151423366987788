import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { CargoItemService } from "./cargo-item.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { Filter } from "src/app/core/models/filter";
import { AuthService } from "src/app/core/services/authentication.service";
import { User } from "src/app/core/interfaces/user";
import { Global } from "src/app/core/resources/global";
import { Cargo } from "src/app/core/interfaces/cargo";
import { Utils } from "src/app/core/resources/utils";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { VehiclesService } from "../../administration/vehicles/list-vehicles.service";
import { Permission } from "src/app/core/resources/permission";
import { PermissionRole } from "src/app/core/resources/permission-role";
import { Titles } from "src/app/core/resources/titles";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { DateFormatPipe } from "src/app/core/pipe/dateFormat.pipe";
import { HttpErrorResponse } from "@angular/common/http";
import { Dialog } from "src/app/core/resources/dialog";
import { FreightListService } from "../cargo-list/cargo-list.service";
import { SummaryAdditionalCostCargoComponent } from "../summary-additional-cost-cargo/summary-additional-cost-cargo.component";
import { DetailVehicleComponent } from "../../administration/vehicles/detail-vehicle/detail-vehicle.component";
import { Vehicle } from "src/app/core/interfaces/vehicle";
import { UserFormComponent } from "../../administration/admin-users/user-form/user-form.component";
import { AdminUsersService } from "../../administration/admin-users/admin-users.service";
import { CargoManager } from "src/app/core/managers/cargo.manager";
import { ExpirationDateContainerEnum } from "src/app/core/enums/expirationDateContainer.enum";
import { UpdateAdditionalCostCargoComponent } from "../cargo-detail/components/update-additional-cost-cargo/update-additional-cost-cargo.component";
import { ExpirationDateDTAEnum } from "src/app/core/enums/expirationDateDTA.enum";
import { RolesService } from "../../administration/roles/roles.service";
import { Role } from "src/app/core/interfaces/role";
import { AssignVehicleCargoComponent } from "../assign-vehicle-cargo/assign-vehicle-cargo.component";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { ReceivableService } from "../../administration/receivable/receivable.service";
import { ServiceMessages } from "src/app/core/messages/service-messages.enum";
import { SummaryBalanceComponent } from "../summary-balance/summary-balance.component";
import { AmountsCargoEnum } from "src/app/core/enums/amountsCargo.enum";
import { catchError } from "rxjs/operators";
import { forkJoin, throwError } from "rxjs";
import { DateManager } from "src/app/core/managers/date.manager";
import { ColorService } from "src/app/core/services/color.service";
import { CargoShippingCostDialogComponent } from "../cargo-shipping-cost-dialog/cargo-shipping-cost-dialog.component";
import { ExpirationBillEnum } from "src/app/core/enums/expirationBill.enum";
import { environment } from "src/environments/environment";
import { CargoStateEnum } from "src/app/core/enums/cargoState.enum";
import { CargoService } from "src/app/core/services/cargo.service";
import { BasicCompany } from "src/app/core/interfaces/basicCompany";
import { Company } from "src/app/core/interfaces/company";
import { AccountService } from "../../account/account.service";
import { TravelExpensesService } from "src/app/core/services/travel-expenses.service";
import { CatalogItem } from "src/app/core/interfaces/catalogItem";
import { ApprovalStateEnum, TravelExpense, TravelExpenseDetail } from "src/app/core/interfaces/travel-expense";
import { LastPointLocation } from "src/app/core/interfaces/lastPointLocation";

@Component({
  selector: "app-cargo-item",
  templateUrl: "./cargo-item.component.html",
  styleUrls: ["./cargo-item.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    CargoItemService,
    VehiclesService,
    PermissionRole,
    DateFormatPipe,
    CargoManager,
  ],
})
export class CargoItemComponent implements OnInit {
  public vehicle: Vehicle;
  public lastPoint: string;
  public timeLastLocation: any;
  public lastInspection: any;
  public lastContainerInspection: any;
  @Input() cargoItemList: any;
  @Input() showBells: boolean;
  @Input() typeList: string;
  @Output() applyFilter: EventEmitter<any> = new EventEmitter();
  @Output() navigate: EventEmitter<any> = new EventEmitter();
  @Output() closeVehicleDetail: EventEmitter<boolean> = new EventEmitter();
  @Input() option: Filter;
  @Input() list: any;
  @Input() filteredCargosTurns: any;
  @Input() hideOptionsLoad: boolean = false;
  @Input() travelExpense?: TravelExpense;
  public expirationDateContainerEnum = ExpirationDateContainerEnum;
  public expirationBillEnum = ExpirationBillEnum;
  public ExpirationDateDTAEnum = ExpirationDateDTAEnum;
  dialogFlags = {};
  diffInDays: any;
  diffInDaysContainerExp: any;
  diffInDaysBillExp: any;
  cargo: Cargo;
  user: User;
  permission = Permission;
  negotiation = {
    state: null,
    driver: null,
    negotiatedValueDriver: null,
    negotiatedValueCreator: null,
  };
  negotiationRequest = {
    state: null,
    companyId: null,
    negotiatedValueCompany: null,
    negotiatedValueCreator: null,
  };
  idUserActive = this.authService
    .getUserSession()
    .information.document;
  userActive = this.authService.getUserSession().information;
  // public listAdditionalService: AdditionalCost[] = [];
  listActive: string = "";
  expirationDaysDTAToLoad: number = 0;
  expirationDaysDTAToDownload: number = 0;
  listRoles: Role[] = [];
  listAbleRoles: Role[] = [];
  pendingReceivables: string = "";

  travelExpenseTypes: Array<CatalogItem> = [];
  listCompanies: BasicCompany[] = [];
  company: Company;
  travelExpenses: Array<TravelExpense> = [];

  public incident: any = { asset: null, name: null, id: null };
  public dateManager = DateManager;

  constructor(
    public utils: Utils,
    public cargoItemService: CargoItemService,
    public rolesService: RolesService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService,
    public route: ActivatedRoute,
    private adminUsersService: AdminUsersService,
    public global: Global,
    public matDialog: MatDialog,
    private vehiclesService: VehiclesService,
    public permissionRole: PermissionRole,
    public titles: Titles,
    public dialog: Dialog,
    public cargoListService: FreightListService,
    public dialogMat: MatDialog,
    private cargoManager: CargoManager,
    public receivableService: ReceivableService,
    public cargoService: CargoService,
    private accountService: AccountService,
    private travelExpensesService: TravelExpensesService
  ) { }

  ngOnInit() {
    this.company = this.authService.getCompany();
    this.listCompanies = this.accountService.listRegisterCompanies();
    if (sessionStorage.getItem("_lastList")) {
      this.listActive = sessionStorage.getItem("_lastList").toString();
    }
    if (this.cargo) {
      this.incident = this.cargoItemService.getIndicator(this.cargo);
      if (this.cargo.containerExpirationDate) this.getContainerExpirationDate();
      if (this.cargo.approvalDate && this.canAlertBill) this.getBillExpirationDate();
      if (this.cargo.cargoModel && this.cargo.cargoModel.tripType && this.cargo.cargoModel.tripType.date && ['DUTA', 'OTM', 'DTA'].includes(this.cargo.cargoModel.tripType.regimeType)) {
        this.getDTAExpirationDate()
      }
      this.travelExpenses = this.cargo.travelExpenses;
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cargoItemList && changes.cargoItemList.currentValue) {
      if (this.utils.isDefined(changes.cargoItemList.currentValue)) {
        if (this.isNegotiation) {
          this.validateDataNegotiation(changes);
        } else {
          this.cargo = changes.cargoItemList.currentValue;
          if (
            this.cargoLastPointLocation && this.cargoLastPointLocation.fingerprint &&
            this.cargoLastPointLocation.fingerprint.date
          ) {
            this.lastPoint = DateManager.dateDiffFromNow(this.cargoLastPointLocation.fingerprint.date, 'YYYY-MM-DD HH:mm ZZ', false);
          }
          this.negotiation = {
            state: null,
            driver: null,
            negotiatedValueDriver: null,
            negotiatedValueCreator: null,
          };
          this.negotiationRequest = {
            state: null,
            companyId: null,
            negotiatedValueCompany: null,
            negotiatedValueCreator: null,
          };
        }
      }
    }
  }

  get isNegotiation(): boolean {
    let state = false;
    switch (this.typeList) {
      case "loadsInNegotiation":
      case "cargoRequestNegotiation":
        state = true;
        break;
    }
    return state;
  }
  public goToList() {
    this.router.navigate(["cargo/list/" + this.listActive]);
  }

  showAdditionalServiceCargo() {
    if (this.dialogFlags['UpdateAdditionalCostCargoComponent']) return;
    this.dialogFlags['UpdateAdditionalCostCargoComponent'] = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
      listAdditionalService: this.cargo.additionalCosts,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialogMat.open(
      UpdateAdditionalCostCargoComponent,
      dialogConfig
    ).afterClosed().subscribe(() => {
      this.dialogFlags['UpdateAdditionalCostCargoComponent'] = false;
      this.goToList();
      this.applyFilter.emit();
    });
  }

  validateDataNegotiation(changes) {
    if (changes.cargoItemList.currentValue.cargo) {
      if (this.utils.isDefined(changes.cargoItemList.currentValue.cargo)) {
        this.cargo = changes.cargoItemList.currentValue.cargo;
      }

      if (this.utils.isDefined(changes.cargoItemList.currentValue.state)) {
        this.negotiation.state = changes.cargoItemList.currentValue.state;
      }

      if (this.utils.isDefined(changes.cargoItemList.currentValue.driver)) {
        this.negotiation.driver = changes.cargoItemList.currentValue.driver;
      }

      if (
        this.utils.isDefined(
          changes.cargoItemList.currentValue.negotiatedValueDriver
        )
      ) {
        this.negotiation.negotiatedValueDriver =
          changes.cargoItemList.currentValue.negotiatedValueDriver;
      }

      if (
        this.utils.isDefined(
          changes.cargoItemList.currentValue.negotiatedValueCreator
        )
      ) {
        this.negotiation.negotiatedValueCreator =
          changes.cargoItemList.currentValue.negotiatedValueCreator;
      }

      //
      if (this.utils.isDefined(changes.cargoItemList.currentValue.state)) {
        this.negotiationRequest.state =
          changes.cargoItemList.currentValue.state;
      }

      if (this.utils.isDefined(changes.cargoItemList.currentValue.companyId)) {
        this.negotiationRequest.companyId =
          changes.cargoItemList.currentValue.companyId;
      }

      if (
        this.utils.isDefined(
          changes.cargoItemList.currentValue.negotiatedValueCompany
        )
      ) {
        this.negotiationRequest.negotiatedValueCompany =
          changes.cargoItemList.currentValue.negotiatedValueCompany;
      }

      if (
        this.utils.isDefined(
          changes.cargoItemList.currentValue.negotiatedValueCreator
        )
      ) {
        this.negotiationRequest.negotiatedValueCreator =
          changes.cargoItemList.currentValue.negotiatedValueCreator;
      }
    }
  }

  openDetail(cargo: Cargo) {
    let route = "/cargo/detail";
    sessionStorage.setItem("_lastList", this.typeList.toString());

    if (this.isLoadingRoutesTab) {
      route = "/cargo/tracking/";
    }
    if (this.typeList === "loadsInNegotiation") {
      this.authService.cargoActive.cargo = cargo;
      this.authService.cargoActive.negotiation = this.negotiation;
      this.navigate.emit("cargo/negotiation");
      this.router.navigate(["cargo/negotiation"]);
    } else if (this.typeList === "cargoRequestNegotiation") {
      this.authService.cargoActive.cargo = cargo;
      this.authService.cargoActive.negotiationRequest = this.negotiationRequest;
      this.navigate.emit("cargo/negotiation");
      this.router.navigate(["cargo/negotiation"]);
    } else {
      this.authService.cargoActive.cargo = cargo;
      this.authService.cargoActive.negotiation = {
        state: null,
        driver: null,
        negotiatedValueDriver: null,
        negotiatedValueCreator: null,
      };
      this.navigate.emit("cargo/negotiation");
      this.router.navigate([route, cargo.consecutive]);
    }
  }

  isFixedCargo() {
    if (
      this.utils.isDefined(this.cargo) &&
      this.utils.isDefined(this.cargo.cargoFeature) &&
      this.utils.isDefined(this.cargo.cargoFeature.productType) &&
      this.utils.isDefined(this.cargo.cargoFeature.productType.type) &&
      this.cargo.cargoFeature.productType.type !== "FixedCargo"
    ) {
      return true;
    } else {
      return false;
    }
  }

  openDetailVehicle() {
    if (this.dialogFlags['DetailVehicleComponent']) return;
    this.dialogFlags['DetailVehicleComponent'] = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      vehicle: this.vehicle,
      close: true,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    this.matDialog.open(DetailVehicleComponent, dialogConfig).afterClosed().subscribe(() => {
      this.dialogFlags['DetailVehicleComponent'] = false;
      this.closeVehicleDetail.emit(true);
    })
  }

  openDetailDriver() {
    if (this.dialogFlags['UserFormComponent']) return;
    this.dialogFlags['UserFormComponent'] = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      driver: this.user,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialogMat.open(
      UserFormComponent,
      dialogConfig
    ).afterClosed().subscribe(() => {
      this.dialogFlags['UserFormComponent'] = false;
    })
  }

  getDetailUserByDocument() {
    if (!this.showDriverDetail) return;
    this.spinner.show();
    this.adminUsersService
      .getUsersDetailByDocument(this.cargo.driver)
      .subscribe(
        (success: User) => {
          this.user = success;
          this.spinner.hide();
          this.openDetailDriver();
        },
        (error) => {
          this.user = null;
          this.spinner.hide();
          this.openDetailDriver();
        }
      );
  }

  async getDetailVehicle(action?) {
    if (this.cargo && this.utils.isDefined(this.cargo.licensePlate)) {
      return new Promise((resolve, reject) => {
        this.vehiclesService.getVehicle(this.cargo.licensePlate).subscribe(
          (success: any) => {
            if (success) {
              this.vehicle = success;
              if (action === "openDetail" && this.showVehicleDetail) {
                this.openDetailVehicle();
              }
              resolve(true);
            } else {
              this.vehicle = null;
              reject(false)
            }
          },
          (error) => {
            this.vehicle = null;
            reject(false);
          }
        );
      })
    }
  }

  getContainerExpirationDate() {
    this.diffInDaysContainerExp = DateManager.dateDiff(this.cargo.containerExpirationDate, 'DD/MM/YYYY', new Date());
  }
  getBillExpirationDate() {
    this.diffInDaysBillExp = DateManager.dateDiff(
      DateManager.add(this.cargo.approvalDate, 5, 'days'),
      null,
      new Date()
    );
  }

  get canAlertBill(): boolean {
    return this.typeList === 'charges' && !!this.cargo && !!this.cargo.approvalDate &&
      !DateManager.isBefore(
        DateManager.stringToDate(this.cargo.approvalDate),
        DateManager.stringToDate('23/02/2024', 'DD/MM/YYYY'));
  }

  getDTAExpirationDate() {
    this.expirationDaysDTAToLoad = DateManager.dateDiff(DateManager.add(this.cargo.cargoModel.tripType.date, 5, 'days', 'YYYY-MM-DD'));
    this.expirationDaysDTAToDownload = DateManager.dateDiff(DateManager.add(this.cargo.cargoModel.tripType.date, 10, 'days', 'YYYY-MM-DD'));
  }

  async confirmDriverValidations() {
    try {
      this.getDetailVehicle()
        .then(success => {
          if (success) {
            this.spinner.show();
            forkJoin([
              this.cargoItemService.getInspection(this.vehicle && this.vehicle.lastInspection ? this.vehicle.lastInspection : null, "vehicle"),
              this.cargoItemService.getInspection(this.cargo && this.cargo.containerInspectionId ? this.cargo.containerInspectionId : null, "container")
            ])
              .pipe(
                catchError((error) => {
                  this.spinner.hide();
                  this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, error);
                  return throwError(error);
                })
              )
              .subscribe(([vehicleInspection, containerInspection]) => {
                this.spinner.hide();
                if (vehicleInspection) this.lastInspection = vehicleInspection;
                if (containerInspection) this.lastContainerInspection = containerInspection;
                this.openModalConfirmDriver();
                this.belowSicetac(this.cargo, `cargo/detail/${this.cargo.consecutive}`);
              });
          } else {
            this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
          }
        })
        .catch(error => {
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        })
    } catch (error) {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
    }
  }

  openModalConfirmDriver() {
    if (this.cargoManager.getTypeCargo(this.cargo) == "Nacional" || this.cargoManager.getTypeCargo(this.cargo) == "Internacional") {
      let hasContainer = false;
      let vehicleInspection = false;
      let containerInspection = false;
      if (this.vehicle && this.vehicle.vehicleType && this.vehicle.vehicleType.name &&
        this.vehicle.vehicleType.name.toUpperCase() === "TRACTOCAMION") {
        hasContainer = !!this.vehicle.trailerId && !!this.cargo.container;
        if (hasContainer && this.lastContainerInspection && this.lastContainerInspection.id)
          containerInspection = this.lastContainerInspection.approval;
      }
      if (this.lastInspection && this.lastInspection.id) {
        vehicleInspection = this.lastInspection.approval;
        if (vehicleInspection) {
          if (hasContainer) {
            containerInspection
              ? this.validateReceivables()
              : this.snackBarService.openSnackBar(
                "Aún no se ha aprobado la Inspección a las unidades de carga",
                undefined,
                "alert"
              );
          } else {
            this.validateReceivables();
          }
        } else {
          this.snackBarService.openSnackBar(
            "Aún no se ha aprobado la Inspección a las unidades de transporte de carga",
            undefined,
            "alert"
          );
        }
      } else {
        const dialogConfig = new MatDialogConfig();
        let who;
        if (this.cargo.assignedVehicleFingerPrint) {
          who = this.cargo.assignedVehicleFingerPrint.userName;
        } else {
          if (this.cargo.numberDocumentCreatorLoad === this.authService.getUserSession().information.document) {
            this.createInspection();
            return;
          }
          who = "el Operador que asigno el vehiculo";
        }
        dialogConfig.data = {
          title:
            "No se encontro registrada una inspección para este vehiculo. Debes comunicarte con " +
            who +
            ` para solicitar el registro de la inspección.`,
          hideBtnCancel: true,
        };
        dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
        dialogConfig.width = ModalEnum.SMALL_WIDTH;
        dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
        dialogConfig.autoFocus = false;
        const dialogRef = this.dialogMat.open(DialogComponent, dialogConfig);
      }
    } else {
      this.validateReceivables();
    }
  }

  private createInspection() {
    this.cargoManager.checkInspection(this.cargo, this.authService.getUserSession().information.document, this.cargo.licensePlate)
      .then((state) => {
        if (state) {
          this.confirmDriverValidations();
        }
      }).catch(() => { });
  }

  get isPrincipalCompany(): boolean {
    return !!(this.listCompanies.length && this.company && this.company.companyId && this.listCompanies.some(company => company.nit === this.company.companyId));
  }

  dialogConfirmDriver() {
    if (this.dialogFlags['DialogComponent']) return;
    this.dialogFlags['DialogComponent'] = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: this.titles.titleConfirmDriver,
      subtitle: this.pendingReceivables ? this.pendingReceivables : '',
      licen: this.pendingReceivables ? '' : this.cargo.licensePlate,
      idCargo: this.pendingReceivables ? '' : this.cargo.consecutive,
    };
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = this.pendingReceivables ? ModalEnum.LARGE_WIDTH : ModalEnum.MEDIUM_WIDTH;

    if (
      this.isPrincipalCompany &&
      this.company && this.company.gpsConfiguration && this.company.gpsConfiguration.monitor && this.company.gpsConfiguration.monitor.active
    ) {
      dialogConfig.data.snackbarMessage =
        'El GPS de este vehículo no está disponible para la trazabilidad automática.';
    }

    this.matDialog.open(DialogComponent, dialogConfig).afterClosed().subscribe((result) => {
      this.dialogFlags['DialogComponent'] = false;
      if (result && result.state) {
        const data: any = {
          cargoId: this.cargo.id,
          fingerprint: {
            userId: this.authService
              .getUserSession()
              .information.document,
            userName: this.authService
              .getUserSession()
              .information.name,
          },
        };
        const approval = result.refuse;
        this.confirmDriver(data, approval);
      }
    });
  }

  confirmDriver(data, approval) {
    this.spinner.show();
    this.cargoItemService.confirmDrive(data, approval).subscribe(
      (success: Cargo) => {
        this.spinner.hide();
        this.applyFilter.emit();
        if (
          !this.utils.isEmpty(success) &&
          !this.utils.isEmpty(success.message)
        ) {
          this.dialog.openDialog({
            title: success.message,
            iconError: true,
            hideBtnConfirm: true,
          }).then().catch(err => err);
        } else {
          this.snackBarService.openSnackBar(approval && approval === 'true' ? "Placa confirmada correctamente" : "Se ha rechazado la confirmacion correctamente");
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (
          !this.utils.isEmpty(error) &&
          !this.utils.isEmpty(error.error) &&
          !this.utils.isEmpty(error.error.message)
        ) {
          this.snackBarService.openSnackBar(
            error.error.message,
            undefined,
            "alert"
          );
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al confirmar la placa",
            undefined,
            "error"
          );
        }
      }
    );
  }

  validateReceivables() {
    const observerListReceivables = {
      next: (data) => {
        if (data && data.some(receivable => receivable.state && receivable.state.description && receivable.state.description === 'pending')) {
          this.pendingReceivables = `El tenedor de este vehiculo ( ${this.vehicle.bankAccountBalance.name} ) tiene carteras pendientes. ¿Está seguro que desea confirmar la placa ${this.cargo.licensePlate} como vehiculo para el servicio ${this.cargo.consecutive}?`;
        } else {
          this.pendingReceivables = "";
        }
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error");
      },
      complete: () => {
        this.dialogConfirmDriver();
        this.spinner.hide();
      }
    };
    if (this.vehicle && this.vehicle.bankAccountBalance && this.vehicle.bankAccountBalance.document) {
      this.spinner.show();
      this.receivableService.getReceivableList(null, null, this.vehicle.bankAccountBalance.document).subscribe(observerListReceivables);
    } else {
      this.pendingReceivables = "";
      this.dialogConfirmDriver();
    }
  }

  public semaphoreApproved() {
    if (
      this.cargo.state == CargoStateEnum.END_SERVICE &&
      this.cargo.shippingCost &&
      !this.cargo.shippingCost.paid &&
      this.cargo.durationTime
    ) {
      let endDateWithExtraHours: Date = DateManager.add(this.cargo.durationTime.endDate, 72, 'hours');
      let diff;
      if (DateManager.isSameOrBefore(endDateWithExtraHours, new Date())) {
        diff = DateManager.dateDiff(new Date(), null, endDateWithExtraHours);
      } else {
        diff = DateManager.dateDiff(endDateWithExtraHours, null, new Date());
      }

      if (this.cargo.stateTracking != 'Pause') {
        if (diff > 0 && diff <= 15) {
          return { "border-left": "10px solid blue" };
        } else if (diff > 15 && diff <= 30) {
          return { "border-left": "10px solid orange" };
        } else if (diff > 30) {
          return { "border-left": "10px solid red" };
        }
      }

      return { "border-left": "10px solid white" };
    } else if (
      this.cargo.state == CargoStateEnum.END_SERVICE &&
      this.cargo.shippingCost &&
      this.cargo.shippingCost.paid
    ) {
      return { "border-left": "10px solid green" };
    }
  }

  get semaphore() {
    if (this.option && this.option.showFilterAprovedCargo) {
      return this.semaphoreApproved();
    } else if (this.showTransportedValueSemaphore()) {
      return this.semaphoreCargooAmount();
    } else if (this.showSemaphorepayment()) {
      return this.semaphoreState();
    }
  }

  get semaphoreBody() {
    const semaphore = this.semaphoreCargooAmount();
    let background = semaphore["border"].split(" ")[2] || semaphore["border"].split(" ")[1];

    const contrast_white = ColorService.getContrast(parseInt(`0xff${background.slice(1)}`), 0xffffffff);
    const contrast_black = ColorService.getContrast(parseInt(`0xff${background.slice(1)}`), 0xff000000);
    let color = contrast_white > contrast_black ? '#ffffff' : '#000000';

    if (this.cargo.stateTracking == 'Pause') {
      color = '#000000';
      background = '#ffffff';
    }

    return { background, color };
  }

  get riskProfile() {
    return this.cargo.riskProfile && this.cargo.riskProfile.name ? this.cargo.riskProfile.name : '';
  }

  public semaphoreCargooAmount() {
    let absDiff = 0
    if (this.cargoLastPointLocation && this.cargoLastPointLocation.fingerprint) {
      const diff = DateManager.dateDiff(this.cargoLastPointLocation.fingerprint.date, null, new Date(), 'YYYY-MM-DD HH:mm ZZ', 'minutes');
      absDiff = Math.abs(diff);
    } else if (this.cargo.cargoFeature.uploadDownload.origin && this.cargo.cargoFeature.uploadDownload.origin.addresses && this.cargo.cargoFeature.uploadDownload.origin.addresses.length > 0 && this.cargo.cargoFeature.uploadDownload.origin.addresses[0].durationTime && this.cargo.cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate) {
      const diff = DateManager.dateDiff(this.cargo.cargoFeature.uploadDownload.origin.addresses[0].durationTime.endDate, null, new Date(), 'YYYY-MM-DD HH:mm ZZ', 'minutes');
      absDiff = Math.abs(diff);
    }

    if (absDiff) {
      if (absDiff > AmountsCargoEnum.EMPTY_ALERT_MINS) {
        return { border: "solid #495356" };
      }
      if (absDiff > AmountsCargoEnum.LOW_ALERT_MINS && absDiff <= AmountsCargoEnum.EMPTY_ALERT_MINS) {
        return { border: "solid #FF3203" };
      }
      if (absDiff > AmountsCargoEnum.MEDIUM_ALERT_MINS && absDiff <= AmountsCargoEnum.LOW_ALERT_MINS) {
        return { border: "solid #FFDC1C" };
      }
      if (absDiff > AmountsCargoEnum.HIGH_ALERT_MINS && absDiff <= AmountsCargoEnum.MEDIUM_ALERT_MINS) {
        return { border: "solid #44C98B" };
      }
      if (absDiff <= AmountsCargoEnum.HIGH_ALERT_MINS) {
        return { border: "solid #00D0F5" };
      }
    }

    return { border: "solid white" };

  }

  public isPendingConfirmLicensePlate(): boolean {
    if (
      (this.typeList === "scheduledLoads" || this.typeList === "charges") &&
      this.cargo &&
      this.cargo.state === CargoStateEnum.ACCEPTED &&
      this.cargo.driver &&
      this.cargo.confirmedDriver === false &&
      !this.isNegotiation
    ) {
      const permissionSpecialLicensePlate = this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.specialLicensePlate
      );
      const permissionConfirmLicensePlate = this.permissionRole.hasPermission(
        this.permission.cargo.module,
        this.permission.cargo.confirmLicensePlate
      );
      if (
        permissionSpecialLicensePlate &&
        this.cargo &&
        this.cargo.licensePlate === "C0N111"
      ) {
        return true;
      } else if (
        permissionConfirmLicensePlate &&
        this.cargo &&
        this.cargo.licensePlate !== "C0N111"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get hasPermissionFinancier(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module,
      this.permission.cargo.cargoItemFinancier);
  }

  public validateTime(diff) {
    if (this.cargo.cargoFeature.cargoMeasure.amount <= 400000000) {
      if (diff >= 120 && diff <= 180) {
        return {
          color: "#F5D527",
        };
      } else if (diff > 180) {
        return {
          color: "#FFB71C",
        };
      }
    } else if (
      this.cargo.cargoFeature.cargoMeasure.amount > 400000000 &&
      this.cargo.cargoFeature.cargoMeasure.amount <= 700000000
    ) {
      if (diff >= 60 && diff <= 120) {
        return {
          color: "#7961CF",
        };
      } else if (diff > 120) {
        return {
          color: "#584796",
        };
      }
    } else if (this.cargo.cargoFeature.cargoMeasure.amount > 700000000) {
      if (diff >= 60 && diff <= 63) {
        return {
          color: "#333333",
        };
      } else if (diff > 120) {
        return {
          color: "#161616",
        };
      }
    } else {
      return {
        display: "none",
      };
    }
    return {
      display: "none",
    };
  }

  public semaphoreState() {
    if (
      this.cargo.state == CargoStateEnum.END_SERVICE &&
      this.cargo.shippingCost &&
      !this.cargo.shippingCost.paid &&
      this.cargo.durationTime
    ) {
      const diff = DateManager.dateDiff(new Date(), null, this.cargo.durationTime.endDate);
      if (diff > 0 && diff <= 15) {
        return { "border-left": "10px solid blue" };
      } else if (diff > 15 && diff <= 30) {
        return { "border-left": "10px solid orange" };
      } else if (diff > 30) {
        return { "border-left": "10px solid red" };
      } else {
        return { "border-left": "10px solid white" };
      }
    } else if (
      this.cargo.state == CargoStateEnum.END_SERVICE &&
      this.cargo.shippingCost &&
      this.cargo.shippingCost.paid
    ) {
      return { "border-left": "10px solid green" };
    }
  }

  get showSiigoIcon(): boolean {
    return this.cargo &&
      this.cargo.shippingCost &&
      this.cargo.shippingCost.taxSiigo > 0 &&
      this.utils.isDefined(this.cargo.shippingCost.taxSiigo)
  }

  public showTransportedValueSemaphore(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.transportedValueSemaphore
    );
  }

  public showSemaphorepayment() {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.paymentSemaphore
    );
  }
  public getValueAdditionalCost(type: string): number {
    let value: number = 0;
    if (this.cargo && this.cargo.additionalCosts) {
      const additionalLoadList = this.cargo.additionalCosts.filter(
        (additionalCost) => {
          return additionalCost.type.name === type;
        }
      );
      for (const additionalCost of additionalLoadList) {
        value += (additionalCost && additionalCost.financialInformation) ? additionalCost.financialInformation.cost : 0;
      }
    }
    return value;
  }

  public getValueAdditionalRate(type: string): number {
    let value: number;
    if (this.cargo && this.cargo.additionalCosts) {
      const additionalLoadList = this.cargo.additionalCosts.filter(
        (additionalCost) => {
          return additionalCost.type.name === type;
        }
      );
      if (
        additionalLoadList.length &&
        additionalLoadList[0].financialInformation
      ) {
        value = additionalLoadList[0].financialInformation.rate;
      }
    }
    return value;
  }

  public openDialogDetailAdditionalService() {
    if (this.dialogFlags['SummaryAdditionalCostCargoComponent']) return;
    this.dialogFlags['SummaryAdditionalCostCargoComponent'] = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      additionalCosts: this.cargo.additionalCosts,
    };
    dialogConfig.autoFocus = false;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.dialogMat.open(SummaryAdditionalCostCargoComponent, dialogConfig).afterClosed().subscribe(() => {
      this.dialogFlags['SummaryAdditionalCostCargoComponent'] = false;
    });
  }

  async getShowAdditionalService() {
    return this.cargo.additionalCosts && this.cargo.additionalCosts.length > 0;
  }

  public validateApproveBalance(): void {
    if (this.isPendingApproveBalance()) {
      if (!this.listRoles.length) {
        this.rolesService.getRolesByNit(this.authService.getUserSession().clientCompanyId).subscribe(
          (roles: Role[]) => {
            this.listRoles = roles;
            this.listAbleRoles = this.listRoles.filter(
              role => role && role.modules && role.modules.some(
                module => module.name === "cargo" && module.functionalities && module.functionalities.some(
                  funct => funct.name === "approveBalanceDriver" && funct.visible
                )
              )
            )
            this.dialog.openDialog(
              {
                title: "¡Aprobación Pendiente!",
                description:
                  this.listAbleRoles.length ?
                    `El conductor debe aprobar el pago del saldo desde la aplicación móvil Teclogi Conductor, de lo contrario contactar a un usuario con rol ${this.listAbleRoles.map(role => role.name).join(', ')} para aprobar el pago` :
                    "El conductor debe aprobar el pago del saldo desde la aplicación móvil Teclogi Conductor",
                iconError: true,
                hideBtnConfirm: true,
              }
            ).then().catch(err => err);
          },
          (error) => {
            this.listRoles = [];
          }
        );
      } else {
        this.dialog.openDialog(
          {
            title: "¡Aprobación Pendiente!",
            description:
              this.listAbleRoles.length ?
                `El conductor debe aprobar el pago del saldo desde la aplicación móvil Teclogi Conductor, de lo contrario contactar a un usuario con rol ${this.listAbleRoles.map(role => role.name).join(', ')} para aprobar el pago` :
                "El conductor debe aprobar el pago del saldo desde la aplicación móvil Teclogi Conductor",
            iconError: true,
            hideBtnConfirm: true,
          }
        ).then().catch(err => err);
      }
    }
  }


  public get showAlertCargo(): boolean {
    return (
      this.isPendingApproveBalance() ||
      this.isPendingConfirmLicensePlate() ||
      this.isPendingApproveBankAccount() ||
      this.isPendingApprove() ||
      this.isPendingApproveTravelExpense() ||
      (!this.cargoWithVehicleConfirmed && [this.cargoStateEnum.ACCEPTED].includes(this.cargo.state))
    );
  }

  public isPendingApproveTravelExpense(): boolean {
    if (!Array.isArray(this.travelExpenses))
      return false;
    return (
      this.typeList === 'paymentBalanceCargo' &&
      this.travelExpenses.some((travelExpense => travelExpense.approval == ApprovalStateEnum.PENDING))
    );
  }

  public isPendingApproveBalance(): boolean {
    return (
      this.cargoItemService.isApproveBalance(this.cargo) === false &&
      this.cargo.state === CargoStateEnum.END_SERVICE &&
      this.cargo.shippingCost &&
      !this.cargo.shippingCost.paid
    );
  }

  public isPendingApprove(): boolean {
    return (
      this.cargo.approval !== "Approved" &&
      this.cargo.state === CargoStateEnum.END_SERVICE &&
      this.cargo.shippingCost &&
      !this.cargo.shippingCost.paid
    );
  }

  public get cargoWithVehicleConfirmed(): boolean {
    return this.cargo.confirmedDriver;
  }

  public isPendingApproveBankAccount() {
    return (
      this.cargoWithVehicleConfirmed &&
      this.cargoListService.isPaymentCargo(this.typeList) &&
      ((this.utils.isDefined(this.cargo.validatedBankAccount) &&
        !this.cargo.validatedBankAccount) ||
        (!this.utils.isDefined(this.cargo.validatedBankAccount) &&
          this.cargo.shippingCost &&
          !this.cargo.shippingCost.paid))
    );
  }

  AvaliableTurnList() {
    if (!this.filteredCargosTurns || (this.filteredCargosTurns && this.filteredCargosTurns.matches.length === 0)) return;
    if (this.dialogFlags['AssignVehicleCargoComponent']) return;
    this.dialogFlags['AssignVehicleCargoComponent'] = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Datos del Vehículo Asignado",
      filteredCargosTurns: this.filteredCargosTurns,
      cargo: this.cargo,
      type: 'turn'
    };
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.matDialog.open(AssignVehicleCargoComponent, dialogConfig).afterClosed().subscribe(result => {
      this.dialogFlags['AssignVehicleCargoComponent'] = false;
      if (result && result.state) {
        this.applyFilter.emit();
      }
    });
  }

  public summaryBalance(cargo: Cargo) {
    if (this.dialogFlags['SummaryBalanceComponent']) return;
    this.dialogFlags['SummaryBalanceComponent'] = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      data: this.cargoItemService.getSummaryBalance(cargo, this.getTravelExpensesBalance())
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.matDialog.open(SummaryBalanceComponent, dialogConfig).afterClosed().subscribe(() => {
      this.dialogFlags['SummaryBalanceComponent'] = false;
    });
  }

  public get activeBell(): boolean {
    return (
      this.cargo &&
      this.cargo.state === this.cargoStateEnum.START_SERVICE &&
      this.showTransportedValueSemaphore()
    );
  }

  public get isLoadingRoutesTab(): boolean {
    const list = ['loadingRoutes', 'emptyContainers', 'urbanRoutes', 'lastMileRoutes', 'nationalRoutes', 'exportRoutes', 'importRoutes', 'internationalRoutes'];
    return list.indexOf(this.typeList) !== -1;
  }

  public get cargoLastPointLocation(): LastPointLocation {
    if (this.cargo && this.cargo.lastPointLocation)
      return this.cargo.lastPointLocation;
    if (this.cargo && this.cargo.lastPointLocationGps)
      return this.cargo.lastPointLocationGps;
    return null;
  }

  public get escortedVehicleTooltip(): string {
    if (!this.cargo || !this.cargo.escortedVehicle || !this.cargo.escortedVehicle.licensePlate) return '';
    const escortedVehicle = this.cargo.escortedVehicle;
    return `${escortedVehicle.licensePlate} - Conductor: ${escortedVehicle.driver.name} - Celular: ${escortedVehicle.driver.phoneNumber}`;
  }

  public get trackingTime(): string {
    if (this.cargo && this.cargoLastPointLocation && this.cargoLastPointLocation.fingerprint
      && this.cargoLastPointLocation.fingerprint.date) {
      const lastTrack = DateManager.dateDiffFromNow(this.cargoLastPointLocation.fingerprint.date, null, false);
      return lastTrack ? lastTrack : "Desconocido";
    }
    return "Sin seguimientos";
  }

  public get trackingRiskProfile(): string {
    let risk = 'No especificado';
    switch (this.riskProfile) {
      case "Riesgo alto": risk = 'Alto'; break;
      case "Riesgo medio": risk = 'Medio'; break;
      case "Riesgo bajo": risk = 'Bajo'; break;
    }
    return risk;
  }

  public get trackingRiskProfileBg(): { background: string } {
    let bgRisk = '#333333';
    switch (this.riskProfile) {
      case "Riesgo alto": bgRisk = '#F50D01'; break;
      case "Riesgo medio": bgRisk = '#F3B62A'; break;
      case "Riesgo bajo": bgRisk = '#00D0F5'; break;
    }
    return { background: bgRisk };
  }

  public openShippingCostDetails(type) {
    if (this.dialogFlags['CargoShippingCostDialogComponent']) return;
    this.dialogFlags['CargoShippingCostDialogComponent'] = true;
    const config = new MatDialogConfig();
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.MEDIUM_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    config.data = {
      shippingCostToDisplay: this.cargo.shippingCost.modifications.filter((item) => item.type === type && !!item.amount),
      typePayment: 'DiscountsAndBonuses'
    };
    this.matDialog.open(CargoShippingCostDialogComponent, config).afterClosed().subscribe(() => {
      this.dialogFlags['CargoShippingCostDialogComponent'] = false;
    });
  }

  public belowSicetac(cargo: Cargo, path?: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const formatter = new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    });
    const min = formatter.format(cargo.minimumApprovedValueSicetac);
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.approvedBelowSicetac)) {
      if (cargo.belowSicetac && !cargo.freightValueApprovedBelowSicetac) {
        dialogConfig.data = {
          title: '¿Desea aprobar el flete por debajo del SICETAC?',
          descriptionHTML: `El flete mínimo permitido por SICETAC es de <strong>${min}</strong>, si desea ajustarlo por favor realice una bonificación para ajustarse al valor mínimo permitido.`,
          btnDetailCargo: true,
          path: path,
          closeModal: true,
        };
        dialogConfig.disableClose = true;
        const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.state) {
            this.cargoService.approvedBelowSiceTac(cargo.id).subscribe(
              () => {
                this.openConfirmBelowSicetac();
              },
              (error) => {
                this.snackBarService.openSnackBar("No se pudo aprobar", undefined, 'error');
              }
            );
          }
        })
      }
    } else {
      if (cargo.belowSicetac && !cargo.freightValueApprovedBelowSicetac) {
        dialogConfig.data = {
          title: `El flete mínimo es ${min}, por favor realice una bonificación para ajustarse al valor mínimo permitido`,
          showBtn: true,
        };
        const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => { })
      }
    }
  }

  openConfirmBelowSicetac() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: `Aprobación exitosa`,
      icon: true,
      description: `Se ha aprobado el servicio por un flete que está por debajo del valor mínimo permitido por el SICETAC`,
      hideBtnCancel: false,
      hideBtnConfirm: true,
    };
    this.matDialog.open(DialogComponent, dialogConfig);
  }

  get showVehicleDetail(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.readVehicleDetails);
  }

  get showDriverDetail(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.readDriverDetails);
  }

  get isTeclogiUser(): boolean {
    return this.authService.getCompany().companyId === environment.rootNit;
  }

  get showTurnVehicles(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.viewTurnVehicles);
  }

  get cargoStateEnum(): typeof CargoStateEnum {
    return CargoStateEnum;
  }

  public get isChargeTaxes(): boolean {
    return this.cargoItemService.isChargeTaxes(this.cargo);
  }

  public getTravelExpensesBalance(): number {
    if (!this.travelExpenses)
      return 0;

    const totalPaid = this.travelExpenses
      .filter(travelExpense => travelExpense.paid)
      .reduce((acc, travelExpense: TravelExpense) => acc + travelExpense.totalPaid, 0);

    const totalValidSpent = this.travelExpenses
      .map(travelExpenses => travelExpenses.travelExpensesDetail)
      .reduce((acc, travelExpenseDetails: Array<TravelExpenseDetail>) => acc.concat(travelExpenseDetails), [])
      .filter(travelExpenseDetail => travelExpenseDetail.approval === ApprovalStateEnum.APPROVED)
      .reduce((acc, travelExpenseDetail) => acc + travelExpenseDetail.value, 0);

    return totalPaid - totalValidSpent;
  }

  public get balance() {
    const travelExpensesBalance = this.getTravelExpensesBalance();
    const balanceState = !!this.utils.getNestedValue(this.cargo, 'shippingCost.balanceState');
    const balance = balanceState ?
      this.cargoItemService.getCargoBalancePaid(this.cargo) :
      this.cargoItemService.getTotalValueBalance(this.cargo) - travelExpensesBalance;
    return balance;
  }

}
