import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Endpoints } from "src/app/core/resources/endpoints";
import { BodyWorkType } from "src/app/core/interfaces/bodyWorkType";
import { CargoType } from "src/app/core/interfaces/cargoType";
import { MethodPayment } from "src/app/core/interfaces/methodPayment";
import { ContainerMeasure } from "src/app/core/interfaces/containerMeasure";
import { RegimeType } from "src/app/core/interfaces/regimeType";
import { ServiceType } from "src/app/core/interfaces/serviceType";
import { TripType } from "src/app/core/interfaces/tripType";
import { VehicleType } from "src/app/core/interfaces/vehicleType";
import { AuthService } from "src/app/core/services/authentication.service";
import { environment } from "../../../../environments/environment";
import { Cargo as CargoInterface, CargoRequest } from "src/app/core/interfaces/cargo";
import { AddressCargo } from "src/app/core/interfaces/addressCargo";
import { Destination } from "src/app/core/interfaces/destination";
import { CargoMeasure } from "src/app/core/interfaces/cargoMeasure";
import { CargoResources } from "./resources/cargo";
import { Company } from "src/app/core/interfaces/company";
import { ComponentActive } from "src/app/core/models/component-active.model";
import { Cargo } from "src/app/core/models/cargo.model";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Utils } from "src/app/core/resources/utils";
import { DateFormatPipe } from "src/app/core/pipe/dateFormat.pipe";
import { Origin } from "src/app/core/interfaces/origin";
import { ThirdParty } from "src/app/core/interfaces/thirdParty";
import { PackagingType } from "src/app/core/interfaces/packagingType";
import { ProductType } from "src/app/core/interfaces/productType";
import { AdditionalService } from "src/app/core/interfaces/additionalService";
import { ShippingCost } from "src/app/core/interfaces/shippingCost";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { ReactiveForm } from "src/app/core/resources/reactive-form";
import { GoogleService } from "src/app/core/services/google.service";
import { CargoDetailService } from "../cargo-detail/cargo-detail.service";
import { forkJoin, Observable, of } from "rxjs";
import { map, timeout } from "rxjs/operators";
import { Tag } from 'src/app/core/interfaces/tag';
import { VehicleCargo } from "src/app/core/interfaces/vehicle-cargo";
import { Patterns } from "src/app/core/resources/patterns";
import { ItemCargoFromGuidesComponent } from "../../guides/create-cargo-from-guides/item-cargo-from-guides/item-cargo-from-guides.component";
import { ItemAdditionalCost } from "src/app/core/interfaces/itemadditionalCost";
import { AmountsCargoEnum } from "src/app/core/enums/amountsCargo.enum";
import { CatalogItem } from "src/app/core/interfaces/catalogItem";
import { Catalog } from "src/app/core/interfaces/catalog";
import { AdditionalCost } from "src/app/core/interfaces/additionalCost";
import __additionalCost from "__mock/additionalCost.json";
import { CargoValidators } from "src/app/core/validators/cargo.validators";
import { BranchOffice } from "src/app/core/interfaces/branch-offices";
import { CargoModel } from "src/app/core/interfaces/cargoModel";
import { Permission } from "src/app/core/resources/permission";
import { PermissionRole } from "src/app/core/resources/permission-role";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { Router } from "@angular/router";
import { DateManager } from "src/app/core/managers/date.manager";
import { RiskProfile } from "src/app/core/interfaces/riskProfile";
import { CargoStateEnum } from "src/app/core/enums/cargoState.enum";
import { TravelExpensesService } from "src/app/core/services/travel-expenses.service";
import { NgxSpinnerService } from "ngx-spinner";
import { TravelExpense } from "src/app/core/interfaces/travel-expense";
@Injectable({
  providedIn: 'root',
})
export class ManualCreationCargoService extends ReactiveForm {
  public static componentActive = new ComponentActive();
  public cargoForm: FormGroup;
  public travelExpensesForm: FormGroup;
  public cargo = new Cargo();
  public cargoMock: CargoInterface = this.utils.clone(
    this.cargoResources.cargoMock
  );
  public steps = this.cargoResources.steps;
  public confirmMinistry: boolean;
  permission = Permission;
  apiUrl: string;
  cargoList = [];
  estimatedTimesOrigin = {};
  estimatedTimes = {};
  vehiclesSelected: VehicleCargo[] = [];
  vehiclesSelectedWithLicensePlate: VehicleCargo[] = [];
  reteicaSelected = 0;
  // cargo: Cargo;
  modelAddress: AddressCargo;
  modelDestination: Destination;
  modelLoadMeasurement: CargoMeasure;
  listVehicles = [];
  data: any;
  public static listAdditionalCost: AdditionalCost[] = [];
  public catalogVehicles: Catalog = {
    catalog: [],
  };
  static utilityOwner: number;
  static utilityAdditionalServiceOwner: number;
  static advancePercentageOwner: number;
  static ownerSelected: Company;
  static branchOfficeSelected: BranchOffice;
  static subBranchOfficeSelected: BranchOffice;
  cityDownloadControls = new FormArray([
    new FormControl('', Validators.required)
  ]);
  cityDownloadValidates = new FormArray([
    new FormControl('')
  ]);
  cityDownloadOptions = new FormArray([
    new FormControl({
      title: 'Ciudad de Destino',
      isInternational: false
    })
  ]);
  bodyworkTypeControls = new FormArray([]);
  bodyworkTypeValidates = new FormArray([]);
  bodyworkTypeOptions = new FormArray([]);
  fileLetterRetirement = {
    file: null,
    name: '',
    uploaded: false,
    size: null,
    url: null
  }
  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
    private authService: AuthService,
    public cargoResources: CargoResources,
    public utils: Utils,
    private snackBarService: SnackBarService,
    public formBuilder: FormBuilder,
    private googleService: GoogleService,
    public patterns: Patterns,
    private permissionRole: PermissionRole,
    private cargoValidators: CargoValidators,
    private spinner: NgxSpinnerService,
    private router: Router,
    public dialog: MatDialog,
    private travelExpensesService: TravelExpensesService
  ) {
    super(
      formBuilder,
      utils.clone(cargoResources.cargoMock),
      utils.clone(cargoResources.cargoMock)
    );
    this.initTravelExpensesForm();
    this.setFormCargo(this.form);
    this.initValidatorsForm();
    this.listVehicles = this.cargoResources.listVehicles;
    this.initComponentActive();
  }

  public initTravelExpensesForm() {
    this.travelExpensesForm = new FormGroup({
      travelExpenses: new FormArray([])
    });
  }

  public getCargoForm() {
    return this.cargoForm;
  }

  public initValidatorsForm() {
    this.formOriginAddresses.controls.forEach((formGroup) => {
      formGroup
        .get("address")
        .setValidators(
          this.cargoValidators.validateAddressEqualCity.bind(this)
        );
    });
  }

  public resetCargoForm() {
    this.initTravelExpensesForm();
    this.cityDownloadControls = new FormArray([
      new FormControl('', Validators.required)
    ]);
    this.cityDownloadValidates = new FormArray([
      new FormControl('')
    ]);
    this.cityDownloadOptions = new FormArray([
      new FormControl({
        title: 'Ciudad de Destino',
        isInternational: false
      })
    ]);
    this.fileLetterRetirement = {
      file: null,
      name: '',
      uploaded: false,
      size: null,
      url: null
    }
    this.bodyworkTypeControls = new FormArray([]);
    this.bodyworkTypeValidates = new FormArray([]);
    this.bodyworkTypeOptions = new FormArray([]);
    this.cargoForm = new FormGroup({});
    this.estimatedTimes = {};
    this.estimatedTimesOrigin = {};
    this.vehiclesSelected = [];
    this.vehiclesSelectedWithLicensePlate = [];
    this.setBranchOfficeSelected(null);
    this.setSubBranchOfficeSelected(null);
    this.refreshCargoForm();
  }

  public refreshCargoForm(lastCargoValue?) {
    this.setForm(this.cargoMock);
    this.setFormCargo(this.form);
    this.initValidatorsForm();
    if (lastCargoValue) {
      this.cargoForm.patchValue(lastCargoValue);
    }
  }

  public setFormCargo(form) {
    this.cargoForm = form;
  }

  public initComponentActive() {
    ManualCreationCargoService.componentActive.deserialize({
      name: "",
      id: 1,
      step: {
        name: "",
        id: 1,
      },
      itineraryComplete: false,
    });
  }

  public getComponentActive() {
    return ManualCreationCargoService.componentActive;
  }

  public initSingletonCargo() {
    this.cargoForm = null;
    this.initComponentActive();
  }

  public createRequestCargo(cargoList: CargoRequest[]) {
    return this.http.post(
      environment.urlServerTeclogi + this.endpointResources.urlCreateRequest,
      cargoList
    ).pipe(
      timeout(420000)
    );
  }

  public createRequest() {
    this.cargoList = [];
    this.getVehiclesWithLicensePlate().forEach((vehicleObject) => {
      this.addresses.forEach(address => {
        if (address && address.get('timePact') && !address.get('timePact').value) address.get('timePact').setValue(1);
      })
      for (let control of this.formDestinations.controls) {
        let arrayAddresses = control.get('addresses') as FormArray;
        for (let addressControl of arrayAddresses.controls) {
          if (addressControl && addressControl.get('timePact') && !addressControl.get('timePact').value) addressControl.get('timePact').setValue(1);
        }
      }
      if (vehicleObject.quantity >= 0) {
        const cargo: CargoInterface = this.utils.clone(this.cargoForm.value);
        cargo.state = CargoStateEnum.REQUEST;
        if (this.utils.isDefined(vehicleObject.licensePlate) && !this.utils.isEmpty(vehicleObject.licensePlate)) {
          cargo.licensePlate = vehicleObject.licensePlate.toUpperCase();
        }
        cargo.cargoFeature.vehicleType.name = vehicleObject.name;
        cargo.cargoFeature.vehicleType.quantity = 1;
        cargo.cargoFeature.vehicleType.bodyWorkType.name = vehicleObject.bodyWorkType.name;
        cargo.cargoFeature.vehicleType.quality = vehicleObject.quality;
        cargo.numberDocumentCreatorLoad = this.authService.getUserSession().information.document.toString();
        this.cargoResources.setCargoNature(cargo);
        this.cargoResources.setTypeOperation(cargo);

        if (this.getOwnerSelected().branchOffices) {
          cargo.branchOffice = this.getBranchOfficeSelected().id;
          if (this.getSubBranchOfficeSelected()) {
            cargo.subBranchOffice = this.getSubBranchOfficeSelected().id;
          }
        }

        cargo.cargoFeature.uploadDownload.origin.addresses.forEach((address, i) => {
          address.id = i.toString();
          if (address.time && address.timeType) {
            address.time = address.time + ' ' + address.timeType;
            address.time = DateFormatPipe.prototype.transform('01-01-1990 ' + address.time, 'time-24');
          }
          if (!cargo.ministry || cargo.cargoFeature.uploadDownload.origin.isInternational)
            delete address.thirdPartyConsignment;
          else
            delete address.thirdPartyConsignment.documentTypeName;
          delete address.timeType;
          delete address.quantity;
        });

        cargo.cargoFeature.uploadDownload.destination.forEach((destination, i) => {
          destination.id = i;
          destination.addresses.forEach((address, j) => {
            if (address.time && address.timeType) {
              address.time = address.time + ' ' + address.timeType;
              address.time = DateFormatPipe.prototype.transform('01-01-1990 ' + address.time, 'time-24');
            }
            if (!cargo.ministry || destination.isInternational) {
              address.id = j.toString();
              delete address.thirdPartyConsignment;
            } else {
              address.id = j.toString();
              delete address.thirdPartyConsignment.documentTypeName;
            }
            delete address.timeType;
            delete address.quantity;
            if (address.cargoMeasure.unit === 'Toneladas') {
              address.cargoMeasure.totalWeigth = (parseInt(address.cargoMeasure.totalWeigth) * 1000).toString();
              address.cargoMeasure.unit = '1';
            }
            if (this.utils.isDefined(address.guidesId) && address.guidesId.length) {
              address.guidesId = address.guidesId.filter((guideId) => {
                if (!this.utils.isEmpty(guideId.value)) {
                  return guideId.value;
                }
              }).map((guideId) => {
                return guideId.value;
              });
            }
          });
          delete destination.reteica;
        });
        if (cargo.additionalCosts.length) {
          cargo.additionalCosts.forEach((additionalCost) => {
            delete additionalCost.type.disabled;
            delete additionalCost.type.whoToPaysUtilities;
            delete additionalCost.type.utility;
          });
        } else {
          cargo.additionalCosts = null;
        }
        if (cargo.cargoFeature.uploadDownload.origin.isInternational &&
          cargo.cargoFeature.uploadDownload.destination.every(dest => dest.isInternational)) {
          cargo.ministry = false;
        }
        if (!cargo.ministry) {
          delete cargo.cargoOwner;
        } else {
          delete cargo.cargoOwner.address;
          delete cargo.cargoOwner.location;
        }
        if (this.authService.getCompany().validateFreight) {
          cargo.shippingCost.freightCost = null;
          cargo.shippingCost.totalCost = null;
          cargo.shippingCost.advancePercentage = null;
          cargo.shippingCost.valueAdvance = null;
          cargo.shippingCost.advancePaymentMethod = null;
          cargo.shippingCost.balancePaymentMethod = null;
          cargo.shippingCost.paymentTime = null;
        }
        delete cargo.cargoFeature.uploadDownload.origin.isInternational;
        cargo.cargoFeature.uploadDownload.destination.forEach(destination => {
          delete destination.isInternational;
        })
        delete cargo.id;
        delete cargo.quantity;
        delete cargo.businessName;
        delete cargo.driver;
        delete cargo.cargoFeature.productType.cargoNature;
        delete cargo.cargoFeature.productType.cargoNatureId;
        delete cargo.cargoFeature.uploadDownload.origin.reteica;
        delete cargo.cargoFeature.cargoMeasure;
        this.cargoList.push(cargo);
      }
    });
    return this.http.post(
      environment.urlServerTeclogi + this.endpointResources.urlCreateRequest,
      this.cargoList
    ).pipe(
      timeout(420000)
    );
  }

  public processCargo(cargo?: CargoInterface, cargoId?: string) {
    return new Promise((resolve, reject) => {
      this.getRouteGoogleData(cargo ? cargo : undefined)
        .then((cargoRoute) => {
          const cargoRequest = cargo ? this.createRenewCargo(cargo) : this.createCargoByVehicles();
          cargoRequest.subscribe(
            (success: CargoInterface[]) => {
              this.cargoList = success;
              this.validateResponseCreateCargo(success, cargoRoute)
                .then(() => {
                  if (cargo) this.createTravelExpensesRenewedCargo(success[0], cargoId);
                  resolve(success);
                })
                .catch((error) => {
                  reject(error);
                });
            },
            (error) => {
              reject(error);
            }
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public async validateResponseCreateCargo(data, cargoRoute) {
    return new Promise((resolve, reject) => {
      const errorRNDC: string[] = [];
      if (data && data.length) {
        data.map((cargo: CargoInterface) => {
          if (cargo.manifestError && cargo.manifestError.error) {
            errorRNDC.push(cargo.manifestError.error);
          }
          cargo.cargoFeature.uploadDownload.origin.addresses.map(
            (address: AddressCargo) => {
              if (address.ministryError) {
                errorRNDC.push(address.ministryError.error);
              }
            }
          );
          cargo.cargoFeature.uploadDownload.destination.map(
            (destination: Destination) => {
              destination.addresses.map((address: AddressCargo) => {
                if (address.ministryError) {
                  errorRNDC.push(address.ministryError.error);
                }
              });
            }
          );
        });
        if (errorRNDC.length > 0) {
          reject(errorRNDC);
        } else {
          if (cargoRoute !== false) {
            this.saveRouteGoogleCargo(data, cargoRoute).subscribe(
              (success) => {
                resolve(success);
              },
              (error) => {
                reject(error);
              }
            );
          } else {
            resolve(data);
          }
        }
      } else {
        reject();
      }
    });
  }

  private async createTravelExpensesRenewedCargo(cargoRenewed: CargoInterface, oldCargoId: string) {
    const travelExpenses = await this.travelExpensesService.byCargoId(oldCargoId).toPromise();
    if (!travelExpenses || !travelExpenses.length) return;
    this.applyTravelExpenses([cargoRenewed], travelExpenses);
  }

  getConsecutive(cargos: CargoInterface[]): number[] {
    const consecutives: number[] = [];
    for (const cargo of cargos) {
      consecutives.push(cargo.consecutive);
    }
    return consecutives;
  }

  private saveRouteGoogleCargo(cargos: CargoInterface[], cargoRoute) {
    const listRoute = [];
    const listPromises = [];
    const url = `${environment.urlServerTeclogi}${this.endpointResources.saveRouteGoogleCargo}`;
    cargoRoute.forEach((route: any, i) => {
      const overviewPolyline =
        route.routes[0].overview_polyline.points ||
        route.routes[0].overview_polyline;
      listRoute.push(overviewPolyline);
    });
    cargos.map((cargo: CargoInterface) => {
      listPromises.push(
        this.http.post(url, {
          cargoId: cargo.id,
          overview_polylines: listRoute,
        })
      );
    });

    return forkJoin(listPromises);
  }

  private getRouteGoogleData(cargo?: CargoInterface) {
    return new Promise((resolve, reject) => {
      this.googleService
        .getRouteDataCargo(cargo || this.cargoForm.value)
        .then((success: { cargoDistancy; cargoEstimatedTime; cargoRoute }) => {
          this.cargoForm.controls.distancy.setValue(success.cargoDistancy);
          this.cargoForm.controls.estimatedTime.setValue(
            success.cargoEstimatedTime
          );
          this.cargoForm.controls.shippingCost[
            "controls"
          ].reteicaPercentage.setValue(
            this.cargoForm.value.cargoFeature.uploadDownload.origin.reteica
          );
          this.cargoForm.controls.shippingCost[
            "controls"
          ].reteftePercentage.setValue(1);
          resolve(success.cargoRoute);
        })
        .catch((error) => {
          resolve(error);
        });
    });
  }

  public getVehiclesWithLicensePlate(): VehicleCargo[] {
    let listLicensePlate: VehicleCargo[] = [];
    this.vehiclesSelected.forEach((vehicleObject: VehicleCargo) => {
      if (this.utils.isDefined(vehicleObject.quantity)) {
        for (let i = 0; i < vehicleObject.quantity; i++) {
          listLicensePlate.push({
            ...vehicleObject,
            licensePlate: "",
          });
        }
      }
    });
    if (this.vehiclesSelectedWithLicensePlate.length) {
      listLicensePlate = listLicensePlate.map(
        (vehicleObject: VehicleCargo, i: number) => {
          if (
            this.vehiclesSelectedWithLicensePlate[i] &&
            !this.utils.isEmpty(
              this.vehiclesSelectedWithLicensePlate[i].licensePlate
            )
          ) {
            vehicleObject.licensePlate =
              this.vehiclesSelectedWithLicensePlate[i].licensePlate;
          }

          if (this.vehiclesSelectedWithLicensePlate[i] && !this.utils.isEmpty(
            this.vehiclesSelectedWithLicensePlate[i].container
          )) {
            vehicleObject.container =
              this.vehiclesSelectedWithLicensePlate[i].container;
          }
          if (this.vehiclesSelectedWithLicensePlate[i] && !this.utils.isEmpty(
            this.vehiclesSelectedWithLicensePlate[i].containerExpirationDate
          )) {
            vehicleObject.containerExpirationDate =
              this.vehiclesSelectedWithLicensePlate[i].containerExpirationDate;
          }
          if (this.vehiclesSelectedWithLicensePlate[i] && !this.utils.isEmpty(
            this.vehiclesSelectedWithLicensePlate[i].seal
          )) {
            vehicleObject.seal =
              this.vehiclesSelectedWithLicensePlate[i].seal;
          }
          return vehicleObject;
        }
      );
    }
    return listLicensePlate;
  }

  public setVehiclesWithLicensePlate(listLicensePlate): void {
    this.vehiclesSelectedWithLicensePlate = this.utils.clone(listLicensePlate);
  }

  public createCargoByVehicles() {
    this.cargoList = [];
    const thisService = this;
    const companyUser: Company = this.authService.getCompany();
    this.getVehiclesWithLicensePlate().forEach((vehicleObject) => {
      if (vehicleObject.quantity >= 0) {
        const cargo: CargoInterface = this.utils.clone(this.cargoForm.value);
        if (companyUser && companyUser.subCompanies || this.authService.getCompany().validateFreight) {
          cargo.state = CargoStateEnum.REQUEST;
        } else {
          delete cargo.state;
        }
        if (this.utils.isDefined(vehicleObject.licensePlate) && !this.utils.isEmpty(vehicleObject.licensePlate)) {
          cargo.licensePlate = vehicleObject.licensePlate.toUpperCase();
          cargo.state = CargoStateEnum.ACCEPTED;
        }
        if (vehicleObject.containerExpirationDate && vehicleObject.container) {
          cargo.container = vehicleObject.container;
          cargo.containerExpirationDate = DateManager.dateToString(new Date(vehicleObject.containerExpirationDate), 'DD/MM/YYYY');
        }
        if (this.utils.isDefined(vehicleObject.seal && !this.utils.isEmpty(vehicleObject.seal))) cargo.seal = vehicleObject.seal;
        cargo.cargoFeature.vehicleType.name = vehicleObject.name;
        cargo.cargoFeature.vehicleType.name = vehicleObject.name;
        cargo.cargoFeature.vehicleType.quantity = 1;
        cargo.cargoFeature.vehicleType.bodyWorkType.name = vehicleObject.bodyWorkType.name;
        cargo.cargoFeature.vehicleType.quality = vehicleObject.quality;
        cargo.numberDocumentCreatorLoad = thisService.authService.getUserSession().information.document.toString();
        this.cargoResources.setCargoNature(cargo);
        this.cargoResources.setTypeOperation(cargo);

        if (this.getOwnerSelected().branchOffices) {
          cargo.branchOffice = this.getBranchOfficeSelected().id;
          if (this.getSubBranchOfficeSelected()) {
            cargo.subBranchOffice = this.getSubBranchOfficeSelected().id;
          }
        }

        cargo.cargoFeature.uploadDownload.origin.addresses.forEach((address, i) => {
          address.time = address.time + ' ' + address.timeType;
          address.time = DateFormatPipe.prototype.transform('01-01-1990 ' + address.time, 'time-24');
          if (!cargo.ministry || cargo.cargoFeature.uploadDownload.origin.isInternational) {
            address.id = i.toString();
            delete address.thirdPartyConsignment;
          } else {
            delete address.thirdPartyConsignment.documentTypeName;
          }
          delete address.timeType;
          delete address.quantity;
        });

        cargo.cargoFeature.uploadDownload.destination.forEach((destination, i) => {
          destination.id = i;
          destination.addresses.forEach((address, j) => {
            address.time = address.time + ' ' + address.timeType;
            address.time = DateFormatPipe.prototype.transform('01-01-1990 ' + address.time, 'time-24');
            if (!cargo.ministry || destination.isInternational) {
              address.id = j.toString();
              delete address.thirdPartyConsignment;
            } else {
              address.id = j.toString();
              delete address.thirdPartyConsignment.documentTypeName;
            }
            delete address.timeType;
            delete address.quantity;
            if (address.cargoMeasure.unit === 'Toneladas') {
              address.cargoMeasure.totalWeigth = (parseInt(address.cargoMeasure.totalWeigth) * 1000).toString();
              address.cargoMeasure.unit = '1';
            }
            if (this.utils.isDefined(address.guidesId) && address.guidesId.length) {
              address.guidesId = address.guidesId.filter((guideId) => {
                if (!this.utils.isEmpty(guideId.value)) {
                  return guideId.value;
                }
              }).map((guideId) => {
                return guideId.value;
              });
            }
          });
          delete destination.reteica;
        });
        if (cargo.additionalCosts.length) {
          cargo.additionalCosts.forEach((additionalCost) => {
            delete additionalCost.type.disabled;
            delete additionalCost.type.whoToPaysUtilities;
            delete additionalCost.type.utility;
          });
        } else {
          cargo.additionalCosts = null;
        }
        if (cargo.cargoFeature.uploadDownload.origin.isInternational &&
          cargo.cargoFeature.uploadDownload.destination.every(dest => dest.isInternational)) {
          cargo.ministry = false;
        }
        if (!cargo.ministry) {
          delete cargo.cargoOwner;
        } else {
          delete cargo.cargoOwner.address;
          delete cargo.cargoOwner.location;
        }
        if (this.authService.getCompany().validateFreight) {
          cargo.shippingCost.freightCost = null;
          cargo.shippingCost.totalCost = null;
          cargo.shippingCost.advancePercentage = null;
          cargo.shippingCost.valueAdvance = null;
          cargo.shippingCost.advancePaymentMethod = null;
          cargo.shippingCost.balancePaymentMethod = null;
          cargo.shippingCost.paymentTime = null;
        }
        delete cargo.cargoFeature.uploadDownload.origin.isInternational;
        cargo.cargoFeature.uploadDownload.destination.forEach(destination => {
          delete destination.isInternational;
        })
        delete cargo.id;
        delete cargo.quantity;
        delete cargo.businessName;
        delete cargo.driver;
        delete cargo.cargoFeature.productType.cargoNature;
        delete cargo.cargoFeature.productType.cargoNatureId;
        delete cargo.cargoFeature.uploadDownload.origin.reteica;
        delete cargo.cargoFeature.cargoMeasure;
        thisService.cargoList.push(cargo);
      }
    });
    return this.createRenewCargo(this.cargoList);
  }


  // Validation fields form

  public isValidCargoOwner(): boolean {
    const cargoOwner: ThirdParty = this.cargoForm.get('cargoOwner').value as ThirdParty;
    return (
      !_.isEmpty(cargoOwner.documentType) &&
      !_.isEmpty(cargoOwner.documentNumber) &&
      !_.isEmpty(cargoOwner.branchOffice) &&
      !_.isEmpty(cargoOwner.name)
    );
  }

  public isValidUploadLocation(ministry: boolean) {
    const origin: Origin = this.cargoForm.get('cargoFeature.uploadDownload.origin').value as Origin;
    const isInternational = !!origin.isInternational;
    return isInternational ? (
      origin.name && origin.country && origin.country.code && origin.country.name &&
      this.isValidUploadAddressesLocation(ministry) &&
      ((ministry && this.isValidCargoOwner()) || !ministry)
    ) : (
      !_.isEmpty(origin.name) &&
      !_.isEmpty(origin.municipalityCode) &&
      origin.reteica > 0 &&
      this.isValidUploadAddressesLocation(ministry) &&
      ((ministry && this.isValidCargoOwner()) || !ministry)
    );
  }

  public isValidUploadAddressesLocation(ministry: boolean): boolean {
    const isInternational = !!this.cargoForm.get('cargoFeature.uploadDownload.origin').value.isInternational;
    const addresses: AddressCargo[] = this.cargoForm.get('cargoFeature.uploadDownload.origin.addresses').value as AddressCargo[];
    let isValid = true;
    addresses.map((address: AddressCargo) => {
      if (ministry && isInternational) {
        if (
          _.isEmpty(address.address) ||
          !(_.isNumber(address.location.lat) && address.location.lat !== 0) ||
          !(_.isNumber(address.location.lng) && address.location.lng !== 0)
        ) {
          isValid = false;
        }
      } else if (ministry) {
        if (
          (_.isEmpty(address.id)) ||
          _.isEmpty(address.address) ||
          !(_.isNumber(address.location.lat) && address.location.lat !== 0) ||
          !(_.isNumber(address.location.lng) && address.location.lng !== 0) ||
          _.isEmpty(address.thirdPartyConsignment.documentType) ||
          _.isEmpty(address.thirdPartyConsignment.documentNumber) ||
          _.isEmpty(address.thirdPartyConsignment.branchOffice) ||
          _.isEmpty(address.thirdPartyConsignment.name)
        ) {
          isValid = false;
        }
      } else {
        if (
          _.isEmpty(address.address) ||
          !(_.isNumber(address.location.lat) && address.location.lat !== 0) ||
          !(_.isNumber(address.location.lng) && address.location.lng !== 0)
        ) {
          isValid = false;
        }
      }
      if (!isValid) {
        return;
      }
    });
    return isValid;
  }

  public isValidDownloadLocation(ministry: boolean) {
    const destinations: Destination[] = this.cargoForm.get('cargoFeature.uploadDownload.destination').value as Destination[];
    let isValid = true;
    destinations.map((destination: Destination, i: number) => {
      let isInternational = !!destination.isInternational;
      if (isInternational) {
        if (_.isEmpty(destination.name) || _.isEmpty(destination.country) || _.isEmpty(destination.country.code) ||
          _.isEmpty(destination.country.name) || !this.isValidDownloadAddressesLocation(i, ministry)) {
          isValid = false;
        }
      } else if (
        _.isEmpty(destination.name) ||
        _.isEmpty(destination.municipalityCode) ||
        !this.isValidDownloadAddressesLocation(i, ministry)
      ) {
        isValid = false;
      }
      if (!isValid) {
        return;
      }
    });
    return isValid;
  }

  public isValidDownloadAddressesLocation(i: number, ministry?: boolean): boolean {
    const destination: Destination = this.cargoForm.get('cargoFeature.uploadDownload.destination.' + i).value as Destination;
    let isValid = true;
    destination.addresses.map((address: AddressCargo) => {
      if (ministry && destination.isInternational) {
        if (
          _.isEmpty(address.address) ||
          !(_.isNumber(address.location.lat) && address.location.lat !== 0) ||
          !(_.isNumber(address.location.lng) && address.location.lng !== 0)
        ) {
          isValid = false;
        }
      } else if (ministry) {
        if (
          _.isEmpty(address.id) ||
          _.isEmpty(address.address) ||
          !(_.isNumber(address.location.lat) && address.location.lat !== 0) ||
          !(_.isNumber(address.location.lng) && address.location.lng !== 0) ||
          _.isEmpty(address.thirdPartyConsignment.documentType) ||
          _.isEmpty(address.thirdPartyConsignment.documentNumber) ||
          _.isEmpty(address.thirdPartyConsignment.branchOffice) ||
          _.isEmpty(address.thirdPartyConsignment.name)
        ) {
          isValid = false;
        }
      } else {
        if (
          _.isEmpty(address.address) ||
          !(_.isNumber(address.location.lat) && address.location.lat !== 0) ||
          !(_.isNumber(address.location.lng) && address.location.lng !== 0)
        ) {
          isValid = false;
        }
      }
      if (!isValid) {
        return;
      }
    });
    return isValid;
  }

  public isValidCompany(): boolean {
    const idCompany: string = this.cargoForm.get('idCompany').value as string;
    const exclusive: boolean = this.cargoForm.get('exclusive').value as boolean;
    const hasBranchOffices: boolean = this.utils.isDefined(this.getOwnerSelected()) && this.utils.isDefined(this.getOwnerSelected().branchOffices) && this.utils.isDefined(this.getOwnerSelected().branchOffices.length);
    const branchOffice: boolean = hasBranchOffices ? this.utils.isDefined(this.getBranchOfficeSelected()) && this.utils.isDefined(this.getBranchOfficeSelected().id) : true;
    const subBranchOffice: boolean = hasBranchOffices && this.utils.isDefined(this.getBranchOfficeSelected()) && this.utils.isDefined(this.getBranchOfficeSelected().id)
      ? (this.utils.isDefined(this.getBranchOfficeSelected()) && this.utils.isDefined(this.getBranchOfficeSelected().subBranchOffices) && this.utils.isDefined(this.getSubBranchOfficeSelected()) && this.utils.isDefined(this.getSubBranchOfficeSelected().id)) || !this.utils.isDefined(this.getBranchOfficeSelected().subBranchOffices) === true : true;
    return (
      !_.isEmpty(idCompany) &&
      _.isBoolean(exclusive) &&
      branchOffice && subBranchOffice
    );
  }

  public isValidNumberDocumentSender(): boolean {
    const numberDocumentSender: string = this.cargoForm.get('numberDocumentSender').value as string;
    return (
      !_.isEmpty(numberDocumentSender)
    );
  }

  public isValidDateLoad(): boolean {
    const dateLoad: string = this.cargoForm.get('dateLoad').value as string;
    return DateManager.isValidDate(dateLoad, 'YYYY-MM-DD HH:mm ZZ');
  }

  public thereDatesDownload() {
    const destinations: Destination[] = this.cargoForm.get('cargoFeature.uploadDownload.destination').value as Destination[];
    destinations.map((destination: Destination, i: number) => {
      if (destination.downloadDate) {
        destination.downloadDate = ""
      }
    });
    return destinations
  }

  public isValidDatesDownload(): boolean {
    const destinations: Destination[] = this.cargoForm.get('cargoFeature.uploadDownload.destination').value as Destination[];
    let isValid = true;
    destinations.map((destination: Destination, i: number) => {
      isValid = DateManager.isValidDate(destination.downloadDate, 'YYYY-MM-DD HH:mm ZZ');
      if (!isValid) {
        return;
      }
    });
    return isValid;
  }

  public isDatesDownloadGreaterDateLoad(): boolean {
    const dateLoad: string = this.cargoForm.get('dateLoad').value as string;
    const destinations: Destination[] = this.cargoForm.get('cargoFeature.uploadDownload.destination').value as Destination[];
    let isValid = true;
    destinations.map((destination: Destination) => {
      isValid = DateManager.isSameOrBefore(
        DateManager.stringToDate(dateLoad, 'YYYY-MM-DD HH:mm'),
        DateManager.stringToDate(destination.downloadDate, 'YYYY-MM-DD HH:mm ZZ'),
        'minutes'
      )
      if (!isValid) {
        return;
      }
    });
    return isValid;
  }

  public isValidTripType(validDocuments: boolean = false): boolean {
    const tripType: TripType = this.cargoForm.get('cargoModel.tripType').value as TripType;
    const riskProfile: RiskProfile = this.cargoForm.get('riskProfile').value as RiskProfile;
    return (
      !_.isEmpty(tripType.name) && (this.isValidTripTypeDocuments() || validDocuments) && ((tripType.name !== 'Internacional' && !_.isEmpty(riskProfile.id)) || tripType.name === 'Internacional')
    );
  }

  public isValidTripTypeDocuments(): boolean {
    const tripType: TripType = this.cargoForm.get('cargoModel.tripType').value as TripType;
    return ((tripType.name === 'Exportación' && !_.isEmpty(tripType.letterRetirement)) || tripType.name !== 'Exportación')
      && ((tripType.name !== 'Internacional' && !_.isEmpty(this.cargoForm.get('riskProfile.name').value)) || tripType.name === 'Internacional');
  }

  public isValidRegimeType(): boolean {
    const tripType: TripType = this.cargoForm.get('cargoModel.tripType').value as TripType;
    let isValid = false;
    if (
      !_.isEmpty(tripType.name)
    ) {
      if (
        ((tripType.name === 'Exportación' || tripType.name === 'Importación') && !_.isEmpty(tripType.regimeType)) ||
        ((tripType.name === 'Nacional' || tripType.name === 'Internacional' || tripType.name === 'Urbana' || tripType.name === 'Última milla') && _.isEmpty(tripType.regimeType))
      ) {
        isValid = true;
      }
    }

    if (tripType.regimeType === "DUTA" || tripType.regimeType === 'OTM') {
      if (tripType.date === "") {
        isValid = false;
      }
    }
    return isValid;
  }

  public isValidCargoType(): boolean {
    const tripType: TripType = this.cargoForm.get('cargoModel.tripType').value as TripType;
    const cargoType: CargoType = this.cargoForm.get('cargoModel.cargoType').value as CargoType;
    let isValid = false;
    if (
      ((tripType.name === 'Exportación' || tripType.name === 'Importación' || tripType.name === 'Nacional' || tripType.name === 'Internacional' || tripType.name === 'Urbana') && !_.isEmpty(cargoType.name)) ||
      (tripType.name === 'Última milla' && _.isEmpty(cargoType.name))
    ) {
      isValid = true;
    }
    return isValid;
  }

  public isValidContainerMeasure(): boolean {
    const tripType: TripType = this.cargoForm.get('cargoModel.tripType').value as TripType;
    const cargoType: CargoType = this.cargoForm.get('cargoModel.cargoType').value as CargoType;
    // const packagingType: PackagingType = this.cargoForm.get('cargoModel.packagingType').value as PackagingType;
    let isValid = false;
    if (((tripType.name === 'Exportación' || tripType.name === 'Importación' || tripType.name === 'Nacional' || tripType.name === 'Urbana' || tripType.name === 'Internacional') && (
      (cargoType.name === 'Contenedor' && !_.isEmpty(cargoType.containerMeasure.size)) ||
      (cargoType.name === 'Carga suelta' && _.isEmpty(cargoType.containerMeasure.size))
    )) || (tripType.name === 'Última milla' && _.isEmpty(cargoType.containerMeasure.size))) {
      isValid = true;
    }
    return isValid;
  }

  public isValidServiceType(): boolean {
    const serviceType: ServiceType = this.cargoForm.get('cargoModel.serviceType').value as ServiceType;
    return (
      !_.isEmpty(serviceType.name)
    );
  }

  public isValidProductType(): boolean {
    const productType: ProductType = this.cargoForm.get('cargoFeature.productType').value as ProductType;
    const packagingType: PackagingType = this.cargoForm.get('cargoModel.packagingType').value as PackagingType;
    return (
      !_.isEmpty(productType.code) &&
      !_.isEmpty(productType.name) &&
      !_.isEmpty(productType.cargoNature) &&
      !_.isEmpty(productType.cargoNatureId) &&
      !_.isEmpty(packagingType.code)
    );
  }

  public isValidVehicleType(): boolean {
    return this.vehiclesSelected.length > 0;
  }

  public isValidVehicleQuantity(): boolean {
    let isValid = true;
    this.vehiclesSelected.map((vehicle: VehicleType) => {
      if (
        _.isEmpty(vehicle.name) ||
        _.isEmpty(vehicle.bodyWorkType.name) ||
        !_.isNumber(vehicle.quantity) ||
        !(vehicle.quantity > 0)
      ) {
        isValid = false;
      }
      if (!isValid) {
        return;
      }
    });
    return isValid && this.isValidVehicleType();
  }

  public isValidLicensePlate() {
    let status = true;
    this.vehiclesSelectedWithLicensePlate.forEach((vehicle: VehicleCargo) => {
      if (vehicle && !this.utils.isEmpty(vehicle.licensePlate) && !this.patterns.GET_REGEX('LICENSE_PLATES').test(vehicle.licensePlate)) {
        status = false;
        return;
      }
    });
    return status;
  }

  public isValidContainerData() {
    let patternContainer = new RegExp(this.patterns.CONTAINER_FORMAT_FULL.source);
    return this.vehiclesSelectedWithLicensePlate.every(vehicle =>
      (vehicle.container && vehicle.containerExpirationDate && patternContainer.test(vehicle.container))
      || (!vehicle.container && !vehicle.containerExpirationDate));
  }

  public getVehicleWeightById(idVehicle) {
    return this.http.get(
      environment.urlServerTeclogi + this.endpointResources.getVehicleWeightById + (idVehicle != '' ? idVehicle : this.vehiclesSelected[0]['id'])
    );
  }

  public isValidWeight(dataVehicle, destination?): boolean {
    let destinations: Destination[]
    destination ? destinations = destination : destinations = this.cargoForm.get('cargoFeature.uploadDownload.destination').value as Destination[];
    let isValid = true;
    let sum = 0;
    destinations.map((destination: Destination) => {
      destination.addresses.map((address: AddressCargo) => {
        if ((this.converter(address.cargoMeasure.unit, (address.cargoMeasure.totalWeigth * address.cargoMeasure.quantity))) > dataVehicle.weight) {
          isValid = false;
        }
        if (!isValid) {
          return;
        }
        sum += this.converter(address.cargoMeasure.unit, (address.cargoMeasure.totalWeigth * address.cargoMeasure.quantity));
      });
      if (!isValid) {
        return;
      }
    });
    return isValid && sum <= dataVehicle.weight;
  }

  public converter(unit, totalWeigth) {
    switch (unit) {
      case "Toneladas": {
        /*
         * Toneladas
         * Convertimos de toneladas a Kl
        */
        return totalWeigth * 1000;
      }
      case "2": {
        /*
         * Galones
         * Convertimos de Galones a Kl
         * En base al peso del galon del agua, tenemos que un galon de agua pesa 3.78 kg.
        */
        return totalWeigth * 3.78;
      }
      default: {
        /*
         * Cuando el valor es kilo, No se realiza ningun tipo de conversión
         * Ya que de la BD la unidad del peso de un vehiculo es kilo
        */
        return totalWeigth;
      }
    }
  }

  public isValidDownloadAddressesCargoMeasure(): boolean {
    const destinations: Destination[] = this.cargoForm.get('cargoFeature.uploadDownload.destination').value as Destination[];
    const isEmptyContainer = this.cargoForm.get('cargoFeature.productType.name').value === "CONTENEDOR VACIO";
    let isValid = true;
    destinations.map((destination: Destination) => {
      destination.addresses.map((address: AddressCargo) => {
        if (
          !_.isNumber(address.cargoMeasure.quantity) ||
          !(address.cargoMeasure.quantity > 0) ||
          !_.isNumber(address.cargoMeasure.totalMeasurement) ||
          (address.cargoMeasure.totalMeasurement <= 0 && !isEmptyContainer) ||
          (address.cargoMeasure.totalMeasurement != 0 && isEmptyContainer) ||
          !_.isNumber(address.cargoMeasure.amount) ||
          (address.cargoMeasure.amount <= 0 && !isEmptyContainer) ||
          (address.cargoMeasure.amount != 0 && isEmptyContainer) ||
          !_.isNumber(address.cargoMeasure.totalWeigth) ||
          (address.cargoMeasure.totalWeigth <= 0 && !isEmptyContainer) ||
          (address.cargoMeasure.totalWeigth != 0 && isEmptyContainer) ||
          _.isEmpty(address.cargoMeasure.unit) ||
          _.isEmpty(address.cargoMeasure.type)
        ) {
          isValid = false;
        }
        if ((address.cargoMeasure.totalWeigth <= 0 && !isEmptyContainer) ||
          (address.cargoMeasure.totalWeigth != 0 && isEmptyContainer) ||
          (address.cargoMeasure.totalWeigth > AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG && address.cargoMeasure.unit === '1') ||
          (address.cargoMeasure.totalWeigth > AmountsCargoEnum.MAX_WEIGHT_ALLOWED_GL && address.cargoMeasure.unit === '2')) {
          isValid = false;
        }
        if (!isValid) {
          return;
        }
      });
      if (!isValid) {
        return;
      }
    });
    return isValid;
  }

  /*public isValidDownloadAddressesTotalWeight(): boolean {
    const destinations: Destination[] = this.cargoForm.get('cargoFeature.uploadDownload.destination').value as Destination[];
    let totalWeigth = 0;
    destinations.map((destination: Destination) => {
      destination.addresses.map((address: AddressCargo) => {
        totalWeigth += this.converter(address.cargoMeasure.unit, address.cargoMeasure.totalWeigth * address.cargoMeasure.quantity);
      });
    });
    return totalWeigth <= AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG;
  }*/

  public isValidAllDateTimeLoad() {
    let isValid = true;
    this.formOriginAddresses.controls.forEach((addressCtrl, i) => {

      if ((!this.isValidAddressDateTimeLoad(i) && isValid) || !this.isValidEstimatedTimeUpload(i, addressCtrl.get('time').value)) {
        isValid = false;
        return;
      }
    });
    return isValid;
  }

  public getEstimatedTimeDownload(iDestination: number, iAddress: number, time: string) {
    if (!this.estimatedTimes[iDestination] || !this.estimatedTimes[iDestination][iAddress] || !time) return false;
    return this.estimatedTimes[iDestination][iAddress];
  }

  public getVisualEstimatedTimeDownload(iDestination: number, iAddress: number, time: string, previosAddTime?: string) {
    let estimatedTime = this.getEstimatedTimeDownload(iDestination, iAddress, time);
    if (!estimatedTime || !previosAddTime) return false;
    return estimatedTime;
  }

  public getEstimatedTimeUpload(iAddress: number, time: string) {
    if (!this.estimatedTimesOrigin[iAddress] || !time) return false;
    return this.estimatedTimesOrigin[iAddress];
  }

  public getVisualEstimatedTimeUpload(iAddress: number, time: string, previosAddTime?: string) {
    let estimatedTime = this.getEstimatedTimeUpload(iAddress, time);
    if (!estimatedTime || !previosAddTime) return false;
    return estimatedTime;
  }

  public isValidAllDateTimeDowndload() {
    let isValid = true;
    this.formDestinations.controls.forEach((destination, iD) => {
      this.getFormDestinationAddresses(iD).controls.forEach((address, i) => {
        if (isValid && (!this.isValidAddressDateTimeLoad(i, iD) || !this.isValidAddressTimeDownloadToLast(i, iD) || !this.isValidFirstAddressTimeDownload(i, iD) || !this.isValidEstimatedTime(i, iD, address.get('time').value))) {
          isValid = false;
          return;
        }
      });
    });
    return isValid;
  }

  public isValidEstimatedTime(indexAddress, indexDestination, time: string): boolean {
    return !(this.getEstimatedTimeDownload(indexDestination, indexAddress, time) !== false);
  }

  public isValidEstimatedTimeUpload(indexAddress: number, time: string): boolean {
    return !(this.getEstimatedTimeUpload(indexAddress, time) !== false);
  }

  public isValidAddressDateTimeLoad(index: number, iDestination?: number) {
    if (index === 0) return true;
    else if (this.utils.isDefined(iDestination)) {
      const address: Date = this.getDateByAddress(index, iDestination);
      if (address) {
        const lastAddress: Date = this.getDateByAddress(index - 1, iDestination);
        return address > lastAddress;
      }
    } else if (this.formOriginAddresses.controls.length > 1) {
      const address: Date = this.getDateByAddress(index);
      if (address) {
        const lastAddress: Date = this.getDateByAddress(index - 1);
        return address > lastAddress;
      }
    }
    return true;
  }

  public isValidAddressTimeDownloadToLast(index: number, iDestination: number) {
    if (iDestination === 0) return true;
    let lastDestination = this.cargoForm.get('cargoFeature.uploadDownload.destination.' + (iDestination - 1)).value.addresses.length - 1;
    const address: Date = this.getDateByAddress(index, iDestination);
    const lastDestinationAddress: Date = this.getDateByAddress(lastDestination, iDestination - 1);
    return lastDestinationAddress && address && lastDestinationAddress < address;
  }

  public isValidFirstAddressTimeDownload(index: number, iDestination: number) {
    if (index === 0 && iDestination === 0) {
      let lastOrigin = this.cargoForm.get('cargoFeature.uploadDownload.origin.addresses').value.length - 1;
      const address: Date = this.getDateByAddress(index, iDestination);
      const lastOriginAddress: Date = this.getDateByAddress(lastOrigin);
      return lastOriginAddress && address && lastOriginAddress < address;
    }
    return true;
  }

  public getDateByAddress(index: number, iDestination?: number): Date {
    const address: AbstractControl = this.getAddressGroupActive(index, iDestination);
    const city: AbstractControl = iDestination || iDestination === 0 ? this.getFormDestination(iDestination).get('downloadDate') : this.cargoForm.get('dateLoad');
    if (address && city) {
      const time24h: string = DateFormatPipe.prototype.transform(`01-01-1990 ${address.value.time} ${address.value.timeType}`, 'time-24');
      return new Date(`${city.value.substring(0, 10)} ${time24h}`);
    }
    return null;
  }

  public getAddressGroupActive(index: number, iDestination?: number): AbstractControl {
    let ctrl: AbstractControl;
    if (index >= 0) {
      if (this.utils.isDefined(iDestination)) {
        ctrl = this.getFormDestinationAddresses(iDestination).at(index);
      } else {
        ctrl = this.cargoForm.get(`cargoFeature.uploadDownload.origin.addresses.${index}`);
      }
    }
    return ctrl;
  }

  public isValidUploadAddressesAdditionalData(): boolean {
    const addresses: AddressCargo[] = this.cargoForm.get('cargoFeature.uploadDownload.origin.addresses').value as AddressCargo[];
    let isValid = true;
    addresses.map((address: AddressCargo) => {
      if (
        _.isEmpty(address.time) ||
        _.isEmpty(address.timeType) ||
        (!_.isEmpty(address.cellphone) && (address.cellphone.length < 9 || address.cellphone.length > 12 || !this.patterns.CELLPHONEANDLANDLINE.test(address.cellphone)))
      ) {
        isValid = false;
      }
      if (!isValid) {
        return;
      }
    });
    return isValid;
  }

  public isValidDownloadAddressesAdditionalData(): boolean {
    const destinations: Destination[] = this.cargoForm.get('cargoFeature.uploadDownload.destination').value as Destination[];
    let isValid = true;
    destinations.map((destination: Destination) => {
      destination.addresses.map((address: AddressCargo) => {
        if (
          (!_.isEmpty(address.email) &&
            !this.utils.isEmailValid(address.email)) ||
          _.isEmpty(address.time) ||
          _.isEmpty(address.timeType) ||
          (!_.isEmpty(address.cellphone) && address.cellphone.length < 9) ||
          !_.isNumber(address.timePact) ||
          !_.isNumber(address.minutePact)
        ) {
          isValid = false;
        }
        if (!isValid) {
          return;
        }
      });
      if (!isValid) {
        return;
      }
    });
    return isValid;
  }

  public isValidAdditionalCosts(): boolean {
    const additionalCosts: ItemAdditionalCost[] = this.cargoForm.get('additionalCosts').value as ItemAdditionalCost[];
    let isValid = true;
    if (additionalCosts.length) {
      additionalCosts.forEach((additionalCost) => {
        if (additionalCost.cost === 0 && additionalCost.rate === 0) {
          isValid = false;
        }
        if (!isValid) {
          return;
        }
      });
    }
    return isValid;
  }

  public isValidCargoValue(toRequest?: boolean): boolean {
    const shippingCost: ShippingCost = this.cargoForm.get('shippingCost').value as ShippingCost;
    const maxAdvancePercentage = this.getAdvancePercentageOwner() || ((this.authService.getCompany() && this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit) ? AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI : AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES));
    if (this.authService.getCompany().validateFreight) {
      return true;
    } else {
      return (
        _.isNumber(shippingCost.advancePercentage) &&
        shippingCost.advancePercentage >= 0 &&
        shippingCost.advancePercentage <= maxAdvancePercentage &&
        _.isNumber(shippingCost.freightCost) &&
        _.isNumber(shippingCost.rate) &&
        this.travelExpensesForm.valid &&
        (
          (shippingCost.freightCost > 0 &&
            shippingCost.rate > 0) ||
          (this.permissionRole.hasPermission(
            this.permission.cargo.module,
            this.permission.cargo.createCargosWithoutMinimumUtility
          ) &&
            this.cargoForm.get('cargoModel.tripType').value.name === "Urbana" &&
            this.cargoForm.get('cargoFeature.productType.name') &&
            this.cargoForm.get('cargoFeature.productType.name').value &&
            this.cargoForm.get('cargoFeature.productType.name').value === "CONTENEDOR VACIO"
          )
          || toRequest
        )
      );
    }
  }

  public isValidMethodPayment(): boolean {
    const shippingCost: ShippingCost = this.cargoForm.get('shippingCost').value as ShippingCost;
    if (this.authService.getCompany().validateFreight) {
      return (
        _.isNumber(shippingCost.reteicaPercentage) &&
        _.isNumber(shippingCost.reteftePercentage)
      );
    } else {
      return (
        !_.isEmpty(shippingCost.balancePaymentMethod) &&
        _.isNumber(shippingCost.paymentTime) &&
        _.isNumber(shippingCost.totalCost) &&
        (shippingCost.totalCost >= 0) &&
        !_.isEmpty(shippingCost.advancePaymentMethod) &&
        _.isNumber(shippingCost.valueAdvance) &&
        _.isNumber(shippingCost.reteicaPercentage) &&
        _.isNumber(shippingCost.reteftePercentage)
      );
    }
  }

  public isValidObservations(): boolean {
    const observation = this.cargoForm.get('observation').value;
    if (this.authService.getCompany().validateFreight) {
      return (!_.isEmpty(observation));
    } else {
      return (!_.isEmpty(observation) && observation.trim().length >= 3);
    }
  }

  public showMessageMissingFields(): void {
    this.snackBarService.openSnackBar('Faltan campos por llenar', undefined, 'alert');
  }

  public showMessageInvalidDate(): void {
    this.snackBarService.openSnackBar('La fecha de destino debe ser mayor que la de cargue', undefined, 'alert');
  }

  public showMessageInvalidWeight(weight?: string) {
    this.snackBarService.openSnackBar(`El peso total para este vehiculo es mayor al permitido ${weight ? '(' + weight + ' kilos)' : ''}`, undefined, 'alert');
  }

  public showMessageInvalidTotalWeight() {
    this.snackBarService.openSnackBar(`El peso total supera los ${AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG} kilos`, undefined, 'alert');
  }

  public showMessageInvalidDateContainer() {
    this.snackBarService.openSnackBar('Se debe ingresar una fecha de vencimiento para continuar', undefined, 'alert');
  }

  public showMessageNotAllowedDelete() {
    this.snackBarService.openSnackBar('No se puede eliminar el servicio adicional. El servicio ya está pago.', undefined, 'alert');
  }

  public showMessageNotAllowedEdit() {
    this.snackBarService.openSnackBar('No se puede editar el servicio adicional. El servicio ya está pago o ya está aprobado.', undefined, 'alert');
  }

  public showMessageNotAllowedEditAdditionalService() {
    this.snackBarService.openSnackBar('No se pueden editar servicios adicionales después de que el servicio ha sido aprobado. Para editar el servicio adicional, es necesario revertir la aprobación del servicio primero', undefined, 'alert', 8000);
  }

  public showMessageNotAllowedCreateAdditionalService() {
    this.snackBarService.openSnackBar('No se pueden crear servicios adicionales después de que el servicio ha sido aprobado. Para agregar servicios adicionales, es necesario revertir la aprobación del servicio primero', undefined, 'alert', 8000);
  }

  public showMessageNotAllowedDeleteAdditionalService() {
    this.snackBarService.openSnackBar('No se pueden eliminar servicios adicionales después de que el servicio ha sido aprobado. Para eliminar el servicio adicional, es necesario revertir la aprobación del servicio primero', undefined, 'alert', 8000);
  }

  public showMessageNotAllowedEditRate() {
    this.snackBarService.openSnackBar('No se puede editar el servicio adicional. La tarifa no puede ser menor al costo.', undefined, 'alert');
  }
  public showMessageSuccessEdit() {
    this.snackBarService.openSnackBar('El servicio se editó exitosamente', undefined, 'success');
  }

  public showMessageSuccessDelete() {
    this.snackBarService.openSnackBar('El servicio se eliminó exitosamente', undefined, 'success');
  }

  public showMessageErrorDelete() {
    this.snackBarService.openSnackBar('Hubo un error. No se pudo eliminar exitosamente el servicio adicional', undefined, 'error');
  }

  public showMessageWrongDuration(): void {
    this.snackBarService.openSnackBar('Por favor ajuste la duración de los trayectos a mínimo el tiempo estimado recomendado', undefined, 'alert');
  }

  public showMessageNoSelectDowloadTime(): void {
    this.snackBarService.openSnackBar('Por favor seleccione de nuevo un valor de descargue para continuar', undefined, 'alert');
  }

  public getLocationsAddressFormCargo(): any[] {
    const locations = [];
    const addresses: AddressCargo[] = this.cargoForm.get('cargoFeature.uploadDownload.origin.addresses').value as AddressCargo[];
    const destinations: Destination[] = this.cargoForm.get('cargoFeature.uploadDownload.destination').value as Destination[];
    addresses.map((address: AddressCargo) => {
      if (
        (_.isNumber(address.location.lat) && address.location.lat !== 0) &&
        (_.isNumber(address.location.lng) && address.location.lng !== 0)
      ) {
        locations.push({
          ...address.location,
          address: address.address
        });
      }
    });
    destinations.map((destination: Destination) => {
      destination.addresses.map((address: AddressCargo) => {
        if (
          (_.isNumber(address.location.lat) && address.location.lat !== 0) &&
          (_.isNumber(address.location.lng) && address.location.lng !== 0)
        ) {
          locations.push({
            ...address.location,
            address: address.address
          });
        }
      });
    });
    return locations;
  }




  public get formCargoFeature(): FormGroup {
    return this.cargoForm.get('cargoFeature') as FormGroup;
  }

  public get formOriginadditionalCosts(): FormArray {
    return this.cargoForm.get('additionalCosts') as FormArray;
  }

  public get formUploadDownload(): FormGroup {
    return this.formCargoFeature.get('uploadDownload') as FormGroup;
  }

  public get formDestinations(): FormArray {
    return this.formUploadDownload.get('destination') as FormArray;
  }

  public getFormOriginadditionalCost(i: number): FormGroup {
    return this.cargoForm.get('additionalCosts'[i]) as FormGroup;
  }

  public getFormDestination(i: number): FormGroup {
    return this.formDestinations.at(i) as FormGroup;
  }

  public getFormDestinationAddresses(i: number): FormArray {
    return this.getFormDestination(i).get('addresses') as FormArray;
  }

  public get formOrigin(): FormGroup {
    return this.formUploadDownload.get('origin') as FormGroup;
  }
  public getFormOrigin(): FormGroup {
    return this.formUploadDownload.get('origin') as FormGroup;
  }

  public get formOriginAddresses(): FormArray {
    return this.formUploadDownload.get('origin.addresses') as FormArray;
  }


  public getFormOriginAddress(i: number): FormGroup {
    return this.formOriginAddresses.at(i) as FormGroup;
  }

  public get formAdditionalService(): FormGroup {
    return this.formUploadDownload.get('additionalService') as FormGroup;
  }

  public get formNumberDocumentSender(): FormControl {
    return this.cargoForm.get('numberDocumentSender') as FormControl;
  }
  public get formRiskProfile(): FormControl {
    return this.cargoForm.get('riskProfile') as FormControl;
  }
  public get formAxles(): FormControl {
    return this.cargoForm.get('axles') as FormControl;
  }

  public get formCargoOwner(): FormControl {
    return this.cargoForm.get('cargoOwner') as FormControl;
  }

  public get formShippingCost(): FormGroup {
    return this.cargoForm.get('shippingCost') as FormGroup;
  }

  // Catalog

  public getBodyWorkType() {
    return this.http.post<BodyWorkType[]>(
      environment.urlServerTeclogi + this.endpointResources.urlCatalog,
      {
        tokenUser: sessionStorage.getItem('_token'),
        url: this.endpointResources.bodyWorkType
      }
    );
  }

  public getCargoType() {
    return this.http.post<CargoType[]>(
      environment.urlServerTeclogi + this.endpointResources.urlCatalog,
      {
        tokenUser: sessionStorage.getItem('_token'),
        url: this.endpointResources.cargoType
      }
    );
  }

  public getMethodPayment() {
    return this.http.post<MethodPayment[]>(
      environment.urlServerTeclogi + this.endpointResources.urlCatalog,
      {
        tokenUser: sessionStorage.getItem('_token'),
        url: this.endpointResources.methodPayment
      }
    );
  }

  public getContainerMeasure() {
    return this.http.post<ContainerMeasure[]>(
      environment.urlServerTeclogi + this.endpointResources.urlCatalog,
      {
        tokenUser: sessionStorage.getItem('_token'),
        url: this.endpointResources.containerMeasure
      }
    );
  }

  public getRegimeType() {
    return this.http.post<RegimeType[]>(
      environment.urlServerTeclogi + this.endpointResources.urlCatalog,
      {
        tokenUser: sessionStorage.getItem('_token'),
        url: this.endpointResources.regimeType
      }
    );
  }

  public getServiceType() {
    return this.http.post<ServiceType[]>(
      environment.urlServerTeclogi + this.endpointResources.urlCatalog,
      {
        tokenUser: sessionStorage.getItem('_token'),
        url: this.endpointResources.serviceType
      }
    );
  }

  public getTripType() {
    return this.http.post<TripType[]>(
      environment.urlServerTeclogi + this.endpointResources.urlCatalog,
      {
        tokenUser: sessionStorage.getItem('_token'),
        url: this.endpointResources.tripType
      }
    );
  }

  public getVehicleType(): Observable<Catalog> {
    if (!this.catalogVehicles.catalog) {
      this.catalogVehicles = {
        catalog: []
      };
    }
    if (this.catalogVehicles.catalog.length === 0) {
      return this.http.get<Catalog>(
        environment.urlServerTeclogi + this.endpointResources.urlCatalog + "/" + this.endpointResources.vehicleTypeCreation
      ).pipe(
        map((catalog: Catalog) => {
          this.catalogVehicles = catalog;
          return this.catalogVehicles;
        })
      );
    } else {
      return of(this.catalogVehicles);
    }
  }

  public getBodyworkByVehicleType(vehicleType: string) {
    let params = new HttpParams();
    params = params.append('vehicleType', vehicleType);
    return this.http.get<Catalog>(
      `${environment.urlServerTeclogi}${this.endpointResources.catalogBodywork}`,
      { params }
    );
  }

  createRenewCargo(cargos: CargoInterface[] | CargoInterface) {
    const cargosArray = Array.isArray(cargos) ? cargos : [cargos];
    return this.http.post(
      environment.urlServerTeclogi + this.endpointResources.urlCreateCargo,
      cargosArray
    ).pipe(
      timeout(420000)
    );
  }

  showResponseCreateCargo(title: string, errorList?: string[], consecutiveList?, path?: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title
    };
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    if (errorList || (!errorList && !consecutiveList)) {
      dialogConfig.data.messageList = errorList;
      dialogConfig.data.hideBtnConfirm = true;
      dialogConfig.data.iconError = true;
    } else {
      dialogConfig.data.hideBtnCancel = true;
      dialogConfig.data.messageList = consecutiveList;
      dialogConfig.data.icon = true;
    }
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        this.resetCargoForm();
        this.router.navigate([path]);
      }
    });
  }

  public getValueAdditionalCost(): Observable<AdditionalCost[]> {
    this.data = environment.urlServerTeclogi + this.endpointResources.companyAdditionalCost;
    return this.http.get<AdditionalCost[]>(this.data).pipe(
      map((data: AdditionalCost[]) => {
        this.setListAdditionalCost(data);
        return this.getListAdditionalCost();
      })
    );
    // return of(this.mockAdditionalCost);
  }

  private setListAdditionalCost(data: AdditionalCost[]): void {
    ManualCreationCargoService.listAdditionalCost = data;
  }

  public getListAdditionalCost(): AdditionalCost[] {
    return ManualCreationCargoService.listAdditionalCost;
  }

  public getCompaniesNoUtility() {
    return this.http.get('assets/json/companiesNoUtility.json');
  }

  public setUtilityOwner(utility: number): void {
    ManualCreationCargoService.utilityOwner = utility;
  }

  public getUtilityOwner(): number {
    return ManualCreationCargoService.utilityOwner;
  }

  public setutilityAdditionalServiceOwner(utility: number): void {
    ManualCreationCargoService.utilityAdditionalServiceOwner = utility;
  }

  public getutilityAdditionalServiceOwner(): number {
    return ManualCreationCargoService.utilityAdditionalServiceOwner;
  }

  public setAdvancePercentageOwner(advancePercentage: number): void {
    ManualCreationCargoService.advancePercentageOwner = advancePercentage;
  }

  public getAdvancePercentageOwner(): number {
    return ManualCreationCargoService.advancePercentageOwner;
  }

  public setOwnerSelected(company: Company): void {
    ManualCreationCargoService.ownerSelected = company;
  }

  public getOwnerSelected(): Company {
    return ManualCreationCargoService.ownerSelected;
  }

  public setBranchOfficeSelected(branch: BranchOffice): void {
    ManualCreationCargoService.branchOfficeSelected = branch;
  }

  public getBranchOfficeSelected(): BranchOffice {
    return ManualCreationCargoService.branchOfficeSelected;
  }

  public setSubBranchOfficeSelected(branch: BranchOffice): void {
    ManualCreationCargoService.subBranchOfficeSelected = branch;
  }

  public getSubBranchOfficeSelected(): BranchOffice {
    return ManualCreationCargoService.subBranchOfficeSelected;
  }

  public createRoundedCargo(cargo: object) {
    return this.http.post(
      environment.urlServerTeclogi + this.endpointResources.createRoundedCargo, cargo
    ).pipe(
      timeout(420000)
    );
  }

  get addresses() {
    return (this.cargoForm.get('cargoFeature.uploadDownload.origin.addresses') as FormArray).controls;
  }

  public getLabelTags(): Observable<Tag[]> {
    return this.http.get<any>(
      `${environment.urlServerTeclogi}${this.endpointResources.labelTags}`
    ).pipe(
      map(response => response.catalog) // Transforma la respuesta para extraer solo el arreglo 'catalog'
    );
  }

  public approvedBelowSiceTac(cargoId: string) {
    return this.http.put(
      environment.urlServerTeclogi + this.endpointResources.approvedBelowSiceTac + cargoId + "&approved=true", null
    )
  }

  applyTravelExpenses(cargos: Array<CargoInterface>, travelExpenses?: TravelExpense[]) {
    const travelExpensesForm: TravelExpense[] = travelExpenses ? travelExpenses : this.travelExpensesForm.get('travelExpenses').value;
    if (travelExpensesForm.length > 0) {
      const promises = [];
      cargos.forEach(cargo => {
        travelExpensesForm.forEach(
          (expense: TravelExpense) => {
            expense['cargoId'] = cargo.id;
            expense['cargoConsecutive'] = cargo.consecutive;
            const promise = this.travelExpensesService.createTravelExpense(expense).toPromise();
            promises.push(promise);
          }
        );
      })
      this.spinner.show();
      Promise.all(promises).then(() => {
        this.spinner.hide();
      }).catch(() => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Uno o varios viáticos no se han generado correctamente.', undefined, 'error');
      });
    }
  }
}
