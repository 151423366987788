import { Roles } from "../enums/roles.enum";

export const FIELDS_STATE_SCHEMA: FieldsSchema = {
  eps: 'eps',
  arl: 'arl',
  driverLicense: 'driverLicense',
};

export const FIELDS_SECURITY_STUDY_SCHEMA: FieldsSchema = {
  truora: 'truora',
};

export const FIELDS_STATE: Fileds = {
  '9013312320': {
    [Roles.DRIVER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl, FIELDS_STATE_SCHEMA.driverLicense],
  },
  '9009704395': {
    [Roles.DRIVER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl, FIELDS_STATE_SCHEMA.driverLicense],
    [Roles.ADMIN]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl],
    [Roles.OWNER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl],
  },
  '9011981505': {
    [Roles.DRIVER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl, FIELDS_STATE_SCHEMA.driverLicense],
    [Roles.ADMIN]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl],
    [Roles.OWNER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl],
  },
  '9014171480': {
    [Roles.DRIVER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl, FIELDS_STATE_SCHEMA.driverLicense],
    [Roles.ADMIN]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl],
    [Roles.OWNER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl],
  },
  '8000130291': {
    [Roles.DRIVER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl, FIELDS_STATE_SCHEMA.driverLicense],
    [Roles.ADMIN]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl],
    [Roles.OWNER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl],
  },
  '9013522401': {
    [Roles.DRIVER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl, FIELDS_STATE_SCHEMA.driverLicense],
  },
  '9012861321': {
    [Roles.DRIVER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl, FIELDS_STATE_SCHEMA.driverLicense],
  },
  '9011282346': {
    [Roles.DRIVER]: [FIELDS_STATE_SCHEMA.eps, FIELDS_STATE_SCHEMA.arl, FIELDS_STATE_SCHEMA.driverLicense],
  }
};

export const FIELDS_SECURITY_STUDY: Fileds = {
  '9013312320': {
    [Roles.DRIVER]: [FIELDS_SECURITY_STUDY_SCHEMA.truora],
  },
  '9009704395': {
    [Roles.DRIVER]: [FIELDS_SECURITY_STUDY_SCHEMA.truora],
  },
  '9011981505': {
    [Roles.DRIVER]: [FIELDS_SECURITY_STUDY_SCHEMA.truora],
  },
  '9014171480': {
    [Roles.DRIVER]: [FIELDS_SECURITY_STUDY_SCHEMA.truora],
  },
  '8000130291': {
    [Roles.DRIVER]: [FIELDS_SECURITY_STUDY_SCHEMA.truora],
  },
  '9013522401': {
    [Roles.DRIVER]: [FIELDS_SECURITY_STUDY_SCHEMA.truora],
  },
  '9012861321': {
    [Roles.DRIVER]: [FIELDS_SECURITY_STUDY_SCHEMA.truora],
  },
  '9011282346': {
    [Roles.DRIVER]: [FIELDS_SECURITY_STUDY_SCHEMA.truora],
  }
};


export interface Fileds {
  [key: string]: {
    [Roles.DRIVER]?: string[];
    [Roles.ADMIN]?: string[];
    [Roles.OWNER]?: string[];
  };
}

export interface FieldsSchema {
  [key: string]: string;
};
