<form class="position-relative py-4 px-2 px-md-4" [formGroup]="form" *ngIf="form" (submit)="onSubmit()">
    <i *ngIf="dialogParams && dialogParams.form" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
        (click)="dialogRef.close()"></i>
    <h4 class="text-center m-3 text-bold" *ngIf="dialogParams?.checkPortableUnit && dialogParams?.cargo">
        Por favor, verifique la información GPS del vehículo de este servicio
    </h4>
    <h4 class="text-bold pl-0 my-3">
        Tipo de GPS
    </h4>
    <div class="d-flex flex-wrap my-2 align-items-baseline">
        <app-select-gps [inputFormControl]="gpsControls" class="col-lg-6 col-12 mb-2" [options]="optionsGps"
            [validate]="validate"></app-select-gps>
        <button mat-raised-button type="button"
            class="secondary-sharp button-width-auto col-lg-3 col-md-6 col-12 text-bold m-0" color="primary"
            permission-display [module]="permission.administration.module"
            [functionality]="permission.administration.showCreateGps" (click)="createGPS()">
            <i class="fas fa-plus-circle fa-lg mr-1"></i>Crear tipo
        </button>
        <button class="btn-gps-delete col-lg-3 col-md-6 col-12" type="button" permission-display
            [module]="permission.administration.module" [functionality]="permission.administration.showDeleteGps"
            (click)="deleteGPS()">
            <i class="fas fa-trash fa-lg mr-1"></i>Eliminar tipo
        </button>
    </div>

    <h4 class="text-bold pl-0 my-3" *ngIf="!isSatrack || hasPortablePermission">
        Datos de GPS
    </h4>
    <div class="d-flex flex-wrap align-items-baseline">
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Usuario del GPS<span class="asterisk">*</span></mat-label>
            <input matInput type="text" name="userGps" formControlName="userGps">
            <mat-error *ngIf="form.get('userGps').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Contraseña del GPS<span class="asterisk">*</span></mat-label>
            <input matInput type="text" name="passwordGps" formControlName="passwordGps">
            <mat-error *ngIf="form.get('passwordGps').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <div *ngIf="isSatrack && hasPortablePermission" class="col-md-6">
            <label class="text-strong mr-3">Tiene GPS portable</label>
            <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="isPortableUnit">
                <mat-button-toggle [value]="true">
                    Si
                </mat-button-toggle>
                <mat-button-toggle [value]="false">
                    No
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <mat-form-field appearance="outline" class="col-md-6" *ngIf="isSatrack && isPortable" permission-display
            [module]="permission.administration.module" [functionality]="permission.administration.useGpsPortable">
            <mat-label>
                Código
                <span class="asterisk" *ngIf="utils.isRequiredField(form.get('gpsId'))">*</span>
            </mat-label>
            <input matInput type="text" name="gpsId" formControlName="gpsId">
            <mat-error *ngIf="form.get('gpsId').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row" *ngIf="updateGPS">
        <div class="col-md-12 button-container mb-3 mt-3">
            <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
                (click)="closeDialog()" type="button">
                Cancelar
            </button>
            <button mat-raised-button color="primary" type="submit" class="button-width-auto bg-color-primary">
                Guardar
            </button>
        </div>
    </div>
</form>
