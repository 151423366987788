import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Destination } from 'src/app/core/interfaces/destination';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';


@Component({
  selector: 'app-cargo-renew-dates',
  templateUrl: './cargo-renew-dates.component.html',
  styleUrls: ['./cargo-renew-dates.component.scss']
})
export class CargoRenewDatesComponent implements OnInit {

  public dateLoad: string;
  public dateDownload: string[] = [];
  public destinationList: any[] = [];
  public cargo: Cargo;
  configDateLoad = {
    min: new Date(),
    max: null
  };
  minDateDownload = null;

  constructor(
    private manualCreationCargoService: ManualCreationCargoService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<CargoRenewDatesComponent>,
    public dialog: MatDialog,
    public utils: Utils,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) { }

  ngOnInit() {
    this.cargo = this.data;
    this.destinationList = this.cargo.cargoFeature.uploadDownload.destination;
  }

  async renewDates() {
    if (!this.dateLoad || !this.dateDownload.every(date => this.utils.isDefined(date))) {
      this.snackBarService.openSnackBar('Hay fechas sin llenar', undefined, 'alert');
      return;
    }
    let newCargo: Cargo = this.utils.clone(this.cargo);
    newCargo.dateLoad = this.dateLoad;
    newCargo.cargoFeature.uploadDownload.destination.forEach((destination, index) =>
      destination.downloadDate = this.dateDownload[index]
    );
    let path = '/cargo/list/scheduledLoads';
    this.spinner.show();
    this.manualCreationCargoService.processCargo(await this.clearDataCargoToRenew(newCargo), this.cargo.id)
      .then((data: Cargo[]) => {
        this.spinner.hide();
        this.manualCreationCargoService.showResponseCreateCargo(
          'Tu servicio ha sido renovado exitosamente con los consecutivos',
          undefined,
          this.manualCreationCargoService.getConsecutive(data), path
        );
        this.dialogRef.close();
      })
      .catch((error) => {
        this.spinner.hide();
        this.manualCreationCargoService.showResponseCreateCargo(
          'Ocurrió un error al crear el servicio',
          (Array.isArray(error) ? error : undefined)
        );
      });
  }

  private clearDataCargoToRenew(cargo: Cargo): Cargo {
    let newCargo: Cargo = {
      dateLoad: cargo.dateLoad,
      numberDocumentCreatorLoad: this.authService.getUserSession().information.document,
      idCompany: cargo.idCompany,
      exclusive: cargo.exclusive,
      shippingCost: cargo.shippingCost
    };
    if (!this.utils.isEmpty(cargo.observation))
      newCargo.observation = cargo.observation;

    if (!this.utils.isEmpty(cargo.numberDocumentSender))
      newCargo.numberDocumentSender = cargo.numberDocumentSender;

    if (!this.utils.isEmpty(cargo.distancy))
      newCargo.distancy = cargo.distancy;

    if (!this.utils.isEmpty(cargo.estimatedTime))
      newCargo.estimatedTime = cargo.estimatedTime;

    if (!this.utils.isEmpty(cargo.cargoOwner))
      newCargo.cargoOwner = cargo.cargoOwner;

    if (!this.utils.isEmpty(cargo.additionalCosts))
      newCargo.additionalCosts = cargo.additionalCosts;

    if (!this.utils.isEmpty(cargo.seal))
      newCargo.seal = cargo.seal;

    if (!this.utils.isEmpty(cargo.container))
      newCargo.container = cargo.container;

    if (!this.utils.isEmpty(cargo.cargoModel))
      newCargo.cargoModel = cargo.cargoModel;

    if (!this.utils.isEmpty(cargo.ministry))
      newCargo.ministry = cargo.ministry;

    if (!this.utils.isEmpty(cargo.cargoFeature)) {
      newCargo.cargoFeature = cargo.cargoFeature;
      if (!this.utils.isEmpty(cargo.cargoFeature.uploadDownload)) {
        newCargo.cargoFeature.uploadDownload.origin.addresses = cargo.cargoFeature.uploadDownload.origin.addresses.map((address) => {
          if (!this.utils.isEmpty(address.state)) {
            delete address.state;
          }
          return address;
        });
        newCargo.cargoFeature.uploadDownload.destination = cargo.cargoFeature.uploadDownload.destination.map((destination) => {
          destination.addresses.map((address) => {
            if (!this.utils.isEmpty(address.state)) {
              delete address.state;
            }
            if (!this.utils.isEmpty(address.consignments)) {
              delete address.consignments;
            }
            if (!this.utils.isEmpty(address.ministryError)) {
              delete address.ministryError;
            }
            return address;
          });
          return destination;
        });
      }
    }
    if (!this.utils.isEmpty(cargo.driver) && !this.utils.isEmpty(cargo.licensePlate)) {
      newCargo.driver = cargo.driver;
      newCargo.licensePlate = cargo.licensePlate;
      newCargo.state = CargoStateEnum.ACCEPTED;
    }
    if (!this.utils.isEmpty(cargo.escortedVehicle))
      newCargo.escortedVehicle = cargo.escortedVehicle;

    return newCargo;
  }

  onChangeDatePicker($event: any, type: string, index?: number) {
    if (type === "load") {
      this.dateLoad = DateManager.dateToString($event.value, 'YYYY-MM-DD HH:mm ZZ');
    } else {
      this.dateDownload[index] = DateManager.dateToString($event.value, 'YYYY-MM-DD HH:mm ZZ');
    }
  }

  focusDownloadInput(indexDownload: number) {
    if (indexDownload === 0) {
      this.minDateDownload = new Date(this.dateLoad);
    } else {
      this.minDateDownload = this.getMinDateDownload(indexDownload);
    }
  }

  getMinDateDownload(index: number) {
    const lastDate = this.dateDownload[index - 1];
    if (lastDate) {
      return new Date(lastDate);
    } else {
      return {};
    }
  }

}
