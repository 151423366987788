<!--<div class="panic-container" [ngClass]="{'panic-border': cargo && cargo.panic}">
  <header class="d-flex panic-header" *ngIf="cargo && cargo.panic">
    <div class="title light">
      <i class="fas fa-exclamation-triangle"></i> PÁNICO
    </div>
    <div>
      Vehículo: <b>{{cargo.licensePlate}}</b>
    </div>
    <div>
      Conductor: <b>{{cargo.driver}}</b>
    </div>
    <div>
      Contacto: <b>{{driverAssigned.phone}}</b>
    </div>
    <div class="w-100"></div>
    <div>
      <u class="solved-button" (click)="emergencySolved()">Emergencia controlada</u>
    </div>
  </header>-->
<div class="tracking-container">
  <!-- TRACKING OPTIONS -->
  <div class="options-tracking-history-container d-flex flex-column align-items-center h-100">

    <div class="d-flex flex-row align-items-center justify-content-around w-100 flex-wrap">
      <!-- LOAD STATE -->
      <div *ngIf="cargo && cargo.stateTracking">
        <div class="align-items-center justify-content-start mt-3 px-3" [ngSwitch]="cargo.stateTracking">
          <span class="badge badge-warning badge-size" *ngSwitchCase="'Pause'">Estado del servicio: En pausa</span>
          <span class="badge badge-warning badge-size" *ngSwitchCase="'Driver has finished service'">Estado del
            servicio:
            El conductor finalizó la ruta</span>
          <span class="badge badge-warning badge-size" *ngSwitchCase="'End service'">Estado del servicio:
            Finalizado</span>
          <span class="badge badge-warning badge-size" *ngSwitchDefault>Estado del servicio: En ruta</span>
        </div>
      </div>
      <!-- BELL ALERT AND CARGO OPTIONS -->
      <div class="d-flex align-items-center justify-content-end pr-0 pr-sm-3 mt-3">
        <app-bell-alert *ngIf="showBellAlert" [cargo]="cargo">
        </app-bell-alert>
        <app-cargo-options *ngIf="cargo" [type]="'button'" [cargo]="cargo" [tracepoints]="tracepoints"
          (refreshCargo)="getCargoDetail($event)" (executeParentMethod)="onExecuteParentMethod($event)">
        </app-cargo-options>
      </div>
    </div>


    <!-- STARTDATE, END DATE AND DURATION OF THE TRIP -->
    <div class="w-100" *ngIf="cargo?.durationTime">
      <h6 class="text-strong text-center color-secondary-dark">Estado del viaje</h6>
      <div class="pt-2 pb-3 px-4" *ngIf="cargo && !cargo.startTripDate && enableStateTrackingAddresses">
        <p class="start-route-title mb-0">Inicie el viaje para activar el seguimiento de Puntos de Cargue y Descargue.
        </p>
        <div class="button-container">
          <button class="start-route-button mr-2 mb-3" mat-raised-button color="primary"
            (click)="startRealRoute('Start trip')" [disabled]="cargo?.manifestError?.error && cargo?.ministryError"
            [ngClass]="{'disable': cargo?.manifestError?.error && cargo?.ministryError, 'bg-color-primary': !(cargo?.manifestError?.error && cargo?.ministryError)}"><i
              class="fas fa-play fa-lg mr-2"></i> Iniciar ruta</button>
          <span class="badge badge-danger badge-size mr-2 d-flex align-items-center"
            *ngIf="cargo?.manifestError?.error && cargo?.ministryError"><i class="fas fa-exclamation-circle"></i>
            <div>Para iniciar la ruta, es necesario solucionar los errores del manifiesto.</div>
          </span>

        </div>
      </div>

      <div class="d-flex flex-column align-items-left justify-content-start pt-2 pb-3 px-4">
        <div class="duration-time-label" *ngIf="cargo?.durationTime?.startDate">
          <span class="text-bold">Inicio del viaje:</span> {{cargo?.durationTime?.startDate ?
          (formatDate(cargo?.durationTime?.startDate)) : '-'}}
        </div>
        <div class="duration-time-label" *ngIf="cargo?.startTripDate">
          <span class="text-bold">Inicio de la ruta:</span> {{cargo?.startTripDate ?
          (formatDate(cargo?.startTripDate, 'only-date') ) : '-'}}
        </div>
        <div class="duration-time-label" *ngIf="cargo?.durationTime?.endDate">
          <span class="text-bold">Finalización del viaje:</span> {{(cargo.durationTime.endDate | dateFormat)}}
        </div>
        <div class="duration-time-label" *ngIf="cargo?.durationTime?.duration">
          <span class="text-bold">Duración del viaje:</span> {{(cargo.durationTime.duration |
          millisecondsDigitalClock)}}
        </div>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-around w-100 flex-wrap"
      *ngIf="cargoService.secondsLeftToUploadEvidences">
      <div>
        <div class="w-100">
          <span class="badge badge-success badge-size">Subir evidencias en:
            {{cargoService.timeLeftToUploadEvidences}}</span>
        </div>
      </div>
    </div>
    <!-- HISTORY OF TRACKING AND BUTTON ACTIONS -->
    <div class="d-flex flex-column align-items-center justify-content-between h-100 w-100">
      <app-cargo-tracking-states-history class="tracking w-100 px-3" [cargo]="cargo"
        (emitToParent)="lisenerStatesHistory($event)" [historyNoveltyPoints]="listReportedAnomalies"
        [driver]="driverAssigned" [vehicle]="vehicleAssigned">
      </app-cargo-tracking-states-history>
      <div class="end-cargo-button-container" matTooltip="{{cantFinalizeCargoReason}}"
        matTooltipClass="extended-tooltip">
        <button mat-raised-button color="accent" type="button" (click)="endCargo($event)"
          [disabled]="!canFinalizeCargo">Finalizar servicio</button>
      </div>
      <div *ngIf="cargo &&(cargo.ministry || !cargo.ministry || cargo.externalManifest)"
        class="d-flex justify-content-around align-items-center flex-wrap w-100 py-3">
        <div class="alert alert-warning col-12 mt-2 text-center f-14" role="alert" *ngIf="cargo.manifestAlert">
          <b>ALERTA: </b>{{cargo.manifestAlert}}
        </div>
        <!-- Urban -->
        <button *ngIf="!cargo.ministry && hasConsignmentUrbanPermission"
          class="font-primary-light btn text-strong actions-card-primary-secondary"
          (click)="cargoOptionsComponent.openCargoPDF(PDFTypes.CONSIGNMENT_URBAN)">
          <i class="fas fa-file-alt"></i>
          Guía terrestre
        </button>
        <button *ngIf="!cargo.ministry && hasManifestUrbanPermission" permission-display
          [module]="permission.cargo.module" [functionality]="permission.cargo.readManifest"
          class="font-primary-light btn text-strong actions-card-primary-secondary"
          (click)="cargoOptionsComponent.openCargoPDF(PDFTypes.MANIFEST_URBAN)">
          <i class="fas fa-file-alt"></i>
          Plantilla urbana
        </button>
        <!-- National -->
        <button *ngIf="cargo.ministry && hasConsignmentPermission"
          class="font-primary-light btn text-strong actions-card-primary-secondary"
          (click)="cargoOptionsComponent.openCargoPDF(PDFTypes.CONSIGNMENT)">
          <i class="fas fa-file-alt"></i>
          Remesa
        </button>
        <button *ngIf="cargo.manifest && cargo.ministry && !cargo.ministryError && hasManifestPermission"
          permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.readManifest"
          class="font-primary-light btn text-strong actions-card-primary-secondary"
          (click)="cargoOptionsComponent.openCargoPDF(PDFTypes.MANIFEST)">
          <i class="fas fa-file-alt"></i>
          Manifiesto
        </button>
        <button *ngIf="cargo.manifestError && cargo.manifestError.error && cargo.ministryError" permission-display
          [module]="permission.cargo.module" [functionality]="permission.cargo.readManifest"
          class="font-secondary-dark text-strong btn actions-card-primary-secondary" (click)="fixManifestError()">
          <i class="fas fa-exclamation-triangle"></i>
          Error en Manifiesto
        </button>
        <button *ngIf="cargo.externalManifest" class="font-primary-light btn text-strong actions-card-primary-secondary"
          (click)="cargoOptionsComponent.openExternalManifest()">
          <i class="fas fa-eye"></i>
          Manifiesto Externo
        </button>
      </div>
    </div>
  </div>
  <!-- MAP AND SUMMARY DRIVER -->


  <div class="h-100 w-100 position-relative">
    <!-- Driver Summary -->
    <div class="d-flex flex-row justify-content-around w-100 my-3 px-2">
      <div *ngIf="isMonitoreable" class="text-center monitor-tooltip-container"
        style="height: 43px; line-height: 43px;">
        <label><b>Seguimiento con GPS:</b>&nbsp;</label>
        <b style="font-weight: 900;" *ngIf="!trackingState">
          Inactivo
        </b>
        <b style="font-weight: 900;" class="text-info" *ngIf="trackingState">
          Activo
        </b>
        &nbsp;
        <i class="fas fa-exclamation-circle text-info"></i>

        <div class="monitor-tooltip">
          <div *ngIf="trackingState">
            Tiempo restante de monitoreo <br>
            <b>{{remainingMonitoring}}</b>
          </div>
          <div *ngIf="!trackingState && hasLastMonitoringDate">
            Último monitoreo <br>
            <b>29/05/2024 10:02 am</b>
          </div>
          <div *ngIf="!trackingState && !hasLastMonitoringDate">
            ¡Controla tu flota con el rastreo constante y alertas automáticas de múltiples GPS!
          </div>

          <div class="actions" *ngIf="!hasPermissionToActivateMonitor">
            Para configurar trazabilidad con GPS contacte a un administrador.
          </div>
          <div class="actions" style="text-decoration: underline;" *ngIf="hasPermissionToActivateMonitor"
            (click)="setUpMonitorToVehicle()">
            Configurar trazabilidad con GPS
          </div>
        </div>
      </div>
      <div class="text-center">
        <label><b>Detalles:</b>&nbsp;</label>
        <mat-button-toggle-group (change)="onToggleChange($event, 'showMarkers')" [formControl]="showMarkers">
          <mat-button-toggle [value]="true">Sí</mat-button-toggle>
          <mat-button-toggle [value]="false">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="text-center">
        <label><b>Geocercas:</b>&nbsp;</label>
        <mat-button-toggle-group (change)="onToggleChange($event, 'showGeofences')" [formControl]="showGeofences">
          <mat-button-toggle [value]="true">Sí</mat-button-toggle>
          <mat-button-toggle [value]="false">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <app-driver-summary [driver]="driverAssigned" [vehicle]="vehicleAssigned"></app-driver-summary>
    <div class="map">
      <app-standard-map [options]="mapOptions"></app-standard-map>
    </div>
  </div>
</div>
<!--</div>-->
<!-- List Payments -->
<app-list-cargo-payments #modalListCargoPayments *ngIf="cargo && cargo.shippingCost && cargo.shippingCost.payments"
  [cargo]="cargo" [payments]="cargo.shippingCost.payments"></app-list-cargo-payments>
<ng-template #modalCargoPDF let-modal>
  <div class="modal-body wrapper-modal modal-content-PDF" *ngIf="cargoPDF">
    <embed [src]="cargoPDF" type="application/pdf" />
  </div>
</ng-template>