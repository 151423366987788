<div class="modal-body wrapper-modal position-relative">
  <!--Step 1 - Date Load -->
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div>
    <p class="title mx-3">
      Seleccione fecha de origen
    </p>
    <mat-form-field appearance="fill">
      <mat-label>Seleccione una fecha<span class="asterisk">*</span></mat-label>
      <input matInput readonly [min]="configDateLoad.min" [matDatepicker]="datePickerLoad"
        (dateChange)="onChangeDatePicker($event,'load')" (click)="datePickerLoad.open()">
      <mat-datepicker-toggle matSuffix [for]="datePickerLoad"></mat-datepicker-toggle>
      <mat-datepicker #datePickerLoad></mat-datepicker>
    </mat-form-field>
    <mat-error class="custom-mat-error" *ngIf="!dateLoad">{{ utils.giveMessageError('MANDATORY_FIELD') }}</mat-error>
  </div>

  <div>
    <p class="title">
      Seleccione fecha de destino
    </p>
    <div *ngFor="let destination of destinationList; let i = index;">
      <p>Para destino: {{destination.name}} </p>
      <mat-form-field appearance="fill">
        <mat-label>Seleccione una fecha<span class="asterisk">*</span></mat-label>
        <input matInput readonly [matDatepicker]="datePickerDownload" [min]="minDateDownload"
          (dateChange)="onChangeDatePicker($event,'download', i)" (focus)="focusDownloadInput(i)"
          (click)="datePickerDownload.open()">
        <mat-datepicker-toggle matSuffix [for]="datePickerDownload"></mat-datepicker-toggle>
        <mat-datepicker #datePickerDownload (opened)="focusDownloadInput(i)"></mat-datepicker>
      </mat-form-field>
      <mat-error class="custom-mat-error" *ngIf="!dateDownload[i]">{{ utils.giveMessageError('MANDATORY_FIELD')
        }}</mat-error>
    </div>
  </div>
  <div class="button-container">
    <button mat-raised-button class="primary-secondary button-width-auto bg-color-primary" color="primary"
      (click)="renewDates()">
      Restaurar servicio
    </button>
  </div>
</div>