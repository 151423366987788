import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParamsCargoList } from 'src/app/core/interfaces/paramsCargoList';
import { Filter } from 'src/app/core/models/filter';
import { PaginationList } from 'src/app/core/models/pagination-list';
import { CargoService } from 'src/app/core/services/cargo.service';
import { TransportRequestsService } from 'src/app/src/app/core/services/transport-requests.service';
import { FreightListService } from '../cargo-list/cargo-list.service';
import { Utils } from 'src/app/core/resources/utils';

@Component({
  selector: 'app-list-transport-requests',
  templateUrl: './list-transport-requests.component.html',
  styleUrls: ['./list-transport-requests.component.scss'],
  providers: [CargoService, TransportRequestsService]
})
export class ListTransportRequestsComponent implements OnInit {

  @Input() paramsCargoList: ParamsCargoList = {};
  @Input() typeList: string;
  public optionsFilter: Filter;
  public transportRequets = [];
  public paginationList: PaginationList = new PaginationList();
  private customeFilters: string;

  constructor(
    private cargoService: CargoService,
    private spinner: NgxSpinnerService,
    private freightListService: FreightListService,
    private utils: Utils
  ) {
    this.paginationList.setPageSize(10);
  }

  ngOnInit() {
    this.optionsFilter = this.freightListService.getOptionsFilter(this.typeList);
    //this.getList();
  }

  private getList(): void {
    const params: ParamsCargoList = {
      pageKey: this.paginationList.getPageKey(),
      pageSize: this.paginationList.getPageSize(),
      ...this.paramsCargoList,
    };
    this.spinner.show();
    this.cargoService.cargoList(params, this.customeFilters).subscribe(
      (response) => {
        if (!this.utils.isDefined(response) || response.length === 0) {
          this.paginationList.setEnablePagingScroll(false);
        } else {
          this.paginationList.setList(this.transportRequets && this.transportRequets.length ? this.transportRequets.concat(response) : response);
        }
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        console.error(error)
      }
    );
  }

  public onScrollDown(): void {
    if (this.paginationList.getEnablePagingScroll()) {
      this.paginationList.setPageKey(this.paginationList.getPageKey() + 1);
      this.getList();
    }
  }

  public refreshList($event?) {
    this.resetPagging();
    if ($event) this.customeFilters = $event;
    this.getList();
  }

  private resetPagging(): void {
    this.customeFilters = null;
    this.paginationList.setPageKey(1);
    this.paginationList.setList([]);
    this.paginationList.setEnablePagingScroll(true);
  }

}
