import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from '../resources/endpoints';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { Company } from '../interfaces/company';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { User } from './../interfaces/user';
import { RoleService } from './role.service';
import { Role } from './../interfaces/role';
import { NgxSpinnerService } from 'ngx-spinner';
import { FullUser } from '../interfaces/fullUser';
import { Cargo } from '../interfaces/cargo';
import { Utils } from '../resources/utils';
import __roleMaster from '__mock/roleMaster.json';
import { CatalogItem } from '../interfaces/catalogItem';
import { Catalog } from '../interfaces/catalog';
import { TermsAndConditions } from '../interfaces/termsAndConditions';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { UserClient } from '../interfaces/userClient';
import { UserClientModel } from '../models/userClient.model';
import { AccountService } from 'src/app/modules/account/account.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from './snackBar.service';
import { ServiceMessages } from '../messages/service-messages.enum';
import { Global } from '../resources/global';
import { DocumentTypeEnum } from '../enums/document-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static userInstance = new UserClientModel();

  public static afAuth: firebase.auth.Auth;
  public static fStorage: firebase.storage.Storage;
  public static fDatabase: firebase.database.Database;
  public static fMessaging: firebase.messaging.Messaging;
  public static fFirestore: firebase.firestore.Firestore;
  public global: Global
  public static watchdogActive = false;
  private WatchDog = () => {
    const document: string = AuthService.userInstance.information.document;

    if (document.length) {
      import('../classes/firebase-api')
        .then(F => {
          const firestore = F.FirebaseApi.firestore
          firestore
            .collection('UserClient')
            .doc(document)
            .onSnapshot((snapshot) => {
              const user = snapshot.data();
              const active = user.state && user.state.active;
              const isPending = user.role === "webUserPendingActivate";
              if (!active && !isPending) {
                // Logout
                sessionStorage.removeItem('_lastList');
                sessionStorage.removeItem('_freightActive');
                sessionStorage.removeItem('_token');
                sessionStorage.removeItem('_email');
                sessionStorage.removeItem('_phone');
                sessionStorage.removeItem('firebaseAppConfig');

                AuthService.userInstance = new UserClientModel();

                F.FirebaseApi
                  .auth
                  .signOut()
                  .then(() => {
                    F.FirebaseApi.close();
                    this.dialog.closeAll();
                    this.router.navigate(['/']);
                    this.snackBarService.openSnackBar('Su cuenta está deshabilitada.', 'x', 'error');
                  });
              }
            });
        })
    }

  };

  public cargoActive: { cargo: Cargo, negotiation: any, negotiationRequest: any } = {
    cargo: null,
    negotiation: {
      state: null,
      driver: null,
      negotiatedValueDriver: null,
      negotiatedValueCreator: null
    },
    negotiationRequest: {
      state: null,
      companyId: null,
      negotiatedValueCompany: null,
      negotiatedValueCreator: null
    }
  };
  public companyInstance: Company;
  public companySaaS: Company;
  public static companies: Company[] = [];
  public static gps: CatalogItem[] = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private endpointResources: Endpoints,
    private roleService: RoleService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    public utils: Utils,
    public dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {

  }

  public getFullUserByEmail(email: string) {
    return this.http.get<FullUser>(
      environment.urlServerTeclogi + this.endpointResources.configUser + email
    ).pipe(
      catchError(error => {
        console.error(error);
        throw this.errorDetailUserByEmail('user', null, error);
      }),
      map((userData: FullUser) => {
        this.validateDetailUserByEmail(userData);
      }),
      finalize(() => {
        if (!AuthService.watchdogActive) {
          AuthService.watchdogActive = true;
          this.WatchDog();
        }
      })
    );
  }

  public getAllCompanies() {
    return this.http.get<Company[]>(
      environment.urlServerTeclogi + this.endpointResources.getAllCompanies
    ).pipe(
      map((data: Company[]) => {
        this.setCompanies(data);
      })
    );
  }

  public getAllTypesGps() {
    return this.http.get<Catalog>(
      environment.urlServerTeclogi + this.endpointResources.getAllTypesGps
    ).pipe(
      map((data: Catalog) => {
        if (data && data.catalog) {
          this.setGPS(data.catalog);
        } else {
          this.setGPS([]);
        }
      })
    );
  }

  public getFullUserByPhone(phone: string) {

    return this.http.get<User>(
      environment.urlServerTeclogi + this.endpointResources.getUserPhone + phone
    ).pipe(
      catchError(error => {
        console.error(error);
        return error;
      }),
      map((userData: User) => {
        this.setUserSessionPhone(userData);
        return userData;
      })
    );
  }

  private errorDetailUserByEmail(tag, dataUser, error) {
    if (dataUser) {
      return this.validateDetailUserByEmail(dataUser);
    } else {
      error.tag = tag;
      return error;
    }
  }

  private validateDetailUserByEmail(dataUser: FullUser) {

    let user: UserClient;
    let role: Role;
    let company: Company;

    if (dataUser) {
      if (dataUser.user) {
        user = dataUser.user;

        if (dataUser.role) {
          role = dataUser.role;
          this.roleService.setRoleUser(role);
        }
        if (dataUser.company) {
          company = dataUser.company;
          this.setCompany(company);
        }
        if (sessionStorage.getItem('_companyId')) {
          this.setCompanySaaS(sessionStorage.getItem('_companyId'));
        }
        this.setUserSession(user);
        if ((!user.lastUserTerm) || (user.lastUserTerm && !user.lastUserTerm.state)) {
          this.spinner.hide();
          this.router.navigate(['/account/validate-terms']);
        }
        return user;
      }
    }
    this.logOut();
    this.clearSession();
    this.dialog.closeAll();
    this.router.navigate(['/account/login']);
  }

  public getDetailCompany(nit: string) {
    return this.http.get(
      environment.urlServerTeclogi + this.endpointResources.urlDetailCompany + nit
    ).pipe(
      map(
        (dataCompany: Company) => {
          if (dataCompany) {
            this.setCompany(dataCompany);
            return this.getCompany();
          } else {
            this.logOut();
            this.clearSession();
            this.dialog.closeAll();
            this.router.navigate(['/account/login']);
          }
        }
      )
    );
  }

  public userIsFromRootNit(): boolean {
    try {
      const companies = this.accountService.listRegisterCompanies();
      return companies &&
        this.getUserSession() &&
        this.getUserSession().clientCompanyId &&
        companies.some(company => company.nit === this.getUserSession().clientCompanyId);
    } catch {
      return false;
    }
  }

  public setUserSessionPhone(user: User) {

    AuthService.userInstance.deserialize(user);
    if (user && user.phone) {
      sessionStorage.setItem('_phone', user.phone);
    }
  }

  public signUp(email, password) {
    return AuthService.afAuth.createUserWithEmailAndPassword(email, password);
  }

  public getCurrentUserFirebase() {
    return AuthService.afAuth.currentUser;
  }

  public signIn(email, password) {
    return AuthService.afAuth.signInWithEmailAndPassword(email, password);
  }

  public signInGoogle() {
    sessionStorage.setItem('_PendingSSO', "true");
    const provider = (
      environment.production ?
        //@ts-ignore
        new firebase.auth.SAMLAuthProvider('saml.workspace') :
        new firebase.auth.GoogleAuthProvider()
    );
    return AuthService.afAuth.signInWithPopup(provider);
  }

  public signInCredential(credential: firebase.auth.AuthCredential) {
    return AuthService.afAuth.signInWithCredential(credential);
  }

  public async logOut() {
    if (AuthService.fMessaging)
      await AuthService.fMessaging.deleteToken(this.getUserSession().uId);
    firebase.app().delete();
    AuthService.userInstance = new UserClientModel();
    const signInType = sessionStorage.getItem('signInType');
    sessionStorage.clear();
    AuthService['companySaaS'] = null;
    AuthService['companyInstance'] = null;
    if (signInType !== null) sessionStorage.setItem('signInType', signInType);
    if (AuthService.afAuth)
      return AuthService.afAuth.signOut();
  }

  public clearSession() {
    sessionStorage.removeItem('_lastList');
    sessionStorage.removeItem('_freightActive');
    sessionStorage.removeItem('_token');
    sessionStorage.removeItem('_email');
    sessionStorage.removeItem('_phone');
    sessionStorage.removeItem('firebaseAppConfig');
  }

  public sessionActive() {
    return !!sessionStorage.getItem('_token') && !!this.getUserSession() && !!this.getUserSession().email;
  }

  public setUserSession(user: User) {

    AuthService.userInstance.deserialize(user);

    if (user) {
      sessionStorage.setItem('_email', user.email);
    }
  }

  public getUserSession(): UserClientModel {
    return AuthService.userInstance;
  }

  public userSessionIsActive(): boolean {
    try {
      return !!this.getUserSession().state.active;
    } catch (e) {
      return false;
    }
  }

  public getCompanies(): Company[] {
    return AuthService.companies;
  }

  public setCompanies(companies) {
    AuthService.companies = companies;
  }

  public getGPS(): CatalogItem[] {
    return AuthService.gps;
  }

  public setGPS(gps) {
    AuthService.gps = gps;
  }

  public getCompany(): Company {
    return AuthService['companyInstance'] ? AuthService['companyInstance'] : null;
  }

  public getCompanySaaS(): Company {
    return AuthService['companySaaS'] ? AuthService['companySaaS'] : null;
  }

  public setCompany(company: Company) {
    AuthService['companyInstance'] = company;
    firebase.firestore().collection('Company').doc(company.companyId).onSnapshot(
      (snapshot) => {
        const company = snapshot.data();
        AuthService['companyInstance'] = company;
      }
    );
  }

  public setCompanySaaS(nit: string) {
    return this.http.get(
      environment.urlServerTeclogi + this.endpointResources.urlDetailCompany + nit
    ).subscribe(
      (dataCompany: Company) => {
        if (dataCompany) {
          AuthService['companySaaS'] = dataCompany;
          firebase.firestore().collection('Company').doc(dataCompany.companyId).onSnapshot(
            (snapshot) => AuthService['companySaaS'] = snapshot.data()
          )
        } else {
          this.logOut();
          this.clearSession();
          this.dialog.closeAll();
          this.router.navigate(['/account/login']);
        }
      }, () => AuthService['companySaaS'] = null
    );
  }

  public setCompanyFieldsExcelCargo(fields) {
    if (AuthService['companyInstance']) {
      AuthService['companyInstance'].fieldsExcelCargo = this.utils.clone(fields);
    }
  }

  public userActive(): boolean {
    return AuthService.userInstance ? (AuthService.userInstance.state.active ? true : false) : false;
  }

  public setToken(token: any) {
    if (token) {
      sessionStorage.setItem('_token', token);
    }
  }

  public resetPassword(email: string) {
    return AuthService.afAuth.sendPasswordResetEmail(email);
  }

  public getTokenUser() {
    return AuthService.afAuth.currentUser.getIdTokenResult();
  }

  public validateUser(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.getUserSession() && this.getUserSession().information.document) {
        resolve(true);
      } else {
        if (sessionStorage.getItem('_email')) {
          this.getFullUserByEmail(sessionStorage.getItem('_email')).subscribe(
            (success) => {
              resolve(true);
            },
            (error) => {
              console.error(error);
              resolve(false);
            }
          );
        } else if (sessionStorage.getItem('_phone')) {
          this.getFullUserByPhone(sessionStorage.getItem('_phone')).subscribe(
            (success) => {
              resolve(true);
            },
            (error) => {
              console.error(error);
              resolve(false);
            }
          );
        } else {
          resolve(false);
        }
      }
    });
  }

  public updateUId(uId?: string) {
    let params = new HttpParams();
    params = params.append('document', this.getUserSession().information.document);
    params = params.append('uId', uId);
    return this.http.put(
      environment.urlServerTeclogi + this.endpointResources.urlUpdateUId,
      {},
      { params }
    ).pipe(
      mergeMap((data) => this.getFullUserByEmail(this.getUserSession().email))
    );
  }

  public userClientUpdateTerms(userId: string, body: TermsAndConditions) {
    let params = new HttpParams();
    params = params.append('userId', userId);
    return this.http.put(
      environment.urlServerTeclogi + this.endpointResources.urlUserClientUpdateTerms,
      body,
      { params }
    ).pipe(
      mergeMap((data) => this.getFullUserByEmail(this.getUserSession().email))
    );
  }

  async signInWithCustomToken(token: string, email: string) {
    try {
      const result = await AuthService.afAuth.signInWithCustomToken(token);
      this.setToken(result.user.uid);
      this.getDetailUserByEmail(email);
    } catch (error) {
      let errorMessage = '';
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = "La dirección de correo electrónico ya está en uso por otra cuenta.";
          break;
        case 'auth/wrong-password':
          errorMessage = "La contraseña no es válida o el usuario no tiene una contraseña.";
          break;
        case "auth/user-disabled":
          errorMessage = "La cuenta de usuario ha sido deshabilitada por un administrador.";
          break;
        case "auth/user-not-found":
          errorMessage = "El usuario no está registrado";
          break;
        case "auth/internal-error":
          errorMessage = "Usuario o contraseña incorrectos";
          break;
        default:
          errorMessage = 'Ocurrió un error para acceder a la cuenta';
          break;
      }
      this.snackBarService.openSnackBar(errorMessage, undefined, 'error');
    }
  }


  getDetailUserByEmail(email: string, sso_meta?: any) {
    const $ = this.getFullUserByEmail(email)
      .subscribe(
        {
          next: () => {
            if (
              this.utils.isDefined(this.getUserSession().information.document) &&
              !this.utils.isEmpty(this.getUserSession().information.document)
            ) {
              location.reload();
            } else if (!!sso_meta) {
              // Registrar al usuario desde el SSO
              let user: UserClient = null;
              try {
                user = {
                  information: this.getInformationFromMeta(sso_meta),
                  email: sso_meta.user.email,
                  clientCompanyId: environment.rootNit,
                  phone: sso_meta.additionalUserInfo.profile.phone,
                  role: this.global.defaultRoles.webUserPendingActivate.id,
                  lastUserTerm: null
                }
              } catch (e) {
                return;
              }
            } else {
              this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
              this.logOut();
            }
          },
          error: () => {
            this.logOut();
            this.snackBarService.openSnackBar(ServiceMessages.GENERAL_RETRY_INDICATION, undefined, 'error');
            this.spinner.hide();
          },
          complete: () => {
            $.unsubscribe();
          }
        }
      );
  }

  private getInformationFromMeta(meta) {
    let documentTypeId, documentTypeName, document, sigla;

    [sigla, document] = meta.additionalUserInfo.profile.document_id.split(':');
    switch (sigla) {
      case DocumentTypeEnum.CEDULA_CIUDADANIA:
        documentTypeId = '1';
        documentTypeName = 'Cédula de ciudadanía';
        break;
      case DocumentTypeEnum.PASAPORTE:
        documentTypeId = '2';
        documentTypeName = 'Pasaporte';
        break;
      case DocumentTypeEnum.NIT:
        documentTypeId = '3';
        documentTypeName = 'NIT';
        break;
      default:
        throw new Error();
    }

    return {
      document,
      documentTypeId,
      documentTypeName,
      name: `${meta.additionalUserInfo.profile.name} ${meta.additionalUserInfo.profile.lastname ? meta.additionalUserInfo.profile.lastname : ''}`,
    };
  }

}
