<div class="cargo d-flex flex-column flex-xl-row"
  *ngIf="cargo && ((typeList === 'loadsInNegotiation' || typeList === 'cargoRequestNegotiation') && isFixedCargo()) || (typeList !== 'loadsInNegotiation' && typeList !== 'cargoRequestNegotiation')"
  [ngClass]="{'border-intermitente-card': showTransportedValueSemaphore(), 'background-sleep': cargo.stateTracking === 'Pause' && route?.snapshot?.url[0]?.path === 'loadingRoutes'}">

  <div class="w-100 p-cell">
    <div class="col-md-12">

      <div class="row cargo__header relative">
        <div class="absolute absolute--right-top cargo__consecutive">
          <div class="id-cargo-container"
            [ngClass]="{'mt-2': cargo.state === 'Created' && filteredCargosTurns && filteredCargosTurns.matches.length > 0}">
            <span title="Consecutivo de servicio Nº {{cargo.consecutive}}">
              Id: {{ cargo.consecutive !=0 ? (cargo.consecutive) : '-' }}
            </span>
          </div>
        </div>
        <div class="col-md-2">
          <span class="title-purple" title="{{cargo.creationDate | dateFormat:'only-date'}}">
            {{cargo.creationDate | dateFormat:'only-date'}}
          </span>
        </div>
        <div class="col-md-6">
          <span class="title-purple text-break" title="{{cargo.numberDocumentSender}}">
            N° Documento remitente: {{cargo.numberDocumentSender ? cargo.numberDocumentSender : ''}}
          </span>
        </div>
        <div class="col-md-6">
          <span class="title-purple text-break" title="{{cargo.idCompany | companyName}}">
            Operación: {{cargo&&cargo.idCompany?(cargo.idCompany | companyName):''}}
          </span>
        </div>
      </div>

      <div class="row cargo__body">
        <!-- Origin -->
        <div class="col-md-2">
          <div class="label">
            Origen
          </div>
          <div class="text-bold label-field">
            <span title="{{cargo.cargoFeature.uploadDownload.origin.name}}">
              {{cargo.cargoFeature.uploadDownload.origin.name}}
              {{cargo.cargoFeature.uploadDownload.origin.country?
              '('+cargo.cargoFeature.uploadDownload.origin.country.name+')':''}}
            </span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="label">
            Fecha de cargue
          </div>
          <div class="text-bold label-field">
            <span title="{{dateManager.transformDateUFC(cargo.dateLoad)|dateFormat:'only-date'}}">
              {{dateManager.transformDateUFC(cargo.dateLoad)|dateFormat:'only-date'}}
            </span>
          </div>
        </div>
        <!-- Destination -->
        <div class="col-md-2">
          <div class="label">
            Destino
          </div>
          <div class="text-bold label-field">
            <span
              title="{{cargo.cargoFeature.uploadDownload.destination[cargo.cargoFeature.uploadDownload.destination.length - 1].name}}">
              {{cargo.cargoFeature.uploadDownload.destination[cargo.cargoFeature.uploadDownload.destination.length
              -1].name}}
              {{cargo.cargoFeature.uploadDownload.destination[cargo.cargoFeature.uploadDownload.destination.length
              -1].country?'('+cargo.cargoFeature.uploadDownload.destination[cargo.cargoFeature.uploadDownload.destination.length
              -1].country.name+')':''}}
            </span>
          </div>
        </div>

        <div class="col-md-3">
          <div class="label">
            Fecha de descargue
          </div>
          <div class="text-bold label-field">
            <span
              title="{{cargo && cargo.cargoFeature && cargo.cargoFeature.cargoMeasure && cargo.cargoFeature.cargoMeasure.amount ? (cargo.cargoFeature.cargoMeasure.amount  | currency:'COP':'code') : '-'}}">
              <!-- {{dateManager.transformDateUFC()|date:'medium'}} -->
            </span>
          </div>
        </div>
        <div class="col-md-2" *ngIf="hasPermissionFinancier">
          <div class="label">
            Flete
          </div>
          <div class="text-bold label-field"
            *ngIf="cargo&&cargo.shippingCost&&(cargo.shippingCost.totalCost||cargo.shippingCost.totalCost===0)">
            <span title="{{cargo.shippingCost.totalCost}}">
              {{cargo.shippingCost.totalCost | currency:'COP':'code'}}
            </span>
          </div>
          <div class="text-bold label-field" *ngIf="cargoItemService.getTotalDiscounts(cargo)">
            <span class="title-4 text__color color--error"
              title="Descuentos: + {{cargoItemService.getTotalDiscounts(cargo)}}">
              <span class="text__color color--error">(-)</span>
              <a class="text__color color--error text-underlined" role="button"
                (click)="openShippingCostDetails()">{{cargoItemService.getTotalDiscounts(cargo) |
                currency:'COP':'code'}}</a>
            </span>
          </div>
          <div class="text-bold label-field" *ngIf="cargoItemService.getTotalBonuses(cargo)">
            <span class="title-4 text__color color--success"
              title="Bonificaciones: + {{cargoItemService.getTotalBonuses(cargo)}}">
              <span class="text__color color--success">(+)</span>
              <a class="text__color color--success text-underlined" role="button"
                (click)="openShippingCostDetails()">{{cargoItemService.getTotalBonuses(cargo) |
                currency:'COP':'code'}}</a>
            </span>
          </div>
        </div>

      </div>

      <!-- Body Default -->
      <div class="row cargo__body">
        <!-- Rate -->
        <!-- Total Cost -->

        <div class="col-md-2">
          <div class="label">
            Tipo de vehiculo
          </div>
          <div class="text-bold label-field">
            <span title="Pagada">
              {{cargo.cargoFeature.vehicleType.name}}
            </span>
          </div>
        </div>
        <div class="col-md-3" *ngIf="cargo.state != 'Start service'">
          <div class="label">
            Tipo de carrocería
          </div>
          <div class="text-bold label-field"
            title="{{cargo && cargo.cargoFeature && cargo.cargoFeature.productType && cargo.cargoFeature.productType.name ? cargo.cargoFeature.productType.name : '-'}}">
            <span>
              <!-- {{cargo && cargo.cargoFeature && cargo.cargoFeature.vehicleType. : '-'}} -->
            </span>
          </div>
        </div>
        <div class="col-md-2" *ngIf="cargo.state != 'Start service'">
          <div class="label">
            Cantidad de vehículos
          </div>
          <div class="text-bold label-field">
            <span
              title="{{cargo && cargo.cargoFeature && cargo.cargoFeature.cargoMeasure && cargo.cargoFeature.cargoMeasure.totalWeigth ? cargo.cargoFeature.cargoMeasure.totalWeigth : '-'}}">
              {{cargo && cargo.cargoFeature && cargo.cargoFeature.cargoMeasure &&
              cargo.cargoFeature.cargoMeasure.totalWeigth ? cargo.cargoFeature.cargoMeasure.totalWeigth : '-'}}
            </span>
          </div>
        </div>
        <div class="col-md-3" *ngIf="cargo.state != 'Start service'">
          <div class="label">
            Peso de mercancía
          </div>
          <div class="text-bold label-field">
            <span
              title="{{cargo && cargo.cargoFeature && cargo.cargoFeature.cargoMeasure && cargo.cargoFeature.cargoMeasure.totalWeigth ? cargo.cargoFeature.cargoMeasure.totalWeigth : '-'}}">
              {{cargo && cargo.cargoFeature && cargo.cargoFeature.cargoMeasure &&
              cargo.cargoFeature.cargoMeasure.totalWeigth ? cargo.cargoFeature.cargoMeasure.totalWeigth : '-'}}
            </span>
          </div>
        </div>
        <div class="col-md-2">
          <div class="label">
            Cubicaje
          </div>
          <div class="text-bold label-field">
            <span>
              Ver servicios adicionales
            </span>
          </div>
        </div>
      </div>

      <div class="row cargo__body">
        <div class="col-md-2" *ngIf="cargo.observation">
          <div class="label">
            Observaciones
          </div>
          <div class="text-bold label-field">
            <span *ngIf="cargo.observation"><u matTooltip="{{cargo.observation}}" matTooltipPosition="above"
                matTooltipClass="extended-tooltip">
                Servicio
              </u></span>
            <span *ngIf="cargo.observationDriver"> - <u matTooltip="{{cargo.observationDriver}}"
                matTooltipPosition="above" matTooltipClass="extended-tooltip">
                Conductor
              </u></span>
          </div>
        </div>
      </div>

      <!-- Message validated documents vehicle -->
      <div class="cargo__alert" *ngIf="showAlertCargo()">
        <div class="col-md-12 alert alert-warning">
          <div *ngIf="isPendingApproveBankAccount()">
            <i class="fas fa-exclamation-triangle"></i>
            {{'Los documentos para pago del vehículo '}}
            <span class="font-weight-bold">{{cargo.licensePlate}}</span>
            {{' no están validados.'}}
            <small><span class="font-wight-bold underline text-uppercase" (click)="openDetailVehicle()">Ir a
                Validar</span></small>
          </div>
          <div *ngIf="isPendingConfirmLicensePlate()">
            <i class="fas fa-exclamation-triangle"></i>
            {{'La placa '}}
            <span class="font-weight-bold">{{cargo.licensePlate}}</span>
            {{'aún no se ha confirmado para iniciar este servicio.'}}
            <small><span class="font-wight-bold underline text-uppercase"
                (click)=" confirmDriverValidations()">Confirmar
                Placa</span></small>
          </div>
          <div *ngIf="isPendingApproveBalance()">
            <i class="fas fa-exclamation-triangle"></i>
            No se ha aprobado el pago del saldo, se debe confirmar desde la aplicación móvil Teclogi Conductor o los
            encargados.
            <small>
              <span class="font-wight-bold underline text-uppercase" (click)="validateApproveBalance()">
                ¿Quién puede Aprobar?
              </span>
            </small>
          </div>
          <div *ngIf="isPendingApprove()">
            <i class="fas fa-exclamation-triangle"></i>
            No se ha aprobado el servicio
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mw-100 p-cell buttons d-flex flex-column justify-content-center align-items-center">
    <button mat-raised-button class="primary" color="primary" (click)="selectDriver(cargo)"
      *ngIf="!cargo.assignedDriver">
      <div class="container-number">
        {{visualNumberOfOffers}}
      </div>
      <span><i class="fas fa-truck"></i>Asignar vehículo</span>
    </button>

    <button mat-raised-button class="primary-secondary" color="primary" (click)="openDetail(cargo)"
      *ngIf="cargo.state === 'Request'">
      Seguir diligenciando <i class="fas fa-pencil-alt" color="primary"></i>
    </button>

    <app-cargo-options class="cargo-options title-purple" type="text" [cargo]="cargo" [typeList]="typeList"
      [isNegotiation]="isNegotiation" (refreshListCargo)="applyFilter.emit()">
    </app-cargo-options>
  </div>

</div>

<!-- Menu optionsCargo -->
<mat-menu #optionsCargo="matMenu">
  <button mat-menu-item>
    <mat-icon><i class="fas fa-check-square"></i></mat-icon>
    <span>completada</span>
  </button>
</mat-menu>
