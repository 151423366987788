<section class="px-3 px-md-5 py-5 position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="ref.close()"></i>
    <h2 class="title py-4 text-center">Legalizar viáticos</h2>
    <div class="d-flex flex-wrap travel-expense mb-3" *ngFor="let travelExpense of travelExpenses; let i = index;">
        <div class="bg-gray main-bar d-flex w-100">
            <div class="w-100">
                <b class="bolded-text">{{travelExpense?.travelExpensesType?.name}}</b>
            </div>
            <span style="width: 14em;">
                <mat-select style="width: 80%;" (selectionChange)="onChangeApprovalExpense(travelExpense, $event)"
                    [value]="travelExpense?.approval">
                    <mat-option *ngFor="let state of states" [value]="state">
                        {{state | legalizeState}}
                    </mat-option>
                </mat-select>
            </span>
        </div>

        <div class="col-12 pt-2 px-2 pb-4 d-flex">
            <div class="w-100">
                <b class="bolded-text">{{ travelExpense?.paid ? 'Girado' : 'Por girar'}}</b><br>
                <span>{{(travelExpense?.totalPaid || 0) | currency:'COP':'code'}}</span>
            </div>
            <div class="w-100">
                <b class="bolded-text">Sin legalizar</b><br>
                <span>{{calculateUnlegalized(travelExpense) | currency:'COP':'code'}}</span>
            </div>
            <div class="w-100">
                <b class="bolded-text">Rechazado</b><br>
                <span>{{calculateRejected(travelExpense) | currency:'COP':'code'}}</span>
            </div>
            <div class="w-100">
                <b class="bolded-text">
                    Descripción
                </b><br>
                <div *ngIf="!showMoreState[i]">
                    <span>
                        {{travelExpense.travelExpensesDetail.length + (travelExpense.travelExpensesDetail.length > 1
                        ? " registros" : " registro")}}
                    </span>
                </div>
                <div *ngIf="showMoreState[i]">
                    <div class="d-flex"
                        *ngFor="let travelExpensesDetail of travelExpense.travelExpensesDetail; let j = index;">
                        <span class="w-100">{{travelExpensesDetail.description || '--'}}</span>
                    </div>
                </div>
            </div>
            <div class="w-100">
                <b class="bolded-text">Valor utilizado</b><br>
                <span *ngIf="!showMoreState[i]">
                    {{(travelExpense?.totalSpentValue || 0) | currency:'COP':'code'}}
                </span><br *ngIf="!showMoreState[i]">

                <div *ngIf="showMoreState[i]">
                    <div *ngFor="let travelExpensesDetail of travelExpense.travelExpensesDetail; let j = index;">
                        <div [formGroup]="travelExpensesDetailValues">
                            <div class="d-flex" [formGroupName]="i">
                                <span class="w-100" *ngIf="!isEditable(i,j)">
                                    {{(travelExpensesDetail?.value || 0) | currency:'COP':'code'}}
                                </span>
                                <input matInput type="text" [name]="'prix-'+i+'-'+j"
                                    [value]="(travelExpensesDetail?.value || 0)" *ngIf="isEditable(i,j)" onlyNumbersInt
                                    min="0" [formControlName]="j" currencyMask>

                                <div class="text-nowrap" style="padding-right: 1em;">
                                    <i matTooltip="Confirmar" matTooltipPosition="above" matSuffix
                                        class="fas fa-check color-primary cursor-pointer" *ngIf="isEditable(i,j)"
                                        (click)="confirmEdit(i,j)">
                                    </i>
                                    <i matTooltip="Cancelar" matTooltipPosition="above" matSuffix
                                        class="fas fa-times color-primary cursor-pointer" *ngIf="isEditable(i,j)"
                                        (click)="cancelEdit(i,j)">
                                    </i>
                                    <i class="fas fa-pencil-alt color-primary cursor-pointer" matTooltip="Cambiar valor"
                                        *ngIf="!isEditable(i,j) && travelExpensesDetail?.approval != 'Approved'"
                                        matTooltipPosition="above" (click)="enableEdition(i,j)">
                                    </i>
                                    <i class="fas fa-trash color-primary cursor-pointer" matTooltip="Cambiar valor"
                                        *ngIf="getCanRemoveTravelExpenses(i,j) || canRemoveApprovedTravelExpenses"
                                        matTooltipPosition="above" (click)="deleteTravelExpense(i,j)">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div (click)="showMore(i)" *ngIf="listPreviewImages[travelExpense.id]?.length">
                    <a role="button" class="bolded-text clickable" *ngIf="!showMoreState[i]">
                        Ver desglose
                        <i class="fas fa-chevron-down"></i>
                    </a>
                    <a role="button" class="bolded-text clickable" *ngIf="showMoreState[i]">
                        Ver menos
                        <i class="fas fa-chevron-up"></i>
                    </a>
                </div>
            </div>

            <div class="w-100">
                <b class="bolded-text">Estado</b><br>
                <div *ngIf="!showMoreState[i]">
                    <span>
                        {{travelExpense.travelExpensesDetail.length + (travelExpense.travelExpensesDetail.length > 1
                        ? " registros" : " registro")}}
                    </span>
                </div>
                <div *ngIf="showMoreState[i]">
                    <div *ngFor="let travelExpensesDetail of travelExpense.travelExpensesDetail; let j = index;">
                        <span>
                            <mat-select style="width: 80%;"
                                (selectionChange)="onChangeApprovalExpenseDetail(travelExpense, j, $event)"
                                [value]="travelExpensesDetail?.approval"
                                [disabled]="!canChangeApprovedTravelExpenses && isClosed">
                                <mat-option *ngFor="let state of states" [value]="state">
                                    {{state | legalizeState}}
                                </mat-option>
                            </mat-select>
                        </span><br>
                    </div>
                </div>
                <!-- TODO -->
            </div>

            <div class="w-100" *ngIf="listPreviewImages[travelExpense.id]?.length || canUploadImages(travelExpense)">
                <b class="bolded-text" *ngIf="listPreviewImages[travelExpense.id]?.length">
                    Fotos subidas
                </b>
                <app-image-thumbnail-list class="w-100"
                    [imagesWithFingerprint]="listPreviewImages[travelExpense.id]"></app-image-thumbnail-list>
            </div>
        </div>
        <div class="text-end col-12 pt-2 px-2 pb-4">
            <a role="button" class="bolded-text align-self-end" (click)="openFormAddTravelExpensesDetail(travelExpense)"
                [ngClass]="{'unable-option': !travelExpense?.paid, 'clickable': travelExpense?.paid}"
                *ngIf="canUploadImages(travelExpense)"
                [matTooltip]="!travelExpense?.paid ? 'El viático no ha sido girado' : undefined">
                Agregar viáticos
            </a>
        </div>
    </div>
    <app-general-table-legalization-travel-expenses [cargo]="data.cargo"
        [travelExpenses]="travelExpenses"></app-general-table-legalization-travel-expenses>
    <div class="row mt-3">
        <div class="col-12 text-center">
            <button mat-raised-button class="primary-secondary bg-color-primary" color="primary"
                (click)="closeDialog()">Cerrar</button>
        </div>
    </div>
</section>