import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Patterns } from '../resources/patterns';
import { Utils } from '../resources/utils';

@Directive({
    selector: 'input[currencyMask]',
})
export class CurrencyMaskDirective implements OnInit {

    constructor(
        public ngControl: NgControl,
        private elementRef: ElementRef,
        public utils: Utils
    ) { }

    ngOnInit(): void {
        if (this.elementRef && this.elementRef.nativeElement && this.elementRef.nativeElement.value)
            this.onInputChange(this.elementRef.nativeElement.value);
    }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event): void {
        this.onInputChange(event);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event): void {
        this.onInputChange(event.target.value);
    }

    onInputChange(event): void {
        if (!this.utils.isEmpty(event)) {
            let value = this.utils.numberMaskToNumber(event);
            this.ngControl.valueAccessor.writeValue(value);
        }
    }

    @HostListener('input') onChange(): void {
        if (!this.utils.isEmpty(this.elementRef.nativeElement.value)) {
            const newVal = this.elementRef.nativeElement.value.replace(/\D/g, '');
            this.ngControl.control.setValue(parseInt(newVal), {
                emitEvent: false,
                emitModelToViewChange: false,
                emitViewToModelChange: false
            });
        }
    }
}