<div class="container">
  <div id="mainFreightCharacteristics" class="col-md-12 mb-md-0 mb-12">

    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-md-0 mb-12">
        <label class="title">
          Ingresa las medidas del servicio
        </label>
      </div>

      <mat-accordion
        *ngFor="let destination of manualCreationCargoService.formDestinations.controls let iDestination = index;">
        <mat-expansion-panel
          *ngFor="let address of manualCreationCargoService.getFormDestination(iDestination).get('addresses')['controls']; let i = index;"
          (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <figure class="panel-img-title">
                <img src="/assets/svg/icons/icon-location.svg" alt="">
              </figure>
              Destino {{manualCreationCargoService.getFormDestination(iDestination).get('name').value}}:
              {{address.get('address').value}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="col-md-12 mb-md-0 mb-12 wraper-quantity">
            <div class="row">
              <div class="col-md-12 mb-md-0 mb-12">
                <mat-form-field appearance="standard">
                  <mat-label>Ingresa la cantidad<span class="asterisk">*</span></mat-label>
                  <input type="text" currencyMask onlyNumbersInt min="1" matInput
                    [formControl]="address.controls.cargoMeasure.controls.quantity">
                  <mat-error *ngIf="address.controls.cargoMeasure.controls.quantity.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                  </mat-error>
                  <mat-error *ngIf="address.controls.cargoMeasure.controls.quantity.errors?.min">
                    {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'cantidad', minQuantity) }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 mb-md-0 mb-12">
                <div class="form-total-load">
                  <mat-form-field appearance="standard">
                    <mat-label>Medida total (metros cubicos)<span class="asterisk">*</span></mat-label>
                    <input type="text" currencyMask matInput class="input-group group-total-measurement"
                      onlyNumbersDouble placeholder="Ejm: 100 Metros cúbicos"
                      [formControl]="address.controls.cargoMeasure.controls.totalMeasurement">
                    <span>metros</span>
                    <mat-error *ngIf="address.controls.cargoMeasure.controls.totalMeasurement.errors?.required">
                      {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                    <mat-error *ngIf="address.controls.cargoMeasure.controls.totalMeasurement.errors?.min">
                      {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'medida total',
                      minTotalMeasurement) }}
                    </mat-error>
                    <mat-error *ngIf="address.controls.cargoMeasure.controls.totalMeasurement.errors?.max">
                      {{isEmptyContainer
                      ? utils.giveMessageError('MUST_TO_BE_ZERO_FIELD', 'medida total', ' para contenedores vacíos')
                      : utils.giveMessageError('MAX_VALUE_EXCEED', 'medida total', maxTotalMeasurement) }}
                    </mat-error>
                  </mat-form-field>
                  <div class="text-right label-calculator" (click)="openDialogCalculatorMeasurements(iDestination, i)">
                    Calcular metros cúbicos</div>
                </div>
              </div>
              <div class="col-md-12 mb-md-0 mb-12">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="row">
                        <mat-form-field appearance="standard">
                          <mat-label>Peso total<span class="asterisk">*</span></mat-label>
                          <input type="text" currencyMask onlyNumbersInt matInput placeholder="Peso" min="0"
                            [formControl]="address.controls.cargoMeasure.controls.totalWeigth">
                          <mat-error *ngIf="address.controls.cargoMeasure.controls.totalWeigth.errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                          </mat-error>
                          <mat-error *ngIf="address.controls.cargoMeasure.controls.totalWeigth.errors?.min">
                            {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'peso total', minTotalWeigth) }}
                          </mat-error>
                          <mat-error *ngIf="address.controls.cargoMeasure.controls.totalWeigth.errors?.max">
                            {{isEmptyContainer
                            ? utils.giveMessageError('MUST_TO_BE_ZERO_FIELD', 'peso total', ' para contenedores vacíos')
                            : utils.giveMessageError('MAX_VALUE_EXCEED', 'peso total',
                            address.value.cargoMeasure.unit === '1' ? AmountsCargoEnum.MAX_WEIGHT_ALLOWED_KG :
                            AmountsCargoEnum.MAX_WEIGHT_ALLOWED_GL)}}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-6">
                      <div class="row">
                        <mat-form-field appearance="standard">
                          <mat-label>Unidad<span class="asterisk">*</span></mat-label>
                          <mat-select [formControl]="address.controls.cargoMeasure.controls.unit" placeholder="Unidad"
                            (selectionChange)="onChangeUnit($event,iDestination,i)">
                            <mat-option [value]="'1'">
                              Kilos
                            </mat-option>
                            <mat-option [value]="'2'">
                              Galones
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-12 mb-md-0 mb-12">
                <mat-form-field appearance="standard">
                  <mat-label>Valor del servicio<span class="asterisk">*</span></mat-label>
                  <input type="text" currencyMask matInput placeholder="Escribe un valor COP"
                    [formControl]="address.controls.cargoMeasure.controls.amount">
                  <mat-error *ngIf="address.controls.cargoMeasure.controls.amount.errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                  </mat-error>
                  <mat-error *ngIf="address.controls.cargoMeasure.controls.amount.errors?.min">
                    {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor total', minTotalAmount) }}
                  </mat-error>
                  <mat-error *ngIf="address.controls.cargoMeasure.controls.amount.errors?.max">
                    {{isEmptyContainer
                    ? utils.giveMessageError('MUST_TO_BE_ZERO_FIELD', 'valor total', ' para contenedores vacíos')
                    : utils.giveMessageError('MAX_VALUE_EXCEED', 'valor total', maxTotalAmount) }}
                  </mat-error>
                </mat-form-field>
              </div>
              <br>
              <div class="col-md-12 mb-md-0 mb-12 mt-5">
                <mat-card-content>
                  <mat-button-toggle-group class="value-load-button-toggle btn-toggle" #group="matButtonToggleGroup"
                    [formControl]="address.controls.cargoMeasure.controls.type">
                    <mat-button-toggle value="Apilable">
                      Es apilable
                    </mat-button-toggle>
                    <mat-button-toggle value="Delicado">
                      Es delicado
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </mat-card-content>
              </div>
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion>

      <!-- Button -->
      <div class="col-12 button-container justify-content-center mt-3">
        <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
        <app-request-button></app-request-button>
        <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Siguiente</button>
      </div>
    </div>

  </div>
</div>