<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="row w-100">
  <!-- Valor de la tarifa-->
  <div class="col-md-3">
    <mat-form-field appearance="standard">
      <mat-label>Valor de la tarifa<span class="asterisk">*</span></mat-label>
      <input matInput type="text" currencyMask onlyNumbersInt min="0" placeholder="$000.000" formControlName="rate">
      <mat-error *ngIf="form.get('rate').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="form.get('rate').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'tarifa', hasPermissionNoMinimumUtility?0:1) }}
      </mat-error>
    </mat-form-field>
  </div>
  <!-- Valor del flete -->
  <div class="col-md-3">
    <mat-form-field appearance="standard">
      <mat-label>Valor del flete<span class="asterisk">*</span></mat-label>
      <input matInput type="text" currencyMask onlyNumbersInt min="0" placeholder="$000.000"
        formControlName="freightCost">
      <mat-error *ngIf="form.get('freightCost').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="form.get('freightCost').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'flete', hasPermissionNoMinimumUtility?0:1) }}
      </mat-error>
    </mat-form-field>
  </div>
  <!-- Porcentaje del anticipo -->
  <div class="col-md-3">
    <mat-form-field appearance="standard">
      <mat-label>Porcentaje de anticipo<span class="asterisk">*</span></mat-label>
      <input type="text" matInput onlyNumbersInt maxlength="3" formControlName="advancePercentage">
      <i class="fas fa-info-circle fa-lg color-secondary-dark" matSuffix
        [matTooltip]="messageAdvancePercentageAllowed"></i>
      <mat-error *ngIf="form.get('advancePercentage').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="form.get('advancePercentage').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'porcentaje', 0) }}
      </mat-error>
      <mat-error *ngIf="form.get('advancePercentage').errors?.max">
        {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'porcentaje', MaxPercentage) }}
      </mat-error>
    </mat-form-field>
  </div>
  <!-- Tiempo de pago del saldo (en días) -->
  <div class="col-md-3">
    <mat-form-field appearance="standard">
      <mat-label>Tiempo de pago del saldo<span class="asterisk">*</span></mat-label>
      <mat-select formControlName="paymentTime">
        <mat-option [value]="0">
          Contra entrega
        </mat-option>
        <mat-option [value]="8">
          8 días
        </mat-option>
        <mat-option [value]="15">
          15 días
        </mat-option>
        <mat-option [value]="30">
          30 días
        </mat-option>
        <mat-option [value]="60">
          60 días
        </mat-option>
        <mat-option [value]="90">
          90 días
        </mat-option>
        <mat-option [value]="-1">
          Otro
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-3" *ngIf="form.get('paymentTime').value === -1">
    <mat-form-field appearance="standard">
      <mat-label>Otro valor (en días)<span class="asterisk">*</span></mat-label>
      <input matInput type="number" min="0" step="1" placeholder="valor" [formControl]="otherFreightCost">
      <mat-error *ngIf="otherFreightCost.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="otherFreightCost.errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor', 0) }}
      </mat-error>
    </mat-form-field>
  </div>
</form>